import { Grid, Paper, Typography, Stack, IconButton } from "@mui/material";
import React from "react";
import { CloseTwoTone } from "@mui/icons-material";
import { connect } from "react-redux";
import {
  ComponentCardStep,
  SurveyDetails,
  SurveyPreview,
} from "../components/ComponentIndex";
import { detailSurvey } from "../store/actions/survey";
import { handleModalSurvey } from "../store/actions/modal";

function activeStepSurvey(valueStepSurvey, index) {
  if (valueStepSurvey === index) {
    return {
      backgroundColor: "#00A45F",
      borderRadius: 10,
      textTransform: "none",
      color: "#FFF",
    };
  }
  return {
    boxShadow: "none",
    color: "#323232",
  };
}
const surveyList = [
  {
    title: "1. Details",
    description: "Fill survey details.",
  },
  { title: "2. Preview", description: "Survey preview." },
];
function handleCusrsor(currStep, defaultStep) {
  if (currStep < defaultStep) {
    return {
      cursor: "default",
    };
  }
  return { cursor: "pointer" };
}

const mappingTitle = {
  create: "Create",
  edit: "Edit",
};

function NewSurvey({ dispatch, formSurvey, modalConfirmation, modalSurvey }) {
  const [stepSurvey, setStepSurvey] = React.useState(0);
  const [isDataChanged, setIsDataChanged] = React.useState(false);

  React.useEffect(() => {
    if (modalSurvey.type === "edit") {
      detailSurvey(dispatch, modalSurvey.data.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalSurvey.data?.id]);

  const handleStepSurvey = (currStep) => {
    if (stepSurvey < currStep) {
      return;
    }
    setStepSurvey(currStep);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
        padding={4}
      >
        <Grid item xs={10}>
          <Paper sx={{ padding: 4, borderRadius: 4 }}>
            <Stack
              justifyContent={"space-between"}
              alignItems={"baseline"}
              direction={"row-reverse"}
            >
              <IconButton
                onClick={() => {
                  handleModalSurvey(dispatch, {
                    isOpen: false,
                    type: "",
                    data: null,
                  });
                }}
              >
                <CloseTwoTone
                  fontSize="medium"
                  sx={{
                    backgroundColor: "#D8D8D8",
                    padding: 0.5,
                    borderRadius: 10,
                  }}
                />
              </IconButton>
              <Typography
                gutterBottom
                variant="h2"
                fontWeight={"bold"}
                fontSize={40}
              >
                {mappingTitle[modalSurvey.type]} Survey
              </Typography>
            </Stack>

            <Paper
              elevation={4}
              sx={{ p: 4, borderRadius: 6, overflow: "auto", height: 600 }}
            >
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <Stack gap={2}>
                    {surveyList.map((item, index) => {
                      return (
                        <ComponentCardStep
                          key={index}
                          index={index}
                          styleCard={activeStepSurvey(stepSurvey, index)}
                          styleCardAction={handleCusrsor(stepSurvey, index)}
                          title={item.title}
                          step={stepSurvey}
                          onHandleClick={() => handleStepSurvey(index)}
                        >
                          {item.description}
                        </ComponentCardStep>
                      );
                    })}
                  </Stack>
                </Grid>
                <Grid item sm={9}>
                  {stepSurvey === 0 && (
                    <SurveyDetails
                      dispatch={dispatch}
                      formSurvey={formSurvey}
                      setStepSurvey={setStepSurvey}
                      modalConfirmation={modalConfirmation}
                      isDataChanged={isDataChanged}
                      setIsDataChanged={setIsDataChanged}
                    />
                  )}
                  {stepSurvey === 1 && (
                    <SurveyPreview
                      dispatch={dispatch}
                      formSurvey={formSurvey}
                      setStepSurvey={setStepSurvey}
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    formSurvey: state.survey.formSurvey,
    modalConfirmation: state.modal.modalConfirmation,
  };
};
export default connect(mapStatetoProps, null)(NewSurvey);
