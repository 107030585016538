import React from "react";
import {
  Box,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import { CloseTwoTone, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { connect } from "react-redux";
import {
  ComponentGuard,
  CustomButton,
  FormikControl,
} from "../../ComponentIndex";
import { handleModalSurvey } from "../../../store/actions/modal";
import { detailSurvey } from "../../../store/actions/survey";
import { apiGet } from "../../../utilities/api";
import { API_CMS } from "../../../utilities/api-url";
import ContentPreviewDetail from "./ContentPreviewDetail";
import { publishErrorMessage } from "../../../events/events";

const directionButtons = (direction) => {
  return direction === "Next" ? (
    <Fab aria-label="arrow-right" size="small">
      <KeyboardArrowRight />
    </Fab>
  ) : (
    <Fab aria-label="arrow-left" size="small">
      <KeyboardArrowLeft />
    </Fab>
  );
};

const AutoFillValue = ({ surveyDetail }) => {
  const { setFieldValue } = useFormikContext();

  React.useEffect(() => {
    setFieldValue("id", surveyDetail?.data?.id || "-");
    setFieldValue("image", surveyDetail?.data.image || "-");
    setFieldValue("name", surveyDetail?.data?.name || "-");
    setFieldValue("questions", surveyDetail?.data?.questions || "-");
    setFieldValue("sequence_id", surveyDetail?.data?.sequence_id || "-");
    setFieldValue("approval_status", surveyDetail?.data.status);
    setFieldValue("survey_category", surveyDetail?.data.survey_category || "-");
    setFieldValue(
      "rejection_reason",
      surveyDetail?.data?.rejection_reason || "-"
    );
  }, [surveyDetail, setFieldValue]);
  return null;
};

function ViewDetail({
  dispatch,
  surveyDetail,
  onHandleModalIsApprove,
  modalSurvey,
}) {
  const [index, setIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [questionDetail, setQuestionDetail] = React.useState({});

  React.useEffect(() => {
    detailSurvey(dispatch, modalSurvey.data.id);
  }, [dispatch, modalSurvey.data.id]);

  React.useEffect(() => {
    if (surveyDetail.data && Object.keys(surveyDetail.data).length) {
      getAPIQuestionDetail(surveyDetail.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, surveyDetail]);

  async function getAPIQuestionDetail(payload) {
    setIsLoading(true);
    try {
      if (!payload?.questions[index]?.question_id) {
        publishErrorMessage("Question ID Not Found");
      } else {
        const { data } = await apiGet(
          API_CMS.QUESTION_BANK_DETAIL(payload?.questions[index]?.question_id)
        );
        setQuestionDetail(data);
      }
    } catch (error) {
      publishErrorMessage(error?.response?.data?.title);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  function handleModal(isOpen, type, data) {
    handleModalSurvey(dispatch, {
      isOpen,
      type,
      data,
    });
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
        padding={4}
      >
        <Grid item xs={10}>
          <Paper
            sx={{ padding: 4, borderRadius: 4 }}
          >
            <Stack
              justifyContent={"space-between"}
              alignItems={"baseline"}
              direction={"row-reverse"}
            >
              <IconButton onClick={() => handleModal(false, "", null)}>
                <CloseTwoTone
                  fontSize="medium"
                  sx={{
                    backgroundColor: "#D8D8D8",
                    padding: 0.5,
                    borderRadius: 10,
                  }}
                />
              </IconButton>
              <Typography gutterBottom variant="h3" fontWeight={"bold"}>
                View Survey
              </Typography>
            </Stack>
            <Paper elevation={4} sx={{ p: 3, borderRadius: 5, height: 650, overflow: 'auto' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h6" fontWeight={"bold"}>
                    Survey Details
                  </Typography>
                </Grid>

                <Formik
                  initialValues={{
                    id: null,
                    image: "",
                    name: "",
                    questions: [],
                    sequence_id: "",
                    approval_status: "",
                    survey_category: "",
                    rejection_reason: "",
                  }}
                >
                  {({ values }) => (
                    <Form style={{ marginTop: "1rem", width: "100%" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {surveyDetail.isLoading && !surveyDetail.error && (
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <CircularProgress />
                            </Box>
                          )}
                        </Grid>
                        {!surveyDetail.isLoading && !surveyDetail.error && (
                          <>
                            <Grid item xs={6}>
                              <FormikControl
                                control="input"
                                label={"Survey Category"}
                                id="survey_category"
                                name="survey_category"
                                className="basic-input"
                                disabled
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormikControl
                                control="input"
                                label={"Approval Status"}
                                id="approval_status"
                                name="approval_status"
                                className="basic-input"
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormikControl
                                control="input"
                                label={"Survey Name"}
                                id="survey_name"
                                name="name"
                                className="basic-input"
                                disabled
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <Typography
                                variant="subtitle1"
                                mb={1.5}
                                sx={{ opacity: 0.6 }}
                              >
                                Survey Preview Tile
                              </Typography>
                            </Grid>
                            <Grid item xs={4} mt={5}>
                              {isLoading && <CircularProgress />}
                              {!isLoading && surveyDetail?.data?.questions[index]?.question_id && (
                                <ContentPreviewDetail
                                  index={index}
                                  handleSelect={handleSelect}
                                  directionButtons={directionButtons}
                                  values={values}
                                  questionDetail={questionDetail}
                                />
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <FormikControl
                                control="input"
                                label={"Reason for Rejection"}
                                id="rejection_reason"
                                name="rejection_reason"
                                className="basic-input"
                                disabled
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                      {!surveyDetail.isLoading && !surveyDetail.error && (
                        <AutoFillValue surveyDetail={surveyDetail} />
                      )}
                      {!surveyDetail.isLoading && !surveyDetail.error && (
                        <>
                          <Stack direction={"row"} spacing={2}>
                            {values.approval_status === "UNCHECKED" && (
                              <>
                                <ComponentGuard
                                  allowedRoles={window.appRoles.approveSurvey}
                                >
                                  <CustomButton
                                    className={"custom_button white"}
                                    style={{
                                      width: 100,
                                      marginTop: 10,
                                      marginRight: 10,
                                    }}
                                    onHandleClick={() =>
                                      onHandleModalIsApprove(false)
                                    }
                                  >
                                    <span>Reject</span>
                                  </CustomButton>
                                </ComponentGuard>
                                <ComponentGuard
                                  allowedRoles={window.appRoles.approveSurvey}
                                >
                                  <CustomButton
                                    style={{ width: 100, marginTop: 10 }}
                                    className={"custom_button black"}
                                    onHandleClick={() =>
                                      onHandleModalIsApprove(true)
                                    }
                                  >
                                    <span>Approve</span>
                                  </CustomButton>
                                </ComponentGuard>
                              </>
                            )}
                          </Stack>
                          <ComponentGuard
                            allowedRoles={window.appRoles.createSurvey}
                          >
                            <CustomButton
                              style={{
                                width: 100,
                                marginTop: 10,
                                marginRight: 10,
                              }}
                              className={"custom_button white"}
                              onHandleClick={() => handleModal(false, "", null)}
                            >
                              <span>Back</span>
                            </CustomButton>
                          </ComponentGuard>
                          {values.approval_status !== "APPROVED" &&
                            values.approval_status !== "UNCHECKED" && (
                              <ComponentGuard
                                allowedRoles={window.appRoles.createSurvey}
                              >
                                <CustomButton
                                  style={{ width: 100, marginTop: 10 }}
                                  className={"custom_button black"}
                                  onHandleClick={() =>
                                    handleModal(true, "edit", { id: values.id })
                                  }
                                >
                                  <span>Edit</span>
                                </CustomButton>
                              </ComponentGuard>
                            )}
                        </>
                      )}
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    surveyDetail: state.survey.surveyDetail,
    modalSurvey: state.modal.modalSurvey,
  };
};
export default connect(mapStatetoProps, null)(ViewDetail);
