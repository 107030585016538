const initialState = {
  modalRejection: {
    isOpen: false,
    approvalStatus: false,
    reason: null,
    data: null,
    type: "",
  },
  modalConfirmation: {
    isOpen: false,
    type: "",
    data: null,
  },
  modalQuestion: {
    isOpen: false,
    type: "",
    data: null,
  },
  modalSurvey: {
    isOpen: false,
    type: "",
    data: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MODAL_REJECTION":
      return {
        ...state,
        modalRejection: {
          ...state.modalRejection,
          isOpen: action.payload?.isOpen,
          approvalStatus: action.payload?.approvalStatus,
          reason: action.payload?.reason,
          data: action.payload?.data,
          type: action.payload?.type,
        },
      };
    case "SET_MODAL_CONFIRMATION":
      return {
        ...state,
        modalConfirmation: {
          ...state.modalConfirmation,
          isOpen: action.payload?.isOpen,
          type: action.payload?.type,
          data: action.payload?.data,
        },
      };
    case "SET_MODAL_QUESTION":
      return {
        ...state,
        modalQuestion: {
          ...state.modalQuestion,
          isOpen: action.payload.isOpen,
          type: action.payload.type,
          data: action.payload.data,
        },
      };
    case "SET_MODAL_SURVEY":
      return {
        ...state,
        modalSurvey: {
          ...state.modalSurvey,
          isOpen: action.payload?.isOpen,
          type: action.payload?.type,
          data: action.payload?.data,
        },
      };
    default:
      return state;
  }
};

export default reducer;
