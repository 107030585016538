import React, { useState } from "react";
import { Grid } from "@mui/material";

function EligibilityRadioGroup({ ruleData, valueKey, value, onSelect }) {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    onSelect({ target: { name: valueKey, value: parseInt(value, 10) } }); // convert back to a number before calling onSelect
  };

  return (
    <Grid item xs={12}>
      <h3>When do users need to meet eligibility criteria</h3>
      <Grid container spacing={2}>
        {ruleData.map((rule) => (
          <Grid item xs={12} key={rule.id}>
            <label style={{ display: "flex", alignItems: "center" }}>
              <input
                data-testid={`eligibility-radio-group-${rule.id.toString()}}`}
                type="radio"
                name="offer-action"
                value={rule.id.toString()} // convert the id to a string
                style={{ margin: "0 8px" }}
                checked={selectedValue.toString() === rule.id.toString()} // compare with string representation
                onChange={handleChange}
              />
              {rule.ruleDescription}
            </label>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default EligibilityRadioGroup;
