import React from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import { stepConstants } from "../../constants/consts";
import { windowSizeUnderSmTablet } from "../../utilities/utils";
import useScreenSize from "../../hooks/useScreenSize";
import {isMarketingCampaignType} from "../../utilities/campaignHelpers";

const StepperNav = ({ activeStep, steps, formValues }) => {
  const screenSize = useScreenSize();

  if (window.preview_offer_disabled || isMarketingCampaignType(formValues)) {
    steps = steps.filter((step) => step !== stepConstants.PREVIEW_OFFER);
  }

  if (isMarketingCampaignType(formValues)) {
    steps = steps.filter((step) => step !== stepConstants.OFFER_MESSAGE);
  }

  const isUnderSmTabletSize = windowSizeUnderSmTablet(screenSize);

  return (
    <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
      {steps.map((label, index) => {
        return (
          <Step key={label} data-testid={"step-root"} sx={{ pl: `${index === 0 ? 0 : "8px"}` }}>
            <StepLabel>{isUnderSmTabletSize ? null : label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default StepperNav;
