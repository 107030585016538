import React, {useCallback, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Button, Grid, Paper} from "@mui/material";
import axios from "axios";
import {
  defaultCampaignValues,
  imageTitles,
  offerMessage,
  statusMapping,
  stepConstants,
  steps,
  tableTypePathMap,
} from "../constants/consts";
import {
  doesNameContainIllegalCharacters, isNameInvalid,
  toIsoDate
} from "../utilities/utils";
import {
  isMarketingCampaignType,
  verifyCampaignValues,
  verifyCriteriaValues,
  verifyOfferMessageValues
} from "../utilities/campaignHelpers";
import EditModeContext from "../contexts/EditModeContext";
import useFetchMasterData from "../hooks/useFetchMasterData";
import {publishErrorMessage} from "../events/events";

import {
  DefineCampaign,
  EligibilityCriteria,
  FormSummary,
  OfferMessage,
  PreviewOffer,
  SelectImages,
  StepperNav,
} from "../components/ComponentIndex";
import {
  getMappedStatus
} from "../components/FormComponents/SummarySections/EligibilityCriteriaSection";

import useAccountName from "../lib/useAccountName";
import { API_CMS } from "../utilities/api-url";

export default function NewCampaign({ isTemplate = false }) {
  const location = useLocation(),
    initialCampaigns = location.state?.campaigns,
    { campaignId } = useParams(),
    { editMode } = useContext(EditModeContext),
    [activeStep, setActiveStep] = useState(0),
    [formValues, setFormValues] = useState(defaultCampaignValues(isTemplate)),
    [images, setImages] = useState({}),
    navigate = useNavigate(),
    [messageFormValues, setMessageFormValues] = useState(offerMessage),
    [campaignTypes, setCampaignTypes] = useState([]),
    masterdata = useFetchMasterData(),
    [ruleData, setRuleData] = useState([]),
    [defaultInfoConfig, setDefaultInfoConfig] = useState([]),
    [existingImagesUrls, setExistingImagesUrls] = useState([]),
    [isSubmitting, setIsSubmitting] = useState(false),
    [showErrorOnPage, setShowErrorOnPage] = useState(false),
    [isLoading, setIsLoading] = useState(true),
    [loading, setLoading] = useState(false),
    [campaigns, setCampaigns] = useState(initialCampaigns),
    [campaignFormErrorState, setCampaignFormErrorState] = useState(false),
    [criteriaFormErrorState, setCriteriaFormErrorState] = useState(false),
    [offerErrorState, setOfferErrorState] = useState(false),
    [surveyItems, setSurveyItems] = useState([]);

  const setEditFormValues = useCallback(
    (formValues) => {
      if (editMode) {
        const exclusionCriteria = formValues.exclusionRules.map((rule) => {
          const statusKey = getMappedStatus(rule.status);

          return {
            ...rule,
            value: rule.existingCampaign || rule.existingCampaignType,
            operator: "IN",
            ruleKey: statusKey,
            id: rule.id,
          };
        });

        const { exclusionRules, ...remainingFormValues } = formValues;

        setFormValues({
          ...remainingFormValues,
          startDate: formValues.startDate,
          eligibilityCriteria: formValues.eligibilityCriteria,
          assignmentCriteria: formValues.assignmentCriteria,
          exclusionCriteria: exclusionCriteria,
          ruleConfig: formValues.ruleConfig.id,
        });

        const urls = [
          { name: imageTitles.HERO, value: formValues.heroImageName },
          { name: imageTitles.BRAND, value: formValues.brandImageName },
          { name: imageTitles.DYNAMIC, value: formValues.dynamicImageName },
          { name: imageTitles.LISTVIEW, value: formValues.listViewImageName },
        ];
        setExistingImagesUrls(urls);

        setMessageFormValues(formValues.couponOfferMessages[0]);
      }
    },
    [editMode]
  );

  const accountName = useAccountName();

  useEffect(() => {
    const fetchCampaign = async () => {
      if (editMode && campaignId) {
        try {
          const response = await axios.get(window.promotion_url + "/campaigns/" + campaignId);
          setEditFormValues(response.data);
        } catch (error) {
          console.error("Error fetching campaign data:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchCampaign();
  }, [campaignId, editMode, setEditFormValues]);

  useEffect(() => {
    if (!initialCampaigns) {
      setLoading(true);
      axios
        .get(window.promotion_url + "/v2/campaigns?size=1000")
        .then((response) => {
          setCampaigns(response.data.content);
          setLoading(false);
        })
        .catch((error) => {
          publishErrorMessage("Error while fetching campaigns: " + error.message);
          setLoading(false);
        });
    }
  }, [initialCampaigns]);

  useEffect(() => {
    axios
      .get(window.promotion_url + "/campaigns/types?all=false")
      .then((response) => {
        setCampaignTypes(response.data.filter((campaignType) => campaignType.active));
      })
      .catch((error) => {
        console.error("Error fetching campaign types:", error);
        publishErrorMessage("Error fetching campaign types. Please retry.");
      });
  }, []);

  useEffect(() => {
    axios
      .get(window.promotion_url + "/campaigns/rule")
      .then((response) => {
        setRuleData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching rule:", error);
        publishErrorMessage("Error fetching rule. Please retry.");
      });
  }, []);

  useEffect(() => {
    axios
      .get(window.promotion_url + "/campaigns/default-info-config")
      .then((response) => {
        setDefaultInfoConfig(response.data);
      })
      .catch((error) => {
        console.error("Error fetching default info config:", error);
        publishErrorMessage("Error fetching default info config. Please retry.");
      });
  }, []);
  
  useEffect(() => {
    if (!window.survey_url) {
      console.info("Not fetching surveys");
      return;
    }

    axios
      .get(API_CMS.SURVEY_LIST, {
        params: { type:"ACTIVE" }
      })
      .then((response) => {
        setSurveyItems(response.data);
      })
      .catch((error) => {
        console.error("Error fetching survey list:", error);
        publishErrorMessage("Error fetching survey list. Please retry.");
      });
  }, []);

  const setImage = (image, imageType) => {
    setImages({ ...images, [imageType]: image });
  };

  const removeImageUrl = (image) => {
    const updatedUrls = existingImagesUrls.map((url) => {
      if (url.name === image) {
        return { ...url, value: null };
      }
      return url;
    });
    setExistingImagesUrls(updatedUrls);

    const imageName = Object.keys(imageTitles).find((key) => imageTitles[key] === image);
    const updatedImages = { ...images };
    delete updatedImages[imageName];
    setImages(updatedImages);
  };

  const goBack = () => {
    if (steps[activeStep] === stepConstants.CONFIRM && isMarketingCampaignType(formValues)) {
      setActiveStep(activeStep - 3);
      return;
    }
    if (steps[activeStep] === stepConstants.CONFIRM && window.preview_offer_disabled) {
      setActiveStep(activeStep - 2);
      return;
    }
    setActiveStep(activeStep - 1);
    window.scrollTo(0, 0);
  };

  const verifyCampaign = () => {
    const campaignValueInvalid = verifyCampaignValues(formValues, campaigns, editMode, campaignTypes, isTemplate);
    if (campaignValueInvalid) {
      if (steps[activeStep] === stepConstants.DEFINE_CAMPAIGN) {
        publishErrorMessage("Please check the form and fill out all required fields.");
        setCampaignFormErrorState(true);
      }
      return false;
    }
    return true;
  };

  const verifyCriteria = () => {
    const { showError, errorMessage } = verifyCriteriaValues(formValues);
    if (!!showError) {
      publishErrorMessage(errorMessage);
      setShowErrorOnPage(true);
      setCriteriaFormErrorState(true);
      return false;
    } else {
      return true;
    }
  };

  const verifyOfferMessage = () => {
    const offerMessageInvalid = !isTemplate && verifyOfferMessageValues(messageFormValues);
    if (offerMessageInvalid) {
      if (steps[activeStep] === stepConstants.OFFER_MESSAGE) {
        publishErrorMessage("Please check the form and fill out all required fields.");
        setOfferErrorState(true);
      }
      return false;
    }
    return true;
  };

  // check that any eligibility rules exists
  const zeroEligibilityCriteria =
    !!(formValues?.eligibilityCriteria?.primaryRuleGroup?.rules?.length === 0) &&
    !!(formValues?.eligibilityCriteria?.ruleGroups?.length === 0) &&
    !!(formValues?.assignmentCriteria?.primaryRuleGroup?.rules?.length === 0) &&
    !!(formValues?.assignmentCriteria?.ruleGroups?.length === 0);

  const isDisabled = (activeStep === steps.length - 1 && isSubmitting) || loading;

  const isCampaignNextDisabled =
    steps[activeStep] === stepConstants.DEFINE_CAMPAIGN &&
    ([
      "name",
      "campaignType"
    ].some((key) => !!(formValues[key]?.length < 1)) ||
    (!isTemplate &&
      ((!isMarketingCampaignType(formValues) && isNaN(formValues.couponValue)) ||
      isNaN(formValues.maxDistribution) ||
      doesNameContainIllegalCharacters(formValues) ||
      isNameInvalid(formValues, campaigns, editMode) ||
      ((formValues.campaignType === 'Survey' || isMarketingCampaignType(formValues)) && !formValues.url) ||
      [
        "description",
        "startDate",
        "endDate",
        "maxDistribution",
      ].some((key) => !!(formValues[key]?.length < 1)) ||
        (!isMarketingCampaignType(formValues) && [
          "rewardExpireDays",
          "couponValue",
        ].some((key) => !!(formValues[key]?.length < 1)))
      )));

  const isOfferNextDisabled =
    steps[activeStep] === stepConstants.OFFER_MESSAGE &&
    !isTemplate &&
    [
      "couponOfferName",
      "couponOfferHeading",
      "couponOfferExternalUrl",
      "couponOfferAgentInstructions",
      "couponOfferDetails",
    ].some((key) => {
      return !!messageFormValues ? messageFormValues[key]?.length < 1 : formValues[key]?.length < 1;
    });

  const isEligibilityCriteriaDisabled =
    steps[activeStep] === stepConstants.ELIGIBILITY_CRITERIA && zeroEligibilityCriteria && !isTemplate;

  const isNextDisabled = isCampaignNextDisabled || isOfferNextDisabled || isDisabled || isEligibilityCriteriaDisabled;

  const handleNext = (event) => {
    event.preventDefault();

    if (activeStep === steps.length - 1) {
      formValues.couponOfferMessage = messageFormValues;
      handleSubmit();
    } else {
      if (steps[activeStep] === stepConstants.DEFINE_CAMPAIGN && !verifyCampaign()) {
        return;
      }
      if (steps[activeStep] === stepConstants.ELIGIBILITY_CRITERIA && !verifyCriteria()) {
        return;
      }
      if (steps[activeStep] === stepConstants.OFFER_MESSAGE && !verifyOfferMessage()) {
        return;
      }
      if (steps[activeStep] === stepConstants.SELECT_IMAGES && isMarketingCampaignType(formValues)) {
        setActiveStep(activeStep + 3);
        return;
      }
      if (steps[activeStep] === stepConstants.OFFER_MESSAGE && window.preview_offer_disabled) {
        setActiveStep(activeStep + 2);
        return;
      }
      setActiveStep(activeStep + 1);
      window.scrollTo(0, 0);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const mappedExclusionCriteria = formValues.exclusionCriteria.map((criteria) => {
        return {
          ...criteria,
          ruleKey: statusMapping[criteria.ruleKey],
        };
      });

      const now = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();

      formValues.startDate = formValues.startDate ? toIsoDate(formValues.startDate) : formValues.startDate;
      formValues.endDate = formValues.endDate ? toIsoDate(formValues.endDate) : formValues.endDate;

      if (!!formValues.startDate && formValues.startDate < now) {
        publishErrorMessage("Error creating campaign. Start Date is in the past.");
        return;
      }
      if (!!formValues.endDate && formValues.endDate < now) {
        publishErrorMessage("Error creating campaign. End Date is in the past.");
        return;
      }

      const updatedFormValues = {
        ...formValues,
        exclusionCriteria: mappedExclusionCriteria,
        initiator: accountName,
        templateName: formValues.name
      };

      const url = `${window.promotion_url + (isTemplate ? tableTypePathMap.TEMPLATE : tableTypePathMap.CAMPAIGN)}${editMode ? `/${formValues.id}` : ""}`;
      const response = await axios.post(url, updatedFormValues, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      const campaignId = formValues.id || response.data.id;
      uploadImages(campaignId);
      navigate("/campaigns");
    } catch (error) {
      console.error("Error creating campaign", error);
      publishErrorMessage("Error creating campaign. Please retry.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const uploadImages = (id) => {
    Object.values(images).forEach((image, index) => {
      const imageCategory = Object.keys(images)[index];
      uploadImage(id, imageCategory, image);
    });
  };

  const uploadImage = (campaignId, imageCategory, image) => {
    if (!image) {
      console.log("image upload failure");
      return;
    }
    const reader = new FileReader();
    reader.onload = function () {
      const imageData = new Uint8Array(reader.result);
      const url = `${window.promotion_url + (isTemplate ? tableTypePathMap.TEMPLATE : tableTypePathMap.CAMPAIGN)}/`;
      axios
        .post(
            url +
            campaignId +
            "/images?imageName=" +
            encodeURIComponent(image.name) +
            "&imageCategory=" +
            imageCategory,
          imageData,
          {
            headers: {
              "Content-Type": image.type,
            },
          }
        )
        .then(() => {
          console.log("image upload success");
        })
        .catch((error) => {
          console.error(error);
          publishErrorMessage("Could not upload image. Please retry.");
        });
    };
    reader.readAsArrayBuffer(image);
  };

  return (
    <>
      {isLoading ? (
        <Paper
          sx={{
            p: 3,
          }}
        >
          <p>Loading...</p>
        </Paper>
      ) : (
        <Paper
          sx={{
            p: 3,
            flexDirection: "column",
          }}
        >
          <StepperNav activeStep={activeStep} steps={steps} formValues={formValues}/>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {
                  [
                    <DefineCampaign
                      formValues={formValues}
                      campaigns={campaigns}
                      campaignTypes={campaignTypes}
                      surveyItems={surveyItems}
                      setFormValues={setFormValues}
                      resetErrorState={campaignFormErrorState}
                      showErrorOnPage={showErrorOnPage}
                      isTemplate={isTemplate}
                    />,
                    <EligibilityCriteria
                      formValues={formValues}
                      campaignTypes={campaignTypes || []}
                      masterdata={masterdata}
                      setFormValues={setFormValues}
                      campaigns={campaigns}
                      ruleData={ruleData}
                      resetErrorState={criteriaFormErrorState}
                      showErrorOnPage={showErrorOnPage}
                      noRulesAdded={zeroEligibilityCriteria}
                      isTemplate={isTemplate}
                    />,
                    <SelectImages
                      formValues={formValues}
                      images={images}
                      setImage={setImage}
                      existingImagesUrls={existingImagesUrls}
                      removeImageUrl={removeImageUrl}
                      isTemplate={isTemplate}
                    />,
                    <OfferMessage
                      formValues={formValues}
                      campaignTypes={campaignTypes}
                      messageFormValues={messageFormValues}
                      setMessageFormValues={setMessageFormValues}
                      resetErrorState={offerErrorState}
                      showErrorOnPage={showErrorOnPage}
                      isTemplate={isTemplate}
                    />,
                    !window.preview_offer_disabled || isMarketingCampaignType(formValues) ? (
                      <PreviewOffer
                        ruleData={ruleData}
                        defaultInfoConfig={defaultInfoConfig}
                        formValues={formValues}
                        messageFormValues={messageFormValues}
                        images={images}
                      />
                    ) : null,
                    <FormSummary
                      ruleData={ruleData}
                      masterdata={masterdata}
                      formValues={formValues}
                      messageFormValues={messageFormValues}
                      images={images}
                      setFormValues={setFormValues}
                    />,
                  ][activeStep]
                }
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <Button
                    sx={{ mr: 2 }}
                    data-testid={`back-button`}
                    variant="contained"
                    onClick={goBack}
                    disabled={activeStep === 0}
                  >
                    Back
                  </Button>

                  <Button
                    data-testid={`${activeStep === steps.length - 1 ? "confirm" : "next"}-button`}
                    variant="contained"
                    onClick={handleNext}
                    disabled={isNextDisabled}
                  >
                    {activeStep === steps.length - 1 ? "Confirm" : "Next"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
    </>
  );
}
