import { TAuthConfig } from 'react-oauth2-code-pkce';

const authService: TAuthConfig = {
    clientId: window.clientId,
    authorizationEndpoint: window.authorizationUrl,
    tokenEndpoint: window.tokenUrl,
    redirectUri: window.authRedirectUri,
    scope: window.campaignApiScopes,
    logoutEndpoint: window.logout,
    logoutRedirect: window.authRedirectUri
}

export default authService;
