export default function DeleteSVG() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 18V9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 18V9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 5.5H19.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 5.5V4.5C15 3.39453 14.1055 2.5 13 2.5H11C9.89453 2.5 9 3.39453 9 4.5V5.5" strokeLinejoin="round" />
      <path
        d="M18.5 5.5V19.5C18.5 20.6055 17.6055 21.5 16.5 21.5H7.5C6.39453 21.5 5.5 20.6055 5.5 19.5V5.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
