const initialState = {
  surveyList: { isLoading: false, data: false, error: null },
  surveyDetail: { isLoading: true, data: false, error: null },
  formSurvey: {
    name: "",
    image: "",
    questions: [
      {
        question_id: "",
        title: "",
        question_type: "",
      },
    ],
    is_submitted: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SURVEY_LIST":
      return {
        ...state,
        surveyList: {
          ...state.surveyList,
          isLoading: action.payload?.isLoading,
          data: action.payload?.data,
          error: action.payload?.error,
        },
      };
    case "SET_SURVEY_DETAIL":
      return {
        ...state,
        surveyDetail: {
          ...state.surveyDetail,
          isLoading: action.payload?.isLoading,
          data: action.payload?.data,
          error: action.payload?.error,
        },
      };
      case "SET_SURVEY_DATA":
      return {
        ...state,
        formSurvey: {
          ...state.formSurvey,
          name: action.payload?.name,
          image: action.payload?.image,
          questions: action.payload?.questions,
          is_submitted: action.payload?.is_submitted,
        },
      };
    default:
      return state;
  }
};

export default reducer;
