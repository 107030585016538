import React from "react";
import { Autocomplete, TextField, Button, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { CONSTANTS } from "../../../constants/consts";
import { isDateFormatValid, toIsoDate } from "../../../utilities/utils";
import { RuleButton, ErrorContainer } from "../../ComponentIndex";
import DeleteSVG from "../../../assets/DeleteSVG";

const defaultValues = {
  ruleKey: "",
  value: "",
  id: null,
  operator: "IN",
  date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
};

export default function ExclusionRuleFormatter(props) {
  const { withDate, onChange, objects, showErrorOnPage, startDate } = props;
  const criteriaExists = props.criteria?.length > 0;

  const deleteExclusion = (index, wDate) => {
    const criteriaToFilter = props.criteria.filter((c) => !!c.date === wDate);
    const criteriaNotToFilter = props.criteria.filter((c) => !!c.date !== wDate);

    const newFilteredCriteria = [...criteriaToFilter];
    newFilteredCriteria.splice(index, 1);

    const criteriaWithDateAndWithoutDate = [...criteriaNotToFilter, ...newFilteredCriteria];

    onChange(criteriaWithDateAndWithoutDate);
  };

  const addExclusion = () => {
    const newCriteria = [...props.criteria, { ...defaultValues, date: withDate ? defaultValues.date : null }];

    onChange(newCriteria);
  };

  const updateExclusion = (index, event, value, wDate) => {
    const criteriaToFilter = props.criteria.filter((c) => !!c.date === wDate);
    const criteriaNotToFilter = props.criteria.filter((c) => !!c.date !== wDate);

    const categoryName = value instanceof Date ? "date" : value?.categoryName;

    switch (categoryName) {
      case CONSTANTS.COUPON_STATUS:
        criteriaToFilter[index].ruleKey = value.label;
        break;
      case CONSTANTS.CAMPAIGN_TYPE:
      case CONSTANTS.CAMPAIGN:
        criteriaToFilter[index].value = value.label;
        criteriaToFilter[index].id = value.id;
        break;
      case "date":
        criteriaToFilter[index].date = !!isDateFormatValid(value) ? toIsoDate(value) : value;
        break;
      default:
        break;
    }

    const newCriteria = [...criteriaToFilter, ...criteriaNotToFilter];
    onChange(newCriteria);
  };

  const renderAddButton = () => {
    return (
      <div>
        <RuleButton dataIdString={`add-exclusion-rule-${withDate ? "with-date" : "without-date"}`} click={addExclusion}>
          Add Exclusion
        </RuleButton>
      </div>
    );
  };

  const renderAutocomplete = (object, criteria, index) => {
    const defaultFilterOptions = (options, params) => {
      const { inputValue } = params;
      const ruleKeys = options.filter((option) => option.value.toLowerCase().includes(inputValue.toLowerCase()));
      return ruleKeys;
    };

    const showAutocompleteError =
      showErrorOnPage && (object.name === CONSTANTS.COUPON_STATUS ? criteria.ruleKey === "" : criteria.value === "");

    return (
      <Autocomplete
        data-testid={`exclusion-rule-autocomplete-${object.name.toString().replace(/\s+/g, "-")}`}
        disablePortal
        freeSolo
        forcePopupIcon={true}
        disableClearable={true}
        isOptionEqualToValue={(option, value) => {
          return option.value === value || value === "" || option.id === value.id;
        }}
        value={object.name === CONSTANTS.COUPON_STATUS ? criteria.ruleKey : criteria.value}
        options={
          object.list
            ? object.list
                .map((option, index) => {
                  return {
                    label: option.name,
                    value: option.name,
                    id: option.id ? option.id : index,
                    categoryName: object.name,
                    key: option.name,
                  };
                })
                .sort((a, b) => a.label.localeCompare(b.label))
            : []
        }
        onChange={(event, newValue) => {
          updateExclusion(index, event, newValue, withDate);
        }}
        filterOptions={(options, params) => {
          const filtered = defaultFilterOptions(options, params);
          return filtered;
        }}
        renderInput={(params) => (
          <TextField error={showErrorOnPage && showAutocompleteError} {...params} label={`${object.name}`} />
        )}
      />
    );
  };

  const renderExclusionRule = (c, index, objects, withDate, startDate) => {
    let dateAfterStartDate = false;

    if (withDate) {
      if (isDateFormatValid(startDate) && isDateFormatValid(c.date)) {
        const startDateIso = toIsoDate(startDate);
        const cDateIso = toIsoDate(c.date);
        dateAfterStartDate = startDateIso < cDateIso;
      }
    }

    return (
      <React.Fragment key={`exclusion-rule-${index}`}>
        {showErrorOnPage && (c.value === "" || c.ruleKey === "") && (
          <Grid item xs={12}>
            <ErrorContainer
              styleOverrides={{ margin: "0 4px" }}
              containerStyle={{ paddingBottom: "12px" }}
              errorMessage="Please ensure that this rule has all values filled correctly"
            />
          </Grid>
        )}
        {showErrorOnPage && dateAfterStartDate && (
          <Grid item xs={12}>
            <ErrorContainer
              styleOverrides={{ margin: "0px 4px" }}
              containerStyle={{ paddingBottom: "12px" }}
              errorMessage="Please ensure that this date is not after the start date of the campaign."
            />
          </Grid>
        )}
        <Grid
          spacing={0.5}
          container
          sx={{
            display: "flex",
            position: "relative",
            justifyContent: "space-between",
            paddingBottom: "12px",
          }}
          key={`${index}-{c.key}`}
        >
          <Grid item xs={8} md={3}>
            {renderAutocomplete(objects[0], c, index)}
          </Grid>
          <Grid item xs={4} md={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {withDate ? "in any" : "in"}
          </Grid>
          <Grid item xs={8} md={3}>
            {renderAutocomplete(objects[1], c, index)}
          </Grid>
          {withDate && (
            <>
              <Grid item xs={4} md={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                since
              </Grid>
              <Grid item xs={6} md={2.5}>
                <DatePicker
                  data-testid={`exclusion-date-picker-${index}`}
                  id={`exclusion-date-picker-${index}`}
                  aria-describedby="start-helper-text"
                  name="exclusion-date"
                  label="Date"
                  inputFormat="dd/MM/yyyy"
                  disableFuture={true}
                  value={c.date || defaultValues.date}
                  onChange={(newValue, event) => {
                    updateExclusion(index, event, newValue, withDate);
                  }}
                  renderInput={(params) => <TextField error={showErrorOnPage && dateAfterStartDate} {...params} />}
                />
              </Grid>
            </>
          )}
          {!withDate && <Grid item xs={1} md={3.5}></Grid>}
          <Grid item xs={2} md={1.5} sx={{ textAlign: "end" }}>
            <Button
              data-testid={`delete-rule-${index}`}
              sx={{ p: 2, mt: "2px" }}
              className="btn-delete"
              color="error"
              variant="contained"
              onClick={() => {
                deleteExclusion(index, withDate);
              }}
            >
              <DeleteSVG />
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <>
      {criteriaExists &&
        props.criteria
          ?.filter((c) => !!c.date === withDate)
          .map((c, index) => {
            return renderExclusionRule(c, index, objects, withDate, startDate);
          })}
      <Grid container>
        <Grid item xs={12}>
          {renderAddButton()}
        </Grid>
      </Grid>
    </>
  );
}
