import React, { useState, useEffect } from "react";
import { Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";

import { PreviewOffer } from "../../ComponentIndex";
import { publishErrorMessage } from "../../../events/events";

const PreviewOfferSection = ({ campaign, images, expanded = false }) => {
  const [isExpanded, setIsExpanded] = useState(expanded),
    [defaultInfoConfig, setDefaultInfoConfig] = useState(),
    [messages, setMessages] = useState(),
    handleAccordionChange = (event, newExpandedState) => {
    setIsExpanded(newExpandedState);
  };

  useEffect(() => {
    if (!!campaign.couponOfferMessages && campaign.couponOfferMessages.length > 0) {
      setMessages(campaign.couponOfferMessages[0]);

    } else {
      setMessages(campaign);
    }
  }, [campaign]);

  useEffect(() => {
    axios
      .get(window.promotion_url + "/campaigns/default-info-config")
      .then((response) => {
        setDefaultInfoConfig(response.data);
      })
      .catch((error) => {
        console.error("Error fetching default info config:", error);
        publishErrorMessage("Error fetching default info config. Please retry.");
      });
  }, []);

  return (
    <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="offer-message-content"
        id="offer-message-header"
        sx={{
          background: `${window.theme.colors.header}`,
          borderBottom: `1px solid ${window.theme.colors.notSelectable}`,
        }}
      >
        <Typography variant="h6">Preview Offer</Typography>
      </AccordionSummary>
      <AccordionDetails>
          <PreviewOffer
            defaultInfoConfig={defaultInfoConfig}
            formValues={campaign}
            messageFormValues={messages}
            images={images}
          />
      </AccordionDetails>
    </Accordion>
  );
};

export default PreviewOfferSection;
