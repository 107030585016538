import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Buffer } from "buffer";

import { checkAssignmentRulesExist, calculateRewardExpirationDate } from "../../utilities/campaignHelpers";

export default function PreviewOffer({ formValues, messageFormValues = {}, images, defaultInfoConfig }) {
  const [imageDataHERO, setImageDataHERO] = useState([]);
  const [imageDataBRAND, setImageDataBRAND] = useState([]);

  useEffect(() => {
    //  DYNAMIC LISTVIEW  HERO  BRAND
    function addEventRun() {
      if (!images || Object.keys(images).length === 0) {
        setImageDataHERO("");
        setImageDataBRAND("");
      } else {
        if (images.HERO !== undefined) {
          let readerHERO = new FileReader();
          readerHERO.readAsArrayBuffer(images["HERO"]);
          readerHERO.onload = function () {
            const imageTempData = new Uint8Array(readerHERO.result);
            var b64 = Buffer.from(imageTempData).toString("base64");
            setImageDataHERO(b64);
          };
        }

        if (images.BRAND !== undefined) {
          let readerBRAND = new FileReader();
          readerBRAND.readAsArrayBuffer(images["BRAND"]);
          readerBRAND.onload = function () {
            const imageTempData = new Uint8Array(readerBRAND.result);
            var b64 = Buffer.from(imageTempData).toString("base64");
            setImageDataBRAND(b64);
          };
        }
      }

      // update wallet balance if assignment criteria is not set (no rules)
      // and if campaignType is not Plan Kicker or TradeIn
      const noAssignmentRules = checkAssignmentRulesExist(formValues);

      const campaignTypeIsPlanKickerOrTradeInOrSurvey =
        formValues.campaignType === "Plan kicker" || formValues.campaignType === "Trade-in" || formValues.campaignType === "Survey";

      const walletBalance = noAssignmentRules && !campaignTypeIsPlanKickerOrTradeInOrSurvey ? formValues.couponValue * 1.0 : 0;

      const earliestExpireDateTime = calculateRewardExpirationDate(formValues.rewardExpireDays);

      const nullToDefaultDate = (date) => {
        if (!date) {
          return '1970-01-01T00:00:00';
        }

        return date;
      }

      window.addEventListener(
        "message",
        (e) => {
          if (e.data["preview"] === "offer") {
            document.getElementById("preview_offer_offerDetails_id").contentWindow.postMessage(
              {
                offer: JSON.stringify({
                  name: messageFormValues.couponOfferName,
                  issueDate: nullToDefaultDate(formValues.startDate),
                  eligibilityExpiryDate: nullToDefaultDate(formValues.endDate),
                  redemptionExpiryDate: nullToDefaultDate(formValues.endDate),
                  offerValue: formValues.couponValue * 1.0,
                  description: messageFormValues.couponOfferDetails,
                  status: "ELIGIBLE",
                  appealScore: 0.0,
                  URL: messageFormValues.couponOfferExternalUrl,
                  couponOfferMessage: [
                    {
                      couponOfferName: messageFormValues.couponOfferName,
                      couponOfferHeading: messageFormValues.couponOfferHeading,
                      couponCTATitle: messageFormValues.couponCTATitle,
                      couponOfferUrlTitle: messageFormValues.couponOfferUrlTitle,
                      couponOfferExternalUrl: messageFormValues.couponOfferExternalUrl,
                      couponOfferNotification: messageFormValues.couponOfferNotification,
                      couponOfferDetails: messageFormValues.couponOfferDetails,
                      couponOfferValueSubheading: messageFormValues.couponOfferValueSubheading,
                      couponOfferShortTAndC: messageFormValues.couponOfferShortTAndC,
                      couponOfferTandc: messageFormValues.couponOfferTAndC,
                      couponOfferLaunchCta: messageFormValues.couponOfferLaunchCta,
                      couponOfferAgentInstructions: messageFormValues.couponOfferAgentInstructions,
                      actionTitle: messageFormValues.actionTitle,
                      actionUrl: messageFormValues.actionUrl,
                      saveOfferSavedTitle: messageFormValues.saveOfferSavedTitle,
                      saveOfferUnsavedTitle: messageFormValues.saveOfferUnsavedTitle,
                    },
                  ],
                  campaignType: formValues.campaignType,
                  isGenericTradeIn: formValues.isGenericTradeIn,
                  launchUrl: formValues.url,
                }),
                image: imageDataHERO,
              },
              "*"
            );
          }

          if (e.data["preview"] === "home") {
            document.getElementById("preview_offer_home_id").contentWindow.postMessage(
              {
                summary: JSON.stringify({
                  customerStatus: "ACTIVE",
                  availableBalance: walletBalance,
                  availableBalanceDisplay: walletBalance,
                  potentialBalance: walletBalance,
                  dynamicOffers:
                    noAssignmentRules && !campaignTypeIsPlanKickerOrTradeInOrSurvey
                      ? []
                      : [
                          {
                            name: messageFormValues.couponOfferName,
                            issueDate: nullToDefaultDate(formValues.startDate),
                            eligibilityExpiryDate: nullToDefaultDate(formValues.endDate),
                            redemptionExpiryDate: nullToDefaultDate(formValues.endDate),
                            offerValue: formValues.couponValue * 1.0,
                            description: messageFormValues.couponOfferDetails,
                            status: "ELIGIBLE",
                            appealScore: 0.0,
                            couponOfferMessage: [
                              {
                                couponOfferName: messageFormValues.couponOfferName,
                                couponOfferHeading: messageFormValues.couponOfferHeading,
                                couponCTATitle: messageFormValues.couponCTATitle,
                                couponOfferUrlTitle: messageFormValues.couponOfferUrlTitle,
                                couponOfferExternalUrl: messageFormValues.couponOfferExternalUrl,
                                couponOfferNotification: messageFormValues.couponOfferNotification,
                                couponOfferDetails: messageFormValues.couponOfferDetails,
                                couponOfferValueSubheading: messageFormValues.couponOfferValueSubheading,
                                couponOfferShortTAndC: messageFormValues.couponOfferShortTAndC,
                                couponOfferTandc: messageFormValues.couponOfferTAndC,
                                couponOfferAgentInstructions: messageFormValues.couponOfferAgentInstructions,
                                actionTitle: messageFormValues.actionTitle,
                                actionUrl: messageFormValues.actionUrl,
                                saveOfferSavedTitle: messageFormValues.saveOfferSavedTitle,
                                saveOfferUnsavedTitle: messageFormValues.saveOfferUnsavedTitle,
                              },
                            ],
                            campaignType: formValues.campaignType,
                            isGenericTradeIn: formValues.isGenericTradeIn,
                          },
                        ],
                  carouselItems: [
                    { imageName: "Promotion UI_Home.png", destination: "ALL_OFFERS" },
                    { imageName: "Promotion UI_Home_rewards.png", destination: "REWARDS" },
                  ],
                  defaultInfoConfig: defaultInfoConfig,
                  earliestExpireDateTime: earliestExpireDateTime,
                }),
                image: imageDataBRAND,
              },
              "*"
            );
          }
        },
        false
      );
    }
    addEventRun();
  }, [images, imageDataHERO, imageDataBRAND, formValues, messageFormValues, defaultInfoConfig]);

  return (
    <Grid container spacing={2} sx={{ p: "16px 0" }}>
      <Grid item xs={12}>
        <Typography variant="h3" color="primary" sx={{ mb: 3 }}>
          Preview
        </Typography>
        <iframe
          id="preview_offer_home_id"
          title="preview_offer_home"
          height="800"
          width="400"
          src={window.preview_offer_home_url}
        ></iframe>
        <iframe
          id="preview_offer_offerDetails_id"
          title="preview_offer_offerDetails"
          height="800"
          width="400"
          src={window.preview_offer_offerDetails_url}
        ></iframe>
      </Grid>
    </Grid>
  );
}
