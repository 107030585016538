import React from "react";
import {Button, Grid, Paper, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";

import {CampaignTable, ComponentGuard} from "../ComponentIndex";

import {tableTypePathMap} from "../../constants/consts";

export default function CampaignList({ title, path = tableTypePathMap.CAMPAIGN, isArchive, isTemplate = false }) {

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Stack sx={{ position: "relative", mb: 1.5 }}>
            <Stack spacing={5}>
              <Typography variant="h1" component="div">
                {title}
              </Typography>
              <ComponentGuard allowedRoles={isTemplate ? window.appRoles.createTemplates : window.appRoles.createCampaign}>
                {!isArchive && (
                  <Link
                    to={"new"}
                    style={{ position: "absolute", right: 0, marginTop: 1 }}
                  >
                    <Button data-testid={`new-campaign`} id="newCampaign" variant="contained" size="Small">
                      New
                    </Button>
                  </Link>
                )}
              </ComponentGuard>
            </Stack>
          </Stack>
          <CampaignTable isArchive={isArchive} path={path}/>
        </Paper>
      </Grid>
    </Grid>
  );
}
