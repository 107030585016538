import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { BannerImage3 } from "../../../../../assets";
import {
  CustomButton,
  LeftContentQuestion,
  RightContentQuestion,
} from "../../../../ComponentIndex";
import { connect } from "react-redux";
import { handleModalConfirmation } from "../../../../../store/actions/modal";
import { handleCheckNextQuestion } from "../../../../../utilities/utils";

const styleBox = {
  backgroundColor: "#17412F",
  borderRadius: 3,
  paddingTop: 2,
  paddingLeft: 3,
  paddingRight: 3,
  overflowY: "auto",
};

function FreeTextQuestion({
  dispatch,
  question,
  formQuestion,
  handleQuestion,
}) {

  const handleNextQuestion = () => {
    handleQuestion(dispatch, {
      questionType: question.questionType,
      questionStep: question.questionStep + 1,
    });
    formQuestion.question_type = 2; // 2 = Free Text
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <LeftContentQuestion title={"Free Text Question"}>
            <Typography variant="body1" mt={1}>
              The Free Text Quiz offers a platform for users to share in-depth
              feedback or suggestions. Through this, One NZ can gather
              personalised experiences about their broadband service or learn
              about additional features users might want in future mobile plans.
            </Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              Example: "Could you suggest a feature you'd like to see in our
              next broadband package upgrade?"
            </Typography>
          </LeftContentQuestion>
        </Grid>
        <Grid item xs={4}>
          <RightContentQuestion
            title="Share what could help improve experience with us. Type in thespace provided below"
            image={BannerImage3}
          >
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={12}>
                <Box sx={styleBox}>
                  <Typography
                    color={"#fff"}
                    sx={{ wordBreak: "break-all" }}
                    height={150}
                    variant="h6"
                    fontSize={14}
                  >
                    Tell us what you think
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </RightContentQuestion>
        </Grid>
        <Grid item xs={12} mt={2}>
          <CustomButton
            type="submit"
            className={"custom_button black"}
            onHandleClick={() => handleCheckNextQuestion({
              dispatch,
              currentQuestionType: formQuestion.question_type,
              defaultQuestionType: 2,
              formQuestion,
              handleNextQuestion,
              handleModalConfirmation
            })}
          >
            <span>Continue</span>
          </CustomButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {
    formQuestion: state.questionBank.questionData,
    question: state.questionBank.checkQuestion,
  };
};
export default connect(mapStatetoProps, null)(FreeTextQuestion);
