import React, { useEffect, useState } from "react";
import { Typography, Grid, Paper, Container, Button } from "@mui/material";
import axios from "axios";

import { publishErrorMessage, publish } from "../events/events";
import { CampaignTypeToggle } from "../components/ComponentIndex";
import SaveSVG from "../assets/SaveSVG";

export default function Settings() {
  const [campaignTypes, setCampaignTypes] = useState([]);

  useEffect(() => {
    const fetchCampaignTypes = async () => {
      try {
        const response = await axios.get(window.promotion_url + "/campaigns/types?all=true");
        const sortedCampaignTypes = response.data.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          return nameA.localeCompare(nameB);
        });
        setCampaignTypes(sortedCampaignTypes);
      } catch (error) {
        console.error("Error fetching or sorting campaign types:", error);
        publishErrorMessage("Error fetching or sorting campaign types. Please retry.");
      }
    };

    if (campaignTypes.length === 0) {
      fetchCampaignTypes();
    }
  }, [campaignTypes]);

  const handleSaveCampaignTypes = async () => {
    try {
      await Promise.all(
        campaignTypes.map(async (c) => {
          const response = await axios.put(window.promotion_url + "/campaigns/types/" + c.id, c);
          console.log("Campaign type saved:", response.data);
        })
      );
      publish("showNotification", { severity: "info", message: "Campaign Types saved." });
    } catch (error) {
      console.error("Error saving campaign types:", error);
      publishErrorMessage("Error saving some or all campaign types. Please retry.");
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              mb: 2,
            }}
          >
            <div id="settings-page">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h1">Settings</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h3">Campaigns Types</Typography>
                </Grid>
                <Grid item xs={8} sx={{ alignItems: "center", display: "flex" }}>
                  {campaignTypes.length > 0 && (
                    <Button
                      className="btn-save"
                      onClick={handleSaveCampaignTypes}
                      sx={{ minWidth: "45px", ml: 1, mr: 1 }}
                      title="Save"
                    >
                      <SaveSVG />
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {campaignTypes.map((campaignType) => (
                    <CampaignTypeToggle
                      setCampaignType={setCampaignTypes}
                      campaignType={campaignType}
                      allCampaignTypes={campaignTypes}
                      key={`${campaignType.name}-campaignType`}
                    />
                  ))}
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
