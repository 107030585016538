import React, { useState } from "react";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { statusMapping } from "../../../constants/consts";

export const getExclusionRuleValue = (rule) => rule.value || rule.existingCampaign || rule.existingCampaignType;
export const getExclusionRuleRuleKey = (rule) => statusMapping[rule.ruleKey || rule.status] || rule.status;

export const getMappedStatus = (value) => {
  const displayKey = Object.keys(statusMapping).find((key) => statusMapping[key] === value);
  return displayKey || value;
};

const CouponsStatusSection = ({ campaign, expanded = true }) => {
  const [isExpanded, setIsExpanded] = useState(expanded),
    couponStatusNumbers = campaign.couponStatusNumbers;
  const handleAccordionChange = (event, newExpandedState) => {
    setIsExpanded(newExpandedState);
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="eligibility-exclusion-rules-content"
        id="eligibility-exclusion-rules-header"
        sx={{
          background: `${window.theme.colors.header}`,
          borderBottom: `1px solid ${window.theme.colors.notSelectable}`,
        }}
      >
        <Typography variant="h6">Coupon Distribution</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Grid container spacing={0}>
            {couponStatusNumbers?.map((obj) => (
                <Grid item xs={12} sx={{ p: 2, background: `${window.theme.colors.secondary}` }}>
                    <Typography>
                    {obj.couponStatus}: {obj.couponNumbers}
                    </Typography>
                </Grid>
            ))}
            {!(couponStatusNumbers.length > 0) && 
                <Grid item xs={12} sx={{ p: 2, background: `${window.theme.colors.secondary}` }}>
                    <Typography>
                      No coupons distributed for this campaign
                    </Typography>
                </Grid>}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CouponsStatusSection;
