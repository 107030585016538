import React, {useEffect, useState} from "react";
import {FormControl, Grid, TextField, Typography} from "@mui/material";
import {genericContentDisplayed} from "../../utilities/utils";
import {
  isActionItemCampaign,
  isOverFiveHundred,
  isOverHundred,
  isOverHundredAndRequired,
  isOverSixty,
  isOverThousand,
  isOverThousandAndRequired,
  isValidExternalUrl,
} from "../../utilities/campaignHelpers";

export default function OfferMessage(props) {
  const formValues = props.formValues,
    messageFormValues = props.messageFormValues,
    setMessageFormValues = props.setMessageFormValues,
    [hasOfferNameChanged, setHasOfferNameChanged] = useState(false),
    [hasOfferHeadingChanged, setHasOfferHeadingChanged] = useState(false),
    [hasExternalURLChanged, setHasExternalURLChanged] = useState(false),
    [hasOfferDetailsChanged, setHasOfferDetailsChanged] = useState(false),
    [hasAgentInstructionsChanged, setHasAgentInstructionsChanged] = useState(false),
    [hasActionUrlChange, setHasActionUrlChange] = useState(false),
    [hasSavedTitleChange, setHasSavedTitleChange] = useState(false),
    [hasUnsavedTitleChange, setHasUnsavedTitleChange] = useState(false),
    isTemplate = props.isTemplate,
    [resetErrorState, setResetErrorState] = useState(props.resetErrorState);

  useEffect(() => {
    setResetErrorState(props.resetErrorState);
  }, [props.resetErrorState]);

  if (resetErrorState) {
    setHasOfferNameChanged(true);
    setHasOfferHeadingChanged(true);
    setHasExternalURLChanged(true);
    setHasOfferDetailsChanged(true);
    setHasAgentInstructionsChanged(true);
    setHasActionUrlChange(true);
    setHasUnsavedTitleChange(true);
    setHasSavedTitleChange(true);
    setResetErrorState(false);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMessageFormValues({
      ...messageFormValues,
      [name]: value,
    });

    switch (name) {
      case "couponOfferName":
        if (!hasOfferNameChanged) {
          setHasOfferNameChanged(true);
        }
        break;
      case "couponOfferHeading":
        if (!hasOfferHeadingChanged) {
          setHasOfferHeadingChanged(true);
        }
        break;
      case "couponOfferExternalUrl":
        if (!hasExternalURLChanged) {
          setHasExternalURLChanged(true);
        }
        break;
      case "couponOfferDetails":
        if (!hasOfferDetailsChanged) {
          setHasOfferDetailsChanged(true);
        }
        break;
      case "couponOfferAgentInstructions":
        if (!hasAgentInstructionsChanged) {
          setHasAgentInstructionsChanged(true);
        }
        break;
      case "actionUrl":
        if (!hasActionUrlChange) {
          setHasActionUrlChange(true);
        }
        break;
      case "saveOfferUnsavedTitle":
        if (!hasUnsavedTitleChange) {
          setHasUnsavedTitleChange(true);
        }
        break;
      case "saveOfferSavedTitle":
        if (!hasSavedTitleChange) {
          setHasSavedTitleChange(true);
        }
        break;
      default:
        break;
    }
  };

  const offerNameHelperText = () => {
    return hasOfferNameChanged && isOverHundredAndRequired(messageFormValues.couponOfferName) && !isTemplate
      ? isOverHundred(messageFormValues.couponOfferName)
        ? `Please enter an offer name with 100 characters or less. Currently using ${messageFormValues.couponOfferName.length} characters.`
        : "Please enter an offer name."
      : "";
  };

  const offerHeadingHelperText = () => {
    return hasOfferHeadingChanged && isOverHundredAndRequired(messageFormValues.couponOfferHeading) && !isTemplate
      ? isOverHundred(messageFormValues.couponOfferHeading)
        ? `Please enter an offer heading with 100 characters or less. Currently using ${messageFormValues.couponOfferHeading.length} characters.`
        : "Please enter an offer heading."
      : "";
  };

  const offerDetailsHelperText = () => {
    return hasOfferDetailsChanged && isOverThousandAndRequired(messageFormValues.couponOfferDetails) && !isTemplate
      ? isOverThousand(messageFormValues.couponOfferDetails)
        ? `Please provide offer details in 1000 characters or less. Currently using ${messageFormValues.couponOfferDetails.length} characters.`
        : "Please provide offer details."
      : "";
  };

  const ctaTitleHelperText = () => {
    return invalidCTATitle
      ? `Please use 60 characters or less. Currently using ${messageFormValues.couponCTATitle.length} characters.`
      : "";
  };

  const invalidAgentInstructions =
    hasAgentInstructionsChanged && !isTemplate && isOverThousandAndRequired(messageFormValues.couponOfferAgentInstructions);

  const agentInstructionsHelperText = () => {
    return invalidAgentInstructions
      ? isOverThousand(messageFormValues.couponOfferAgentInstructions)
        ? `Please provide some instructions for the agent in 1000 characters or less. Currently using ${messageFormValues.couponOfferAgentInstructions.length} characters.`
        : "Please provide some instructions for the agent."
      : "";
  };

  const showGenericContent = genericContentDisplayed(formValues);
  const genericContentError = isOverHundred(messageFormValues.couponOfferValueSubheading);

  const actionTitleHelperText = () => {
    return invalidActionTitle
        ? `Please use 100 characters or less. Currently using ${messageFormValues.actionTitle.length} characters.`
        : "";
  };

  const actionUrlHelperText = () => {
    return invalidActionUrl
        ? `Please use 200 characters or less. Currently using ${messageFormValues.actionUrl.length} characters.`
        : "";
  };

  const savedTitleHelperText = () => {
    return invalidSavedTitle
        ? `Please use 100 characters or less. Currently using ${messageFormValues.saveOfferSavedTitle.length} characters.`
        : "";
  };

  const unsavedTitleHelperText = () => {
    return invalidUnsavedTitle
        ? `Please use 100 characters or less. Currently using ${messageFormValues.saveOfferUnsavedTitle.length} characters.`
        : "";
  };

  const genericContentHelperText = () => {
    return genericContentError
      ? `Please use 100 characters or less. Currently using ${messageFormValues.couponOfferValueSubheading.length} characters.`
      : "";
  };

  const urlTitleHelperText = () => {
    return invalidURLTitle
      ? `Please use 100 characters or less. Currently using ${messageFormValues.couponOfferUrlTitle.length} characters.`
      : "";
  };

  const shortTCError = isOverFiveHundred(messageFormValues.couponOfferShortTAndC);

  const shortTCHelperText = () => {
    return shortTCError
      ? `Please use 500 characters or less. Currently using ${messageFormValues.couponOfferShortTAndC.length} characters.`
      : "";
  };

  const externalURLError = hasExternalURLChanged && !isValidExternalUrl(messageFormValues.couponOfferExternalUrl);
  const invalidCTATitle = !isTemplate && isOverSixty(messageFormValues.couponCTATitle);
  const invalidURLTitle = !isTemplate && isOverHundred(messageFormValues.couponOfferUrlTitle);
  const invalidActionUrl = !isTemplate && hasActionUrlChange && !isValidExternalUrl(messageFormValues.actionUrl);
  const invalidActionTitle = !isTemplate && isOverHundred(messageFormValues.actionTitle);
  const invalidUnsavedTitle = !isTemplate && hasUnsavedTitleChange && isOverHundredAndRequired(messageFormValues.saveOfferUnsavedTitle);
  const invalidSavedTitle = !isTemplate && hasSavedTitleChange && isOverHundredAndRequired(messageFormValues.saveOfferSavedTitle);

  return (
    <Grid container spacing={2} sx={{ p: "16px 0" }}>
      <Grid item xs={12}>
        <Typography variant="h3" color="primary">
          Offer Message
        </Typography>
        <h3 style={{ marginTop: "24px" }}>Offer Overview</h3>
        <FormControl fullWidth>
          <TextField
            data-testid={`couponOfferName-input`}
            id="couponOfferName-input"
            aria-describedby="couponOfferName-helper-text"
            label="Offer Name"
            name="couponOfferName"
            variant="standard"
            onChange={handleInputChange}
            required={!isTemplate}
            error={hasOfferNameChanged && !isTemplate && isOverHundredAndRequired(messageFormValues.couponOfferName)}
            value={messageFormValues.couponOfferName}
            helperText={offerNameHelperText()}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            data-testid={`couponOfferHeading-input`}
            id="couponOfferHeading-input"
            aria-describedby="couponOfferHeading-helper-text"
            label="Offer Heading"
            name="couponOfferHeading"
            variant="standard"
            onChange={handleInputChange}
            required={!isTemplate}
            error={hasOfferHeadingChanged && !isTemplate && isOverHundredAndRequired(messageFormValues.couponOfferHeading)}
            helperText={offerHeadingHelperText()}
            value={messageFormValues.couponOfferHeading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            data-testid={`couponOfferDetails-input`}
            id="couponOfferDetails-input"
            aria-describedby="couponOfferDetails-helper-text"
            multiline
            rows={3}
            label="Offer Details"
            name="couponOfferDetails"
            onChange={handleInputChange}
            value={messageFormValues.couponOfferDetails}
            required={!isTemplate}
            error={hasOfferDetailsChanged && !isTemplate && isOverThousandAndRequired(messageFormValues.couponOfferDetails)}
            helperText={offerDetailsHelperText()}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            data-testid={`couponCTATitle-input`}
            id="couponCTATitle-input"
            aria-describedby="couponCTATitle-helper-text"
            label="Offer CTA Title"
            name="couponCTATitle"
            variant="standard"
            onChange={handleInputChange}
            value={messageFormValues.couponCTATitle}
            error={invalidCTATitle}
            helperText={ctaTitleHelperText()}
          />
        </FormControl>
      </Grid>
      {showGenericContent && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              data-testid={`genericContent-input`}
              id="genericContent-input"
              aria-describedby="genericContent-helper-text"
              label="Value Sub-heading"
              name="couponOfferValueSubheading"
              variant="standard"
              onChange={handleInputChange}
              value={messageFormValues.couponOfferValueSubheading}
              error={genericContentError}
              helperText={genericContentHelperText(messageFormValues.couponOfferValueSubheading)}
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <h3 style={{ marginTop: "24px" }}>Additional Details for Detailed Offer View</h3>
        <FormControl fullWidth>
          <TextField
            data-testid={`couponOfferUrlTitle-input`}
            id="couponOfferUrlTitle-input"
            aria-describedby="couponOfferUrlTitle-helper-text"
            label="URL Title"
            name="couponOfferUrlTitle"
            variant="standard"
            value={messageFormValues.couponOfferUrlTitle}
            onChange={handleInputChange}
            error={invalidURLTitle}
            helperText={urlTitleHelperText()}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            data-testid={`couponOfferExternalUrl-input`}
            id="couponOfferExternalUrl-input"
            aria-describedby="couponOfferExternalUrl-helper-text"
            label="External URL"
            name="couponOfferExternalUrl"
            variant="standard"
            required={!isTemplate}
            value={messageFormValues.couponOfferExternalUrl}
            onChange={handleInputChange}
            error={externalURLError}
            helperText={externalURLError ? "Please enter a valid external URL." : ""}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            data-testid={`couponOfferShortTAndC-input`}
            id="couponOfferShortTAndC-input"
            aria-describedby="couponOfferShortTAndC-helper-text"
            multiline
            rows={3}
            label="Short Terms and Conditions"
            name="couponOfferShortTAndC"
            onChange={handleInputChange}
            value={messageFormValues.couponOfferShortTAndC}
            error={shortTCError}
            helperText={shortTCHelperText()}
          />
        </FormControl>
      </Grid>
      { formValues.campaignType === "Survey" && (
      <Grid item xs={12}>
        <h3 style={{ marginTop: "24px" }}>Launch CTA</h3>
        <FormControl fullWidth>
          <TextField
            data-testid={`couponOfferLaunchCta-input`}
            id="couponOfferLaunchCta-input"
            aria-describedby="couponOfferLaunchCta-helper-text"
            label="Launch CTA"
            name="couponOfferLaunchCta"
            variant="standard"
            value={messageFormValues.couponOfferLaunchCta}
            onChange={handleInputChange}
            helperText={genericContentHelperText()}
          />
        </FormControl>
      </Grid>
      ) }
      { isActionItemCampaign(formValues) && (
          <Grid item xs={12}>
            <h3 style={{marginTop: "24px"}}>Action Items</h3>
            <FormControl fullWidth>
              <TextField
                  data-testid={`actionTitle-input`}
                  id="actionTitle-input"
                  aria-describedby="actionTitle-helper-text"
                  label="Action Title"
                  name="actionTitle"
                  variant="standard"
                  value={messageFormValues.actionTitle}
                  onChange={handleInputChange}
                  helperText={actionTitleHelperText()}
              />
            </FormControl>
          </Grid>
      )}
      { isActionItemCampaign(formValues) && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                  data-testid={`actionUrl-input`}
                  id="actionUrl-input"
                  aria-describedby="actionUrl-helper-text"
                  label="Action Url"
                  name="actionUrl"
                  variant="standard"
                  value={messageFormValues.actionUrl}
                  onChange={handleInputChange}
                  helperText={actionUrlHelperText()}
              />
            </FormControl>
          </Grid>
      )}
      { formValues.isSavedOffer && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                  data-testid={`saveOfferUnsavedTitle-input`}
                  id="saveOfferUnsavedTitle-input"
                  aria-describedby="saveOfferUnsavedTitle-helper-text"
                  label="Save Offer CTA - Unsaved State"
                  name="saveOfferUnsavedTitle"
                  variant="standard"
                  value={messageFormValues.saveOfferUnsavedTitle}
                  onChange={handleInputChange}
                  error={invalidUnsavedTitle}
                  required={!isTemplate}
                  helperText={unsavedTitleHelperText()}
              />
            </FormControl>
          </Grid>
      )}
      { formValues.isSavedOffer && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                  data-testid={`saveOfferSavedTitle-input`}
                  id="saveOfferSavedTitle-input"
                  aria-describedby="saveOfferSavedTitle-helper-text"
                  label="Save Offer CTA - Saved State"
                  name="saveOfferSavedTitle"
                  variant="standard"
                  value={messageFormValues.saveOfferSavedTitle}
                  onChange={handleInputChange}
                  error={invalidSavedTitle}
                  required={!isTemplate}
                  helperText={savedTitleHelperText()}
              />
            </FormControl>
          </Grid>
      )}
      <Grid item xs={12}>
        <h3 style={{ marginTop: "24px" }}>Customer Communications and Help</h3>
        <FormControl fullWidth>
          <TextField
            data-testid={`couponOfferAgentInstructions-input`}
            id="couponOfferAgentInstructions-input"
            aria-describedby="couponOfferAgentInstructions-helper-text"
            multiline
            rows={3}
            label="Agent Instructions"
            name="couponOfferAgentInstructions"
            value={messageFormValues.couponOfferAgentInstructions}
            onChange={handleInputChange}
            required={!isTemplate}
            error={invalidAgentInstructions}
            helperText={agentInstructionsHelperText()}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
