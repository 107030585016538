import React from "react";
import { Grid, Typography } from "@mui/material";
import {
  CampaignDetailsSection,
  OfferMessageSection,
  EligibilityCriteriaSection,
  ImagesDisplaySection,
  DownloadUsers,
} from "../ComponentIndex";
import {isMarketingCampaignType} from "../../utilities/campaignHelpers";

export default function FormSummary({ formValues, messageFormValues, images, ruleData, masterdata, setFormValues }) {
  return (
    <Grid container spacing={2} sx={{ p: "16px 0" }}>
      <Grid item xs={12}>
        <Typography variant="h3" color="primary" sx={{ mb: 3 }}>
          Campaign Summary
        </Typography>
        <CampaignDetailsSection campaign={formValues} />
      </Grid>
      <Grid item xs={12}>
        <EligibilityCriteriaSection campaign={formValues} ruleData={ruleData} masterdata={masterdata} />
      </Grid>
      <Grid item xs={12}>
        <ImagesDisplaySection localImages={images} />
      </Grid>
      <Grid item xs={12} hidden={isMarketingCampaignType(formValues)}>
        <OfferMessageSection couponOfferMessages={[messageFormValues]}
                             formValues={formValues}/>
      </Grid>

      <DownloadUsers formValues={formValues} setFormValues={setFormValues} showCalculate={false} preventSave={false} />
    </Grid>
  );
}
