import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { BannerImage1, BinaryImage1 } from "../../assets";
import { PreviewImage } from "../ComponentIndex";

const styles = {
  marginTop: -2,
  paddingBottom: 3,
};

const styleButton = (index) => ({
  "&:hover": { backgroundColor: index % 2 === 1 ? "#FFF" : "#17412F"},
  mt: 4,
  backgroundColor: index % 2 === 1 ? "#FFF" : "#17412F" ,
  color: index % 2 === 1 ? "#000" : "#FFF",
  minHeight: 46,
  width: 120,
  fontSize: 12,
  borderRadius: 2,
  textTransform: "none",
  marginBottom: 4,
});
function BinaryPreview({ questionDetail }) {
  return (
    <Stack gap={1} style={styles}>
      <div>
        <PreviewImage
          dataImage={questionDetail.banner_image}
          defaultImage={BannerImage1}
          alt={"BannerImagePreview"}
          className={"banner_image"}
        />
      </div>
      <Typography variant="h7" textAlign={"center"} mb={2} mt={1}>
        {questionDetail?.title}
      </Typography>
      <Grid container spacing={4} justifyContent={"center"}>
        {questionDetail?.options?.map((item, index) => (
          <Grid item xs={6} key={index}>
            <Stack alignItems={"center"}>
              <PreviewImage
                dataImage={item.image}
                defaultImage={BinaryImage1}
                alt={`binary${index}`}
                styles={{ width: "80%" }}
              />
              <Button sx={styleButton(index)} variant="contained">
                <span>{item.answer}</span>
              </Button>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

export default BinaryPreview;
