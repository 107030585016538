import { parseISO, format } from "date-fns";
import { msalConfig } from "../config/AuthConfig";
import { msalInstance } from "../lib/MsalAuth";
import axios from "axios";

// is Campaign Name Valid - checks against existing campaigns
export const isNameInvalid = (currentCampaign, campaigns, editMode = false) => {
  if (!campaigns) {
    return false;
  }
  const matchingCampaign = campaigns?.find((campaign) => campaign.name === currentCampaign.name);

  if (matchingCampaign) {
    if (editMode) {
      return matchingCampaign.id !== currentCampaign.id;
    } else {
      return true;
    }
  }

  return false;
};

export const doesNameContainIllegalCharacters = (currentCampaign, editMode = false) => {
  if (editMode) {
    return false;
  }
  const invalidCharacters = /[/\\%#;?[\]]/;

  return invalidCharacters.test(currentCampaign.name);
};

// Used to format larger numbers with commas for readability
export const formatLargeNumberWithCommas = (number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// When campaign is in edit / draft mode, convert date to UTC to ensure displayed date is same as date in database
export const convertDraftDate = (date) => {
  if (date instanceof Date) {
    return new Date(date).setUTCHours(0, 0, 0, 0);
  } else if (typeof date === "string") {
    const parts = date.split("-");
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1;
    const day = parseInt(parts[2]);

    return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
  } else {
    return date;
  }
};

// Is the end date of the campaign before the start date
export const isEndDateBeforeStartDate = (startDate, endDate) => {
  return new Date(endDate) < new Date(startDate);
};

// Is the state date of the campaign in the past
export const isStartDateInThePast = (startDate) => {
  return new Date(startDate) < new Date(new Date().setHours(0, 0, 0, 0));
};

// Convert date to ISO format before submitting to database
export const toIsoDate = (date) => {
  const newDate = new Date(date);
  const day = newDate.getDate().toString().padStart(2, "0");
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
  const year = newDate.getFullYear();
  return `${year}-${month}-${day}T00:00:00.000Z`;
};

// Convert date to ISO format before submitting to database, using above toIsoDate function
export const dateToString = (date) => {
  if (date instanceof Date) {
    return toIsoDate(date);
  } else {
    const newDate = new Date(date);
    return toIsoDate(newDate);
  }
};

// Format date to display in the formatString provided, otherwise return invalid
export const formatDate = (dateInput, formatString) => {
  let date;

  if (dateInput instanceof Date && !isNaN(dateInput)) {
    date = dateInput;
  } else if (typeof dateInput === "string") {
    const parsedDate = parseISO(dateInput);
    if (isDateFormatValid(parsedDate)) {
      date = parsedDate;
    } else {
      return "Invalid or missing date";
    }
  } else {
    return "Invalid or missing date";
  }

  return format(date, formatString);
};

// Is the date format provided valid
export const isDateFormatValid = (dateString) => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
};

// Remove duplicates from an array
export const removeDuplicates = (arr) => {
  const uniqueSet = new Set(arr);
  return [...uniqueSet];
};

export const isDropdownOperator = (operator) => {
  return operator === "IS" || operator === "NOT";
};

// Check is URL is valid
export const isValidUrl = (value) => {
  const regex = new RegExp("^(https?|http)://([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?");
  return value.length > 200 ? false : regex.test(value);
};

// Check if value is "Trade-in"
export const tradeInGenericDisplayed = (formValues) => {
  return formValues.campaignType === "Trade-in";
};

// Check if value is "Trade-in" and Generic Trade-in is selected
export const genericContentDisplayed = (formValues) => {
  return formValues.campaignType === "Trade-in" && formValues.isGenericTradeIn;
};

// Toggle Button Styles
export const getToggleButtonStyle = (isSelected, marginRight, marginLeft, minWidth) => {
  return {
    borderRadius: "50px !important",
    fontWeight: "500",
    p: "4px 8px",
    mr: marginRight,
    minWidth: minWidth,
    ml: marginLeft,
    fontSize: "1rem",
    color: isSelected ? `${window.theme.colors.inverseFont} !important` : `${window.theme.colors.primary}`,
    background: isSelected ? `${window.theme.colors.primary} !important` : "none",
    border: isSelected ? `2px ${window.theme.colors.primary} solid` : "2px rgba(0,0,0,0) solid",
  };
};

export const windowSizeUnderSmTablet = (screenSize) => {
  return screenSize < (!!window?.theme?.breakPoints?.smTablet ? window.theme.breakPoints.smTablet : 768);
};

export const windowSizeUnderLgTablet = (screenSize) => {
  return screenSize < (!!window?.theme?.breakPoints?.lgTablet ? window.theme.breakPoints.lgTablet : 960);
};

export const windowSizeUnderLaptop = (screenSize) => {
  return screenSize < (!!window?.theme?.breakPoints?.laptop ? window.theme.breakPoints.laptop : 1100);
};

export const windowSizeUnderMob = (screenSize) => {
  return screenSize < (!!window?.theme?.breakPoints.mobile ? window.theme.breakPoints.mobile : 600);
};

export const capitaliseWord = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const addAsterisk = (label, isRequired) => {
  return label + (isRequired ? " *" : "");
}

export const splitData = (data, separator) => {
  if (!data) return ""
  return data.split(separator)
}

export const getIDTokenClaims = () => {
  if (window.disableSso) {
    return ""
  }

  const currentAccount = msalInstance.getActiveAccount();
  if (currentAccount) {
    if (currentAccount.tenantId === msalConfig.auth.tenantId) {
      const idTokenClaims = currentAccount.idTokenClaims;
      return idTokenClaims?.roles?.join(",")
    }
  } else {
    return ""
  }
}

export const fetchImage = async (imageUrl, convertType) => {
  let getFileName = ""
  if (imageUrl) {
    getFileName = splitData(imageUrl, "/")?.pop()
  }
  try {
    if (!getFileName) return ""
    
    const response = await axios.get(`${window.assets_url}/survey/sso${imageUrl}`, {
      responseType: "blob",
    });
    
    if (getFileName) {
      const blob = response.data;
      if (convertType === "detail") {
        return URL.createObjectURL(blob)
      }
      return {[`${imageUrl}`]: URL.createObjectURL(blob)}
    }
    
  } catch (error) {
    console.error(`Error fetching image ${getFileName}:`, error);
  }
};

export const handleCheckNextQuestion = ({
  dispatch, 
  currentQuestionType, 
  defaultQuestionType, 
  formQuestion, 
  handleNextQuestion,
  handleModalConfirmation
}) => {

  if (+currentQuestionType === defaultQuestionType) {
    handleNextQuestion();
    return;
  }
  if (
    !formQuestion?.title ||
    (!formQuestion?.information &&
      !formQuestion?.free_text_feedback &&
      formQuestion?.options?.some((item) => !item.answer))
  ) {
    handleNextQuestion();
    return;
  }
  handleModalConfirmation(dispatch, {
    isOpen: true,
    type: "change-question",
    data: null,
  });
};