import React from "react";
import { Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import ContentDetail from "./ContentDetail";
import { publish, publishErrorMessage } from "../../../events/events";
import { CustomModalConfirmation, ModalRejection } from "../../ComponentIndex";
import {
  detailQuestionBank,
  submitApprovalRejectQuestionBank,
} from "../../../store/actions/questionBank";

function handleSuccessMessage(type) {
  if (type === "approve") {
    return "Question successfully Approved";
  } else if (type === "reject") {
    return "Question successfully Rejected";
  }
}

function ViewDetail({ dispatch, modalConfirmation, modalQuestion }) {
  const [isLoading, setIsloading] = React.useState(false);

  React.useEffect(() => {
    detailQuestionBank(dispatch, modalQuestion.data?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, modalQuestion.data?.id]);

  function handleApproval(params) {
    const payload = {
      id: params.data.id,
      type: "approve",
      body: params.data.body,
    };
    submitApprovalRejection(payload);
  }
  function handleRejection(params) {
    const payload = {
      id: params.data.id,
      type: "reject",
      body: {
        approval_status: params.approvalStatus,
        reason: params.reason,
      },
    };
    submitApprovalRejection(payload);
  }

  function submitApprovalRejection(payload) {
    setIsloading(true);
    submitApprovalRejectQuestionBank({ id: payload.id, body: payload.body })
      .then(() => {
        publish("showNotification", {
          message: handleSuccessMessage(payload.type),
          severity: "success",
        });
      })
      .catch((error) => {
        publishErrorMessage(error?.response?.data?.title);
      })
      .finally(() => {
        window.location.reload();
      });
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
      padding={4}
    >
      <Grid item xs={10}>
        <ContentDetail />
        <CustomModalConfirmation
          dispatch={dispatch}
          modalConfirmation={modalConfirmation}
          onHandleSubmit={handleApproval}
          isLoading={isLoading}
        >
          <>
            <Typography
              id="modal-modal-title"
              variant="body1"
              fontWeight={600}
              fontSize={32}
              lineHeight={"normal"}
            >
              Are you sure to approve this question?
            </Typography>
          </>
        </CustomModalConfirmation>
        <ModalRejection
          isLoading={isLoading}
          onHandleSubmit={handleRejection}
        />
      </Grid>
    </Grid>
  );
}

const mapStatetoProps = (state) => {
  return {
    modalQuestion: state.modal.modalQuestion,
    modalConfirmation: state.modal.modalConfirmation,
  };
};
export default connect(mapStatetoProps, null)(ViewDetail);
