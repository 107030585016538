import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { CustomButton, FormikControl, Title } from "../../../../ComponentIndex";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  setQuestionData,
} from "../../../../../store/actions/questionBank";
import { connect } from "react-redux";
import { handleModalConfirmation } from "../../../../../store/actions/modal";

const AutoFillValue = ({ questionDetail }) => {
  const { setFieldValue } = useFormikContext();

  React.useEffect(() => {
    setFieldValue("dataQuestion", {
      title: questionDetail.data.title,
      banner_image: questionDetail.data.banner_image,
      question_type: questionDetail.data.question_type,
      information: questionDetail.data.information,
      show_feedback: questionDetail.data.show_feedback,
      free_text_feedback: questionDetail.data.free_text_feedback,
      options: null,
      is_submitted: false,
    });
  }, [questionDetail, setFieldValue]);
  return null;
};

function FreeTextDetails({
  dispatch,
  question,
  formQuestion,
  modalConfirmation,
  handleQuestion,
  modalQuestion,
  questionDetail,
  isDataChanged,
  setIsDataChanged,
}) {
  const [modalType, setModalType] = React.useState("");

  const validationSchema = Yup.object({
    dataQuestion: Yup.object().shape({
      title: Yup.string().required("This is a required field"),
      show_feedback: Yup.boolean(),
      free_text_feedback: Yup.string().when("show_feedback", {
        is: true,
        then: Yup.string().nullable().required("This is a required field"),
        otherwise: Yup.string().nullable(),
      }),
    }),
  });

  React.useEffect(() => {
    setModalType(modalConfirmation.type);
  }, [modalConfirmation]);

  function handleChange(ev, setFieldValue, dataQuestion) {
    setQuestionData(dispatch, {
      ...dataQuestion,
      show_feedback: ev.target.checked,
    });
    setFieldValue("dataQuestion.free_text_feedback", "");
  }
  function handleQuestionNextBack(nextOrBack) {
    handleQuestion(dispatch, {
      questionType: question.questionType,
      questionStep: nextOrBack,
    });
  }
  function handleModal(type, data) {
    if (modalQuestion.type === "edit") {
      data = {
        id: modalQuestion.data.id,
        body: data,
      };
    }

    handleModalConfirmation(dispatch, {
      isOpen: true,
      type,
      data,
    });
  }

  return (
    <>
      <Title>Question Details</Title>
      <Grid container spacing={2}>
        {questionDetail.isLoading &&
          !questionDetail.data &&
          modalQuestion.type === "edit" && <CircularProgress />}
        {((!questionDetail.isLoading &&
          questionDetail.data &&
          Object.keys(questionDetail.data).length) ||
          modalQuestion.type === "create") && (
          <Grid item xs={12}>
            <Formik
              initialValues={{
                dataQuestion: formQuestion,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                const newData = {
                  ...values.dataQuestion,
                  options: null,
                };
                if (modalType === "draft") {
                  handleModal("draft", newData);
                } else {
                  handleQuestionNextBack(question.questionStep + 1);
                }
                setIsDataChanged(true);
                setQuestionData(dispatch, newData);
              }}
            >
              {({ values, setFieldValue }) => (
                <Form style={{ width: "100%" }}>
                  <Grid container direction="column">
                    <FormikControl
                      control="input"
                      label={[
                        "Question",
                        `(${values.dataQuestion?.title?.length || 0}/45)`,
                      ]}
                      id="question"
                      name="dataQuestion.title"
                      placeholder="Type question"
                      className="basic-input"
                      inputProps={{ maxLength: 45 }}
                    />
                  </Grid>
                  <Typography
                    variant="subtitle1"
                    mb={1.5}
                    mt={1.5}
                    sx={{ opacity: 0.6 }}
                  >
                    Show Feedback
                  </Typography>
                  <label
                    className="switch"
                    onChange={(e) =>
                      handleChange(e, setFieldValue, values.dataQuestion)
                    }
                  >
                    <Field type="checkbox" name="dataQuestion.show_feedback" />
                    <span className="slider round"></span>
                  </label>
                  {values.dataQuestion.show_feedback && (
                    <Grid container direction="column" mt={2}>
                      <FormikControl
                        control="input"
                        label={[
                          "Answer Feedback",
                          `(${
                            values.dataQuestion?.free_text_feedback?.length || 0
                          }/340)`,
                        ]}
                        id="free_text_feedback"
                        name="dataQuestion.free_text_feedback"
                        placeholder="Type answer feedback"
                        className="basic-input"
                        inputProps={{ maxLength: 340 }}
                      />
                    </Grid>
                  )}

                  <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
                    <CustomButton
                      className={"custom_button white"}
                      style={{ width: 100 }}
                      disabled={modalQuestion.type === "edit"}
                      onHandleClick={() =>
                        modalQuestion.type === "edit" ? {} : handleQuestionNextBack(question.questionStep - 1)
                      }
                    >
                      <span>Back</span>
                    </CustomButton>
                    <CustomButton
                      className={"custom_button white"}
                      style={{ width: 133 }}
                      type="submit"
                      onHandleClick={() => setModalType("draft")}
                    >
                      <span>Save to Drafts</span>
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      className={"custom_button black"}
                    >
                      <span>Continue</span>
                    </CustomButton>
                  </Stack>
                  {modalQuestion.type === "edit" &&
                    !questionDetail.isLoading &&
                    !isDataChanged &&
                    questionDetail.data &&
                    Object.keys(questionDetail.data).length && (
                      <AutoFillValue questionDetail={questionDetail} />
                    )}
                </Form>
              )}
            </Formik>
          </Grid>
        )}
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    questionDetail: state.questionBank.questionDetail,
    formQuestion: state.questionBank.questionData,
    question: state.questionBank.checkQuestion,
    modalConfirmation: state.modal.modalConfirmation,
    modalQuestion: state.modal.modalQuestion,
  };
};
export default connect(mapStatetoProps, null)(FreeTextDetails);
