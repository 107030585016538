import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// If you want to change the theme, please look at the variables in config.js.
// Anything that can't be achieved there should be updated below. Be aware that
// updating the below theme will affect all clients.
export const appTheme = createTheme({
  palette: {
    primary: {
      main: window.theme.colors.primary,
    },
    error: {
      main: window.theme.colors.error,
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: 120,
          margin: 2,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: window.theme.colors.background,
        },
      },
    },
  },
  typography: {
    allVariants: {
      fontFamily: `-apple-system, ${window.theme.fonts.fontFamilyName}, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    },
    fontFamily: `-apple-system, ${window.theme.fonts.fontFamilyName}, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    h1: {
      fontWeight: 500,
      fontSize: "30pt",
      lineHeight: "40pt",
      color: window.theme.colors.primary,
    },
    h2: {
      fontWeight: 400,
      fontSize: "30pt",
      lineHeight: "40pt",
    },
    h3: {
      fontWeight: 500,
      fontSize: "24pt",
      lineHeight: "36pt",
    },
    body1: {
      fontWeight: 400,
      fontSize: "12pt",
      lineHeight: "16pt",
    },
  },
});

const CustomThemeProvider = (props) => {
  const { children } = props;

  return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
