import { Navigate } from "react-router-dom";

import { Paper, Typography } from "@mui/material";
import { msalInstance } from "../lib/MsalAuth";

import { LogInOutButton } from "../components/ComponentIndex";

export default function Welcome() {
  if (msalInstance.getActiveAccount() || window.disableSso) {
    return <Navigate to="/campaigns" replace={true} />;
  }

  return (
    <Paper sx={{ p: 3, textAlign: "center" }}>
      <Typography variant="h3" color="primary">
        Welcome
      </Typography>
      <Typography variant="h6" color="black" sx={{ mt: 1, mb: 2 }}>
        Please login to continue
      </Typography>
      <LogInOutButton color={"primary"} variant={"contained"} />
    </Paper>
  );
}
