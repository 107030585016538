import React from "react";
import { Typography } from "@mui/material";

function RuleGroup({ ruleGroup }) {
  return (
    <>
      {ruleGroup.rules.map((rule, index) => (
        <React.Fragment key={`${index}-rulegroup`}>
          <Typography variant="body1" sx={{ margin: "5px 0px" }}>
            {rule.label ? rule.label : rule.ruleKey} {rule.operator} {rule.value}
          </Typography>
          {index < ruleGroup.rules.length - 1 && (
            <Typography sx={{ ml: 1, fontSize: "1rem", fontWeight: 600 }} variant="h6">
              {ruleGroup.andOrOption}
            </Typography>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

function EligibilityViewer({ data, ruleName }) {
  const shouldRenderAndOr = (groupIndex) =>
    groupIndex < data.ruleGroups.length - 1 ||
    (groupIndex === data.ruleGroups.length - 1 && data.primaryRuleGroup.rules.length > 0);

  return (
    <>
      {data.primaryRuleGroup.rules.length > 0 && <RuleGroup ruleName={ruleName} ruleGroup={data.primaryRuleGroup} />}
      {data.ruleGroups.map((group, index) => (
        <div key={`${index}-rulegroup-render`} style={{ margin: "10px 16px" }}>
          {shouldRenderAndOr(index) && (
            <Typography sx={{ my: 1, ml: "-8px", fontSize: "1rem", fontWeight: 600 }} variant="h6">
              {data.primaryRuleGroup.andOrOption}
            </Typography>
          )}
          <Typography variant="h7" className="bold">
            {data.ruleGroups.length === 1 ? "Group" : `Group ${index + 1}`}
          </Typography>
          <div>
            <RuleGroup ruleName={ruleName} ruleGroup={group} />
          </div>
        </div>
      ))}
    </>
  );
}

export default EligibilityViewer;
