import React from "react";
import {
  Paper,
  Grid,
  Button,
  Stack,
  Autocomplete,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@mui/icons-material";
import { ComponentGuard } from "../ComponentIndex";
import { handleModalSurvey } from "../../store/actions/modal";
import { quesitonStatusFilter } from "../../constants/consts";

const styleButton = {
  textTransform: "none",
  width: 180,
  fontSize: 15,
  fontWeight: 600,
  margin: 0,
  height: 45,
  borderRadius: 2,
  backgroundColor: "#0066FF",
  "&:hover": {
    backgroundColor: "#0066FF",
  },
};

function HeaderSurveyList({
  dispatch,
  searchSurveyName,
  valueFilter,
  setValueFilter,
  setIsFiltered
}) {
  return (
    <>
      <Paper sx={{ p: 3, borderRadius: 4 }}>
        <Stack sx={{ position: "relative" }}>
          <Stack direction="row" spacing={2}>
            <>
              <Grid item xs={5}>
                <TextField
                  data-testid={`search-survey`}
                  id="search-input"
                  label="Search Survey Name"
                  variant="outlined"
                  size="small"
                  type="search"
                  onChange={(e) => searchSurveyName(e.target.value)}
                  InputProps={{
                    sx: { fontSize: 20 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchOutlined />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "100%", margin: "0" }}
                />
              </Grid>
              <Grid item xs={5}>
                <Autocomplete
                  data-testid={`status-filter`}
                  id="status-filter"
                  value={valueFilter.inputValue}
                  onChange={(event, newValue) => {
                    setValueFilter((prev) => ({
                      ...prev,
                      status: newValue,
                    }));
                    setIsFiltered(true)
                  }}
                  options={quesitonStatusFilter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filter by Status"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        sx: { fontSize: 20 },
                      }}
                      sx={{ width: "100%", margin: "0" }}
                    />
                  )}
                />
              </Grid>
              <ComponentGuard allowedRoles={window.appRoles.createSurvey}>
                <Grid item xs={2}>
                  <Link
                    onClick={() => {
                      handleModalSurvey(dispatch, {
                        isOpen: true,
                        type: "create",
                        data: null,
                      });
                    }}
                  >
                    <Button
                      data-testid={`survey-list`}
                      id="survey-list"
                      variant="contained"
                      size="small"
                      sx={styleButton}
                    >
                      <span>Create New Survey</span>
                    </Button>
                  </Link>
                </Grid>
              </ComponentGuard>
            </>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
}

export default HeaderSurveyList;
