import {useContext, useRef, useState} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

import {loginRedirect, msalInstance} from "./lib/MsalAuth";
import {protectedResources} from "./config/AuthConfig";

import EditModeContext from "./contexts/EditModeContext";

import {
  Archive,
  Campaign,
  CampaignTemplates,
  Dashboard,
  ErrorPage,
  NewCampaign,
  NotFoundPage,
  Root,
  Settings,
  QuestionBank,
  Survey,
  Welcome
} from "./routes/RouteIndex";
import authService from "./config/AuthService";
import {AuthContext, AuthProvider} from "react-oauth2-code-pkce";
import AxiosInterceptor from "./lib/AxiosInterceptor";
import {ComponentGuard} from "./components/ComponentIndex";
import axios from "axios";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Root/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "",
        element: <Welcome/>,
      },
      {
        path: "campaigns",
        element: <Dashboard/>,
      },
      {
        path: "question-bank",
        element: <QuestionBank />,
      },
      {
        path: "survey",
        element: <Survey />,
      },
      {
        path: "campaigns/archive",
        element: <Archive/>,
      },
      {
        path: "campaigns/archive/:id",
        element: <Campaign/>,
        loader: async ({ params }) => {
          const response = await axios.get(window.promotion_url + "/campaigns/" + params.id);
          return response.data;
        },
      },
      {
        path: "campaigns/templates",
        element: <CampaignTemplates/>
      },
      {
        path: "campaigns/templates/:id",
        element: <Campaign isTemplate="true"/>
      },
      {
        path: "campaigns/templates/new",
        element: <NewCampaign isTemplate="true"/>
      },
      {
        path: "campaigns/templates/edit/:id",
        element: <NewCampaign isTemplate="true"/>
      },
      {
        path: "campaigns/new",
        element: <NewCampaign/>,
      },
      {
        path: "campaigns/edit/:campaignId",
        element: <NewCampaign/>,
      },
      {
        path: "campaigns/:id",
        element: <Campaign/>
      },
      {
        path: "settings",
        element: <ComponentGuard allowedRoles={window.appRoles.admin}><Settings/></ComponentGuard>,
      },
      {
        path: "*",
        element: <NotFoundPage />
      }
    ],
  },
]);

function App() {
  let loading = useRef(true);
  const [editMode, setEditMode] = useState(false);

  // security loading took too long and the page was already rendered
  // (useRef doesn't trigger a re-render)
  let [securityLoading, setSecurityLoading] = useState(true);

  // This is done here so that pages don't try to load and send requests using
  // the credentials from the active account before the redirect promise is handled
  if (!window.authorizationFlow) {
    msalInstance
    .handleRedirectPromise()
    .then(() => {
      const account = msalInstance.getActiveAccount();
      if (account || !window.location.pathname.startsWith(
          protectedResources.campaignApi.path)) {
        if (securityLoading) {
          setSecurityLoading(false);
        } else {
          console.log("Skipping re-rendering for security loading");
        }
      } else {
        loginRedirect();
      }

      console.log("security loading: " + securityLoading);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  return window.authorizationFlow ? (
      <AuthProvider authConfig={authService}>
        <AuthLoadingWrapper
            securityLoading={securityLoading}
            loadingRef={loading.current}
        >
          <AxiosInterceptor/>
          <LocalizationProvider className="App" dateAdapter={AdapterDateFns}>
            <EditModeContext.Provider value={{editMode, setEditMode}}>
              <RouterProvider router={router}/>
            </EditModeContext.Provider>
          </LocalizationProvider>
        </AuthLoadingWrapper>
      </AuthProvider>
  ) : (
      <AuthLoadingWrapper
          securityLoading={securityLoading}
          loadingRef={loading.current}
      >
        <AxiosInterceptor/>
        <LocalizationProvider className="App" dateAdapter={AdapterDateFns}>
          <EditModeContext.Provider value={{editMode, setEditMode}}>
            <RouterProvider router={router}/>
          </EditModeContext.Provider>
        </LocalizationProvider>
      </AuthLoadingWrapper>
  );
}

const AuthLoadingWrapper = ({ children, securityLoading, loadingRef }) => {
  const { loginInProgress } = useContext(AuthContext);

  console.log("Page rendering. " + securityLoading + " " + loadingRef);
  if ((loginInProgress && window.authorizationFlow) || (securityLoading && loadingRef && !window.authorizationFlow)) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
};

export default App;
