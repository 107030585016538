import { useRouteError, Link } from "react-router-dom";
import { Typography, Grid, Paper, Container, Button } from "@mui/material";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div id="error-page">
              <Typography variant="h1">Oops!</Typography>
              <p>Sorry, an unexpected error has occurred.</p>
              <p>
                <i>{error.statusText || error.message}</i>
              </p>
            </div>
            <Button
              size="large"
              variant="contained"
              edge="start"
              aria-label="menu"
              component={Link}
              to="/"
              sx={{
                maxWidth: "200px",
              }}
            >
              Head Back Home
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
