import React from "react";
import ReactDOM from "react-dom/client";
import importGlobalStyles from "./theme/GlobalStyles";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CustomThemeProvider from "./theme/CustomThemeProvider";
import "./theme/Index.css";
import { Provider } from "react-redux";
import store from "./store";

// set favicon and prevent caching
const link = document.getElementById("favicon");
link.id = "favicon";
link.type = "image/x-icon";
link.rel = "icon";
link.href = window.theme.faviconLink + "?=" + Math.random();
document.head.appendChild(link);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // StrictMode has changed to Fragment, because there is an issue when using react-draft-wysiwyg
  <React.Fragment>
    <Provider store={store}>
      {importGlobalStyles}
      <CustomThemeProvider>
        <App />
      </CustomThemeProvider>
    </Provider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
