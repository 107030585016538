import { KeyboardArrowRight } from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

function ComponentCardStep({
  title,
  children,
  step,
  index,
  styleCard,
  styleCardAction,
  onHandleClick,
}) {
  return (
    <Card style={styleCard}>
      <CardActionArea style={styleCardAction} onClick={onHandleClick}>
        <CardContent>
          <div className="box_card_step">
            <Typography
              gutterBottom
              variant="subtitle1"
              fontWeight={700}
              sx={{ m: 0, p: 0 }}
            >
              {title}
            </Typography>
            <Stack
              sx={{
                m: 0,
                p: 0,
                color: step === index ? "#FFF" : "",
              }}
            >
              <KeyboardArrowRight />
            </Stack>
          </div>

          <Typography variant="body2" mt={1}>
            {children}
          </Typography>
          <Chip
            label={step > index ? "Completed" : "Draft"}
            className={`custom_chip_label ${step > index ? "active" : "inactive"}`}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ComponentCardStep;
