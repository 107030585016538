import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { BannerImage3, ImagePreview } from "../../../../../assets";
import { CustomButton, PreviewImage, Title } from "../../../../ComponentIndex";
import { connect } from "react-redux";
import { handleModalConfirmation } from "../../../../../store/actions/modal";

const styleCard = {
  backgroundImage: `url(${ImagePreview})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  borderRadius: 10,
  overflow: "auto",
  height: "550px",
};

function InformationPreview({
  dispatch,
  question,
  formQuestion,
  handleQuestion,
  modalQuestion,
}) {
  function handleModal(type) {
    let data = formQuestion;
    if (modalQuestion.type === "edit") {
      data = {
        id: modalQuestion.data.id,
        body: data,
      };
    }
    handleModalConfirmation(dispatch, {
      isOpen: true,
      type,
      data,
    });
  }
  function handleQuestionNextBack(nextOrBack) {
    handleQuestion(dispatch, {
      questionType: question.questionType,
      questionStep: nextOrBack,
    });
  }

  return (
    <>
      <Title> Information Preview </Title>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Card style={styleCard}>
            <CardContent>
              <Stack gap={1}>
                <div>
                  <PreviewImage
                    dataImage={formQuestion.banner_image}
                    defaultImage={BannerImage3}
                    alt={"BannerImagePreview"}
                    styles={{ width: "100%" }}
                  />
                </div>
                <Typography
                  variant="h4"
                  fontWeight={600}
                  color={"#FFF"}
                  sx={{ wordBreak: "break-all", mb: 3, mt: 2 }}
                >
                  {formQuestion.title}
                </Typography>
                <Grid container spacing={2} justifyContent={"center"}>
                  <Grid item xs={12}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formQuestion.information,
                      }}
                      style={{ color: "#FFF" }}
                    ></p>
                  </Grid>
                </Grid>
              </Stack>
            </CardContent>
          </Card>

          <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
            <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
              <CustomButton
                className={"custom_button white"}
                style={{ minWidth: 100 }}
                onHandleClick={() =>
                  handleQuestionNextBack(question.questionStep - 1)
                }
              >
                <span>Back</span>
              </CustomButton>
              <CustomButton
                onHandleClick={() => handleModal("draft")}
                className={"custom_button white"}
                style={{ minWidth: 133 }}
              >
                <span>Save to Drafts</span>
              </CustomButton>
              <CustomButton
                className={"custom_button black"}
                onHandleClick={() => handleModal("submit")}
                style={{ minWidth: 100 }}
              >
                <span>Submit</span>
              </CustomButton>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    formQuestion: state.questionBank.questionData,
    question: state.questionBank.checkQuestion,
    modalQuestion: state.modal.modalQuestion,
  };
};
export default connect(mapStatetoProps, null)(InformationPreview);
