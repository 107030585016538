import { Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";
import { BannerImage1, ImagePreview } from "../../../assets";

const styleCard = {
  backgroundImage: `url(${ImagePreview})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  borderRadius: 10,
  minHeight: 350,
  maxHeight: 350,
  overflow: "auto",
  scrollbarWidth: "none",
};

function RightContent({ title, image, children }) {
  return (
    <Card style={styleCard}>
      <CardContent sx={{ color: "#FFF" }}>
        <Stack gap={1}>
          <div>
            <img
              src={image || BannerImage1}
              alt="BannerImage1"
              width={"100%"}
            />
          </div>
          <Typography variant="body2" textAlign={"center"}>
            {title}
          </Typography>
          {children}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default RightContent;
