import { publishErrorMessage } from "../../events/events";
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
  apiPutWithHeaders,
} from "../../utilities/api";
import { API_CMS } from "../../utilities/api-url";

export const getListSurvey = async (dispatch, data) => {
  dispatch({
    type: "SET_SURVEY_LIST",
    payload: {
      isLoading: true,
      data: false,
      error: null,
    },
  });
  try {
    const response = await apiGet(API_CMS.SURVEY_LIST, data);
    dispatch({
      type: "SET_SURVEY_LIST",
      payload: {
        isLoading: false,
        data: response.data,
        error: null,
      },
    });
    return response.data;
  } catch (error) {
    publishErrorMessage(error?.response?.data?.title);
    dispatch({
      type: "SET_SURVEY_LIST",
      payload: {
        isLoading: false,
        data: false,
        error: error || "Error get list survey",
      },
    });
  }
};
export const deleteSurvey = async (data) => {
  try {
    const response = await apiDelete(API_CMS.SURVEY_DELETE(data));
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const createSurvey = async (data) => {
  try {
    const response = await apiPost(API_CMS.SURVEY_CREATE, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const detailSurvey = async (dispatch, data) => {
  dispatch({
    type: "SET_SURVEY_DETAIL",
    payload: {
      isLoading: true,
      data: null,
      error: null,
    },
  });
  try {
    const response = await apiGet(API_CMS.SURVEY_DETAIL(data));
    dispatch({
      type: "SET_SURVEY_DETAIL",
      payload: {
        isLoading: false,
        data: response.data,
        error: null,
      },
    });
  } catch (error) {
    publishErrorMessage(error?.response?.data?.title);
    dispatch({
      type: "SET_SURVEY_DETAIL",
      payload: {
        isLoading: false,
        data: false,
        error: error || "Error get detail survey",
      },
    });
  }
};
export const submitApprovalRejectSurvey = async (data) => {
  try {
    const response = await apiPost(
      API_CMS.SURVEY_SUBMIT_APPROVAL(data.id),
      data.body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const surveyUploadImage = async (data) => {
  const fileName = `?file_name=${data.param}`
  try {
    const response = await apiPutWithHeaders(API_CMS.SURVEY_UPLOAD_IMAGE(fileName),data.body);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const resetSurveyData = async (dispatch, data) => {
  dispatch({
    type: "SET_SURVEY_DATA",
    payload: {
      name: "",
      image: "",
      questions: [
        {
          question_id: "",
          title: "",
          question_type: "",
        },
      ],
      is_submitted: false,
    },
  });
};
export const setSurveyData = async (dispatch, data) => {
  dispatch({
    type: "SET_SURVEY_DATA",
    payload: {
      name: data.name,
      image: data.image,
      questions: data.questions,
      is_submitted: data.is_submitted,
    },
  });
};
export const editSurvey = async (data) => {
  try {
    const response = await apiPut(
      API_CMS.SURVEY_EDIT(data.id),
      data.body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};