import {EventType, PublicClientApplication} from "@azure/msal-browser";
import {msalConfig, protectedResources} from "../config/AuthConfig.js";
import {publishErrorMessage} from "../events/events";

export const msalInstance = new PublicClientApplication(msalConfig);
if (window.disableSso || window.authorizationFlow) {
  msalInstance.loginRedirect = function (loginRequest) {

  };
  msalInstance.logoutRedirect = function () {

  };
  msalInstance.account = {
    name: 'No SSO login'
  };
  msalInstance.getActiveAccount = function () {
    return this.account;
  };
  msalInstance.acquireTokenSilent = function () {
    return "disabled";
  };
}

msalInstance.getOrInitActiveAccount = () => {
  let account = msalInstance.getActiveAccount();
  if (account) {
    return account;
  }

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 1) {
    account = accounts[0];
    if (account?.tenantId === msalConfig.auth.tenantId) {
      msalInstance.setActiveAccount(account);
    }
  } else if (accounts.length > 1) {
    accounts.forEach((account) => {
      if (account?.tenantId === msalConfig.auth.tenantId) {
        msalInstance.setActiveAccount(account);
      }
    });
  }

  return account;
};

export const loginRedirect = () => {
  if (window.authorizationFlow) {
    return;
  }
  try {
    const loginRequest = {
      scopes: protectedResources.campaignApi.scopes,
      redirectUri: window.location.origin,
    };
    msalInstance.loginRedirect(loginRequest);
  } catch (err) {
    console.log(err);
    publishErrorMessage("Unexpected error during login: ");
  }
};

export const logoutRedirect = () => {
  if (window.authorizationFlow) {
    return;
  }
  if (!msalInstance.getActiveAccount()) {
    console.error("Cannot log out without active account");
    return;
  }

  try {
    msalInstance.logoutRedirect({ account: msalInstance.getActiveAccount() });
  } catch (err) {
    console.log(err);
  }
};

msalInstance.addEventCallback((event) => {
  if (window.authorizationFlow) {
    return;
  }

  if (
    (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.LOGIN_SUCCESS) &&
    event.payload
  ) {
    const payload = event.payload;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    if (
      event.error?.name === "InteractionRequiredAuthError" &&
      window.location.pathname.startsWith(protectedResources.campaignApi.path)
    ) {
      loginRedirect();
    } else {
      console.log("ACQUIRE_TOKEN_FAILURE");
      publishErrorMessage("Failed to acquire token. Please try again or contact an administrator.");
    }
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    if (
      event.error?.name === "BrowserAuthError" &&
      window.location.pathname.startsWith(protectedResources.campaignApi.path)
    ) {
      loginRedirect();
    } else {
      console.log("LOGIN FAILURE");
      publishErrorMessage("Failed to log in. Please try again or contact an administrator.");
    }
  } else {
    console.log("Callback finished");
  }
});
