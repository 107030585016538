import { Box, Stack } from "@mui/material";
import React from "react";
import { ImagePopUp } from "../../../assets";
import { CustomButton, CustomModal } from "../../ComponentIndex";
import { handleModalConfirmation } from "../../../store/actions/modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  p: 4,
  borderRadius: 20,
};

function ModalConfirmation({
  dispatch,
  children,
  modalConfirmation,
  onHandleSubmit,
  isLoading = false,
  setIsloading = () => {},
}) {
  const handleClose = () => {
    handleModalConfirmation(dispatch, {
      isOpen: false,
      type: "",
      data: null
    });
    setIsloading(false)
  }
    
  const handleSubmit = () => {
    onHandleSubmit(modalConfirmation);
  };

  return (
    <CustomModal isOpen={modalConfirmation.isOpen} handleClose={handleClose}>
      <Box style={style}>
        <Stack
          color={"#FFF"}
          p={4}
          style={{
            border: "none",
            backgroundImage: `url(${ImagePopUp})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          }}
        >
          {children}
        </Stack>
        <Box
          style={{
            backgroundColor: "#FFF",
            borderBottomRightRadius: 16,
            borderBottomLeftRadius: 16,
          }}
        >
          <Stack
            p={3}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            <CustomButton
              onHandleClick={handleClose}
              className={"custom_button white"}
              style={{ width: 100 }}
              disabled={isLoading}
            >
              <span>{modalConfirmation.type === "change-question" ? 'No' : 'Cancel'}</span>
            </CustomButton>
            <CustomButton
              className={"custom_button black"}
              style={{ width: 133 }}
              disabled={isLoading}
              onHandleClick={handleSubmit}
            >
              {modalConfirmation.type === 'delete' && <span>Delete</span>}
              {modalConfirmation.type === "submit" && <span>Submit</span>}
              {modalConfirmation.type === "draft" && <span>Save Draft</span>}
              {modalConfirmation.type === "approve" && <span>Approve</span>}
              {modalConfirmation.type === "change-question" && <span>Yes</span>}
            </CustomButton>
          </Stack>
        </Box>
      </Box>
    </CustomModal>
  );
}

export default ModalConfirmation;
