import { combineReducers } from "redux";
import questionBank from "./questionBank";
import modal from "./modal";
import survey from "./survey"

export default combineReducers({
  questionBank,
  modal,
  survey
});
