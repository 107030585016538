import {useState} from "react";
import axios from "axios";
import {campaignStatusMap} from "../constants/consts";
import {publish, publishErrorMessage} from "../events/events";
import useAccountName from "../lib/useAccountName";

const useDeleteCampaign = (type) => {
  const [selectedCampaign, setSelectedCampaign] = useState(null),
    [deleteDialogOpen, setDeleteDialogOpen] = useState(false),
    [deleteDialogTitle, setDeleteDialogTitle] = useState(""),
    [deleteDialogDetail, setDeleteDialogDetail] = useState(""),
    [deleteReason, setDeleteReason] = useState(""),
    [needsReason, setNeedsReason] = useState(false);

  const accountName = useAccountName();

  const handleDeleteReason = (value) => {
    setDeleteReason(value);
  };

  const showDeleteDialog = (row) => {
    setDeleteReason("");
    setSelectedCampaign(row);
    setNeedsReason(
      row.status === campaignStatusMap.APPROVED ||
        row.status === campaignStatusMap.ACTIVE ||
        row.status === campaignStatusMap.EXPIRED
    );

    setDeleteDialogTitle(`Are you sure you want to delete "${row.name || row.templateName}"?`);
    setDeleteDialogDetail(
      `Check whether ID: ${row.id} and Name: ${row.name || row.templateName} are deleted. This operation is irreversible.`
    );
    setDeleteDialogOpen(true);
  };

  const handleClose = () => {
    setDeleteDialogOpen(false);
  };

  const deleteCampaign = (row) => {
    let url;
    if (needsReason) {
      url = `${window.promotion_url + type}/delete/${selectedCampaign.id}?reason=${deleteReason}`;
    } else {
      url = `${window.promotion_url + type}/${selectedCampaign.id}`;
    }

    let initiator = accountName ? accountName : "Unknown";

    axios
      .delete(url, { params: { initiator: initiator } })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          publish("showNotification", {
            notification: "Successfully deleted",
            severity: "success",
          });
          selectedCampaign.status = campaignStatusMap.DELETED;
          window.location.reload();
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        publishErrorMessage(err.response ? err.response.data.detail : "Campaign successfully deleted");
      });

    setDeleteDialogOpen(false);
  };

  return {
    deleteDialogOpen,
    deleteDialogTitle,
    deleteDialogDetail,
    showDeleteDialog,
    handleClose,
    deleteCampaign,
    handleDeleteReason,
    deleteReason,
    needsReason,
  };
};

export default useDeleteCampaign;
