import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({
  type = "button",
  variant = "contained",
  children,
  style,
  className,
  onHandleClick = () => ({}),
  ...props
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      onClick={onHandleClick}
      style={style}
      className={className}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
