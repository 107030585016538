import Input from "./Input";
import InputWithIcon from "./InputWithIcon";
const FormikControl = ({ control, ...rest }) => {
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "inputWithIcon":
      return <InputWithIcon {...rest} />;
    // other cases...
    default:
      return null;
  }
};

export default FormikControl;
