import React from "react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { NotFoundImage } from "../assets";

function NotfoundPage() {
  return (
    <Grid
      container
      spacing={2}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ height: "90vh" }}
    >
      <Card
        sx={{
          padding: 2,
          width: 1020,
          height: 365,
          borderRadius: 4,
          backgroundColor: "#F2F4F5",
        }}
      >
        <Grid container>
          <Grid item xs={5}>
            <img
              src={NotFoundImage}
              alt="NotFoundImage"
              width={327}
              height={327}
            />
          </Grid>
          <Grid item xs={7}>
            <Stack
              display={"flex"}
              justifyContent={"space-evenly"}
              height={"100%"}
            >
              <Box>
                <Typography
                  variant="h6"
                  fontWeight={700}
                  fontSize={30}
                  color={"#323232"}
                  marginBottom={2}
                >
                  Sorry, Page Not Found
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  fontWeight={400}
                  color={"#808191"}
                  lineHeight={"normal"}
                >
                  The page you requested could not be found because it's not
                  exist, might have been removed, had it name changed, or
                  another error occurred.
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

export default NotfoundPage;
