import { useState, useEffect } from "react";
import axios from "axios";

const fetchImageFile = async (name, imageUrl) => {
  try {
    const response = await axios.get(window.promotion_url + "/campaigns/images/" + imageUrl, {
      responseType: "blob",
    });
    if (response.status !== 200) {
      throw new Error(`Error fetching image ${name}: ${response.statusText}`);
    }
    const blob = response.data;
    return { [name]: new File([blob], imageUrl.split("/").pop(), { type: blob.type }) };
  } catch (error) {
    console.error(`Error fetching image ${name}:`, error);
  }
};

const useImagePreview = ((campaign) => {
  const [images, setImages] = useState(undefined);

  useEffect(() => {
    if (!!campaign) {
      const imageObject = [
        { name: "HERO", value: campaign.heroImageName },
        { name: "BRAND", value: campaign.brandImageName },
        { name: "DYNAMIC", value: campaign.dynamicImageName },
        { name: "LISTVIEW", value: campaign.listViewImageName },
      ];

      const fetchImages = async () => {
        let imagesData = {};

        for (const image of imageObject) {
          try {
            if (image.value === null) {
              console.log("Not found:", image.name);
              continue;
            }
            const imageData = await fetchImageFile(image.name, image.value);
            imagesData = { ...imagesData, ...imageData };
          } catch (error) {
            console.error("Error fetching images:", error);
          }
        }
        setImages(imagesData);
      };

      fetchImages();
    }
  }, [campaign]);

  return images;
});

export default useImagePreview;
