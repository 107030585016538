import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { BannerImage1, ImagePreview } from "../../../../../assets";
import { CustomButton, PreviewImage, Title } from "../../../../ComponentIndex";
import { connect } from "react-redux";
import { handleModalConfirmation } from "../../../../../store/actions/modal";
import { fetchImage } from "../../../../../utilities/utils";

const styleOption = (others) => {
  return {
    backgroundColor: "#FFF",
    color: "#000",
    display: "flex",
    fontSize: 12,
    justifyContent: "start",
    width: "100%",
    ...others,
  };
};

const styleCard = {
  backgroundImage: `url(${ImagePreview})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  borderRadius: 10,
  overflow: "auto",
  height: "550px",
};

function MultiSelectPreview({
  dispatch,
  question,
  formQuestion,
  handleQuestion,
  modalQuestion,
}) {

  const [newImages, setNewImages] = React.useState([])

  React.useEffect(() => {
    formQuestion?.options?.forEach((item) => {
      convertImage(item.image);
    });
  }, [formQuestion?.options])

  function handleModal(type) {
    let data = formQuestion;
    if (modalQuestion.type === "edit") {
      data = {
        id: modalQuestion.data.id,
        body: data,
      };
    }
    handleModalConfirmation(dispatch, {
      isOpen: true,
      type,
      data,
    });
  }
  function handleQuestionNextBack(nextOrBack) {
    handleQuestion(dispatch, {
      questionType: question.questionType,
      questionStep: nextOrBack,
    });
  }
  function showImage(path) {
    const findData = newImages.find(item => item[path] )
    if (findData !== undefined && Object.keys(findData).length > 0) {
      return findData[path]
    }
    return ""
  }
  async function convertImage(imageURL) {
    try {
      const result = await fetchImage(imageURL, "list");
      if (result) {
        setNewImages(prev => [...prev, result]);
      } 
    } catch (error) {
      console.error(`Error fetching image`, error);
    }
  }

  return (
    <>
      <Title> Question Preview </Title>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Card style={styleCard}>
            <CardContent sx={{ color: "#FFF" }}>
              <Stack gap={1}>
                <div>
                  <PreviewImage
                    dataImage={formQuestion.banner_image}
                    defaultImage={BannerImage1}
                    alt={"BannerImagePreview"}
                    styles={{ width: "100%", height: 100 }}
                  />
                </div>
                <Typography variant="h7" textAlign={"center"} mt={2} mb={2}>
                  {formQuestion.title}
                </Typography>
                <Stack spacing={3} mt={3}>
                  {formQuestion?.options?.map((item, i) => (
                    <div key={i}>
                      {item.image ? (
                        <Button
                          variant="contained"
                          sx={styleOption({
                            borderRadius: 2,
                            paddingLeft: 1,
                            textTransform: "none",
                          })}
                          startIcon={
                            item.image && (
                              <Avatar
                                src={
                                  (typeof item.image === "object" ?
                                    item.image &&
                                    URL.createObjectURL(item.image) : showImage(item.image))
                                }
                                variant="square"
                              />
                            )
                          }
                        >
                          {item.answer}
                        </Button>
                      ) : (
                        <Chip
                          key={i}
                          variant="contained"
                          label={item.answer}
                          sx={styleOption({
                            borderRadius: 4,
                            flexDirection: "row-reverse",
                            height: 40,
                          })}
                        />
                      )}
                    </div>
                  ))}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
          <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
            <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
              <CustomButton
                className={"custom_button white"}
                style={{ width: 100 }}
                onHandleClick={() =>
                  handleQuestionNextBack(question.questionStep - 1)
                }
              >
                <span>Back</span>
              </CustomButton>
              <CustomButton
                className={"custom_button white"}
                style={{ width: 133 }}
                onHandleClick={() => handleModal("draft")}
              >
                <span>Save to Drafts</span>
              </CustomButton>
              <CustomButton
                className={"custom_button black"}
                onHandleClick={() => handleModal("submit")}
                style={{ width: 100 }}
              >
                <span>Submit</span>
              </CustomButton>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    formQuestion: state.questionBank.questionData,
    question: state.questionBank.checkQuestion,
    modalQuestion: state.modal.modalQuestion,
  };
};
export default connect(mapStatetoProps, null)(MultiSelectPreview);
