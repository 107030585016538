import React from "react";
import { Outlet } from "react-router-dom";

import Container from "@mui/material/Container";

import { ButtonAppBar, NotificationBar } from "../components/ComponentIndex";
import ScrollToTop from "../utilities/ScrollToTop";

export default function Root() {
  return (
    <>
      <ButtonAppBar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <NotificationBar />
        <Outlet />
        <ScrollToTop />
      </Container>
    </>
  );
}
