import React from "react";
import { Stack, Typography } from "@mui/material";
import { CustomButton, FileUploadRequirement } from "../ComponentIndex";
import { ErrorMessage } from "formik";
import PreviewImage from "./ComponentPreviewImage";

const addtionalStyle = {
  backgroundColor: "#b1b1b1",
  cursor: "default",
};

function ComponentUploadImage(props) {
  const {
    dataImage,
    defaultImage,
    name,
    uploadImageRef,
    isLoading = false,
    isError = false,
    dimension,
    size,
    accept = "image/png,image/jpg,image/jpeg,image/gif",
    isDisabled,
    titleUpload = "Upload New",
    titleReset = "Reset to Default",
    onHandleChange = () => ({}),
    onHandleClick = () => ({}),
  } = props;
  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={3}>
        <div style={{ width: 80 }}>
          <PreviewImage
            dataImage={dataImage}
            defaultImage={defaultImage}
            alt={name}
            styles={{ width: "100%" }}
          />
        </div>
        <CustomButton
          component="label"
          role={undefined}
          tabIndex={-1}
          className={`custom_button ${!isLoading ? "black" : ""}`}
          style={isLoading ? addtionalStyle : {}}
        >
          <input
            accept={accept}
            name={name}
            ref={uploadImageRef}
            type="file"
            className="custom-file-input"
            disabled={isLoading}
            onChange={(e) => onHandleChange(e)}
          />
          <span>{titleUpload}</span>
        </CustomButton>
        <CustomButton
          variant="link"
          sx={{
            "&:hover": { backgroundColor: "#FFF" },
            textTransform: "none",
            color: !isDisabled || isLoading ? "gray" : "#0a7d35",
          }}
          onHandleClick={onHandleClick}
          disabled={!isDisabled || isLoading}
        >
          <Typography variant="body2" fontWeight={"bold"}>
            {titleReset}
          </Typography>
        </CustomButton>
      </Stack>
      {isError && (
        <>
          <FileUploadRequirement dimension={dimension} size={size} />
          <ErrorMessage
            name={name}
            render={(message) => {
              return <span style={{ color: "red" }}>{message}</span>;
            }}
          />
        </>
      )}
    </>
  );
}

export default ComponentUploadImage;
