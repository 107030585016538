import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { RuleGroup, ErrorContainer, RuleButton } from "../../ComponentIndex";

export default function RuleFormatter({
  showErrorOnPage = false,
  header = "",
  content = "",
  ruleKeys = [],
  ruleWrapper = { primaryRuleGroup: { andOrOption: "and", rules: [] }, ruleGroups: [] },
  onChange = () => {},
  ruleName = "",
}) {
  const addRuleGroup = (e) => {
    let rule = {
      ruleKey: "",
      value: "",
      label: "",
      operator: "IS",
    };

    let ruleGroup = { andOrOption: "and", rules: [rule] };
    ruleWrapper.ruleGroups = [...ruleWrapper.ruleGroups, ruleGroup];

    onChange(e, ruleWrapper);
  };

  const removeRuleGroup = (e, index) => {
    ruleWrapper.ruleGroups = ruleWrapper.ruleGroups.filter((group, groupIndex) => groupIndex !== index);

    onChange(e, ruleWrapper);
  };

  const onRuleChange = (e, rules) => {
    ruleWrapper.primaryRuleGroup = rules;

    onChange(e, ruleWrapper);
  };

  const onRuleGroupChange = (e, index, newValue) => {
    ruleWrapper.ruleGroups[index] = newValue;

    onChange(e, ruleWrapper);
  };

  const backgroundColours = {
    initial: "none",
    grey: `${window.theme.colors.secondary}`,
  };

  const primaryRuleGroupOperator = ruleWrapper.primaryRuleGroup.andOrOption;
  const totalRules = ruleWrapper.primaryRuleGroup.rules.length + ruleWrapper.ruleGroups.length;
  const primaryGroupStyle = totalRules > 1 ? { mt: "70px", position: "relative" } : {};

  const rulesExist = () => ruleWrapper?.primaryRuleGroup?.rules?.length > 0;

  const ruleGroupExists = () => ruleWrapper?.ruleGroups?.length > 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3>{header}</h3>
        <p>{content}</p>
      </Grid>
      <Paper
        sx={{
          p: 0,
          borderRadius: 0,
          boxShadow: "none",
          background: backgroundColours.initial,
          mb: rulesExist() ? 3 : 0,
          ml: 2,
          width: "100%",
          ...primaryGroupStyle,
        }}
      >
        <Grid container spacing={2}>
          <RuleGroup
            ruleName={ruleName}
            className="baseRules"
            ruleGroup={ruleWrapper.primaryRuleGroup}
            ruleKeys={ruleKeys}
            onChange={onRuleChange}
            showErrorOnPage={showErrorOnPage}
            rulesExist={rulesExist()}
            numberOfOtherRules={totalRules}
          />
        </Grid>
      </Paper>

      {ruleWrapper.ruleGroups.map((rules, index) => {
        return (
          <Grid container key={`${index}-ruleGroup`}>
            {ruleWrapper.primaryRuleGroup.rules.length === 0 && index === 0 ? null : (
              <Typography
                variant="h6"
                key={`${index}-operator`}
                sx={{ m: "4px 16px", textTransform: "uppercase", fontWeight: 600, fontSize: "1rem" }}
              >
                {primaryRuleGroupOperator}
              </Typography>
            )}

            <Paper
              sx={{
                p: 2,
                borderRadius: 3,
                boxShadow: "none",
                background: ruleGroupExists() ? backgroundColours.grey : backgroundColours.initial,
                width: "100%",
                mb: 2,
                mt: 2,
                ml: 2,
              }}
            >
              <Grid item xs={12} className={"rule-group-" + index}>
                <Grid container spacing={2}>
                  <RuleGroup
                    ruleName={ruleName}
                    showErrorOnPage={showErrorOnPage}
                    groupName={index}
                    ruleGroup={rules}
                    ruleKeys={ruleKeys}
                    isSubgroup={true}
                    onChange={(e, newRules) => {
                      if (!newRules) {
                        newRules = e.target;
                      }
                      onRuleGroupChange(e, index, newRules);
                    }}
                    removeGroup={(e) => {
                      removeRuleGroup(e, index);
                    }}
                  />
                </Grid>
              </Grid>
              {showErrorOnPage && !!rules.rules && rules.rules.length === 0 && (
                <ErrorContainer errorMessage="Please ensure that every group has a rule in it." />
              )}
            </Paper>
          </Grid>
        );
      })}

      <Grid item sx={rulesExist() ? { pt: "0 !important" } : null}>
        <RuleButton dataIdString={`add-group-${ruleName.toLowerCase()}-button`} click={addRuleGroup}>
          Add group of rules
        </RuleButton>
      </Grid>
    </Grid>
  );
}
