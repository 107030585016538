import React from "react";
import { Paper, Typography, Stack } from "@mui/material";
import { SurveyTable } from "../ComponentIndex";

function mappingStatus(params) {
  const status = {
    ACTIVE: "active",
    DRAFT: "draft",
    ARCHIVE: "archive",
  };
  return status[params];
}

export default function SurveyList({
  status,
  title,
  dispatch,
  surveyList,
  isLoading,
  isError,
  isFiltered,
  valueFilter,
  setLimitasi,
  limitasi,
  setDataSurvey,
  dataLength,
  setTotalData,
  totalData,
  isRendered,
  setIsrendered,
}) {
  return (
    <>
      <Paper sx={{ p: 3, borderRadius: 4 }}>
        <Stack sx={{ position: "relative", mb: 1.5 }}>
          <Stack spacing={2} direction={"row"} mb={2} alignItems={"center"}>
            <span className={`custom__label ${mappingStatus(status)}`}></span>
            <Typography variant="h6" color={"#323232"} fontWeight={700}>
              {title}
            </Typography>
          </Stack>
        </Stack>
        <SurveyTable
          dispatch={dispatch}
          surveyList={surveyList}
          isLoading={isLoading}
          isError={isError}
          isFiltered={isFiltered}
          valueFilter={valueFilter}
          setLimitasi={setLimitasi}
          limitasi={limitasi}
          setDataSurvey={setDataSurvey}
          scrollableTarget={status}
          dataLength={dataLength}
          setTotalData={setTotalData}
          totalData={totalData}
          isRendered={isRendered}
          setIsrendered={setIsrendered}
        />
      </Paper>
    </>
  );
}
