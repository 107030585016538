import { ErrorMessage } from "formik";
import PropTypes from "prop-types";

const TextError = ({ name, icondrag }) => {
  return <ErrorMessage name={name} render={msg => {
    return <span style={{color: 'red', marginLeft: icondrag ? 40 : 0}}>{msg}</span>
  }} />
};

TextError.propTypes = {
  children: PropTypes.node,
};

export default TextError;
