import {
  Box,
  CircularProgress,
  Fab,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { CustomButton } from "../ComponentIndex";
import "bootstrap/dist/css/bootstrap.min.css";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { handleModalConfirmation } from "../../store/actions/modal";
import { connect } from "react-redux";
import ContentPreview from "./ContentPreview";
import { apiGet } from "../../utilities/api";
import { API_CMS } from "../../utilities/api-url";

const directionButtons = (direction) => {
  return direction === "Next" ? (
    <Fab aria-label="arrow-right" size="small">
      <KeyboardArrowRight />
    </Fab>
  ) : (
    <Fab aria-label="arrow-left" size="small">
      <KeyboardArrowLeft />
    </Fab>
  );
};

const styleBox = {
  backgroundColor: "#D9D9D9",
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: 1,
  height: 57,
  borderRadius: 2,
  paddingLeft: 1.5,
  marginBottom: 2,
};

function SurveyPreview({
  dispatch,
  formSurvey,
  modalSurvey,
  setStepSurvey,
}) {
  const [index, setIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [questionDetail, setQuestionDetail] = React.useState({});
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  function handleModal(type) {
    let data = formSurvey;
    if (modalSurvey.type === "edit") {
      data = {
        id: modalSurvey.data.id,
        body: data,
      };
    }
    handleModalConfirmation(dispatch, {
      isOpen: true,
      type,
      data,
    });
  }

  React.useEffect(() => {
    getAPIQuestionDetail(formSurvey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  async function getAPIQuestionDetail(formSurvey) {
    setIsLoading(true);
    const { data } = await apiGet(
      API_CMS.QUESTION_BANK_DETAIL(formSurvey.questions[index].question_id)
    );
    setIsLoading(false);
    setQuestionDetail(data);
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={500} mb={1.5}>
            Survey Preview
          </Typography>
          <Box sx={styleBox}>
            <span>{formSurvey.name}</span>
          </Box>
        </Grid>
        <Grid item xs={6} ml={10} mt={3}>
          {isLoading && <CircularProgress />}
          {!isLoading && Object.keys(questionDetail).length && (
            <ContentPreview
              index={index}
              handleSelect={handleSelect}
              directionButtons={directionButtons}
              questionDetail={questionDetail}
              formSurvey={formSurvey}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
            <CustomButton
              className={"custom_button white"}
              style={{ width: 100 }}
              onHandleClick={() => setStepSurvey((prev) => prev - 1)}
            >
              <span>Back</span>
            </CustomButton>
            <CustomButton
              className={"custom_button white"}
              style={{ width: 133 }}
              onHandleClick={() => handleModal("draft")}
            >
              <span>Save to Drafts</span>
            </CustomButton>
            <CustomButton
              type="submit"
              className={"custom_button black"}
              style={{ width: 100 }}
              onHandleClick={() => handleModal("submit")}
            >
              <span>Submit</span>
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    formSurvey: state.survey.formSurvey,
    modalSurvey: state.modal.modalSurvey
  };
};
export default connect(mapStatetoProps, null)(SurveyPreview);
