import {React, useCallback, useEffect, useRef, useState} from "react";
import CsvDownload from "react-csv-downloader";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography
} from "@mui/material";

import {statusMapping} from "../../constants/consts";
import axios from "axios";

import {publish, publishErrorMessage} from "../../events/events";
import {dateToString, formatDate} from "../../utilities/utils";
import {
  getExclusionRuleRuleKey,
  getExclusionRuleValue
} from "./SummarySections/EligibilityCriteriaSection";
import useAccountName from "../../lib/useAccountName";

export const DownloadUsers = ({ formValues, setFormValues, showCalculate, preventSave }) => {
  const [customerData, setCustomerData] = useState([]),
    [totalEligible, setTotalEligible] = useState(0),
    [maxDistribution, setMaxDistribution] = useState(formValues.maxDistribution || 0),
    [disabledDownLink, setDisableShowDownLink] = useState(showCalculate),
    [downloadInProgress, setDownloadInProgress] = useState(false),
    [downloadProgress, setDownloadProgress] = useState(0.0),
    [countInProgress, setCountInProgress] = useState(false),
    csvInstance = useRef(null),
    CUSTOMER_ID = "CUSTOMER_ID";

  const accountName = useAccountName();

  const calculateUsers = useCallback(() => {
    setCountInProgress(true);
    publish("showWaitSpinner", { toggle: true });

    // Exclude properties where ruleKey and value are empty for primaryRuleGroup
    formValues.eligibilityCriteria.primaryRuleGroup.rules =
      formValues.eligibilityCriteria.primaryRuleGroup.rules.filter((rule) => {
        return rule.ruleKey !== "" && (rule.value !== "" || rule.operator === "NOT_HAVE");
      });

    // Exclude properties where ruleKey and value are empty for ruleGroups
    formValues.eligibilityCriteria.ruleGroups.forEach((ruleGroup, index) => {
      ruleGroup.rules = ruleGroup.rules.filter((rule) => {
        return rule.ruleKey !== "" && (rule.value !== "" || rule.operator === "NOT_HAVE");
      });
    });

    formValues.eligibilityCriteria.ruleGroups = formValues.eligibilityCriteria.ruleGroups.filter((ruleGroup) => {
      return ruleGroup.rules.length > 0;
    });

    const startDate = dateToString(formValues.startDate);

    const exclusionRules = !!formValues?.exclusionCriteria ? formValues.exclusionCriteria : formValues.exclusionRules;

    let requestBody = {
      eligibilityCriteria: formValues.eligibilityCriteria,
      exclusionRules: exclusionRules.map((rule) => ({
        id: rule.id,
        date: rule.date,
        value: getExclusionRuleValue(rule),
        ruleKey: getExclusionRuleRuleKey(rule),
      })),
      campaignStartDate: !!startDate ? startDate : formValues.startDate,
      maxDistribution: formValues.maxDistribution,
    };

    axios
      .post(window.promotion_url + "/customer-metadata/count", JSON.stringify(requestBody), {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      })
      .then((response) => {
        setTotalEligible(response.data);
        setMaxDistribution(formValues.maxDistribution);
        setDisableShowDownLink(false);
      })
      .catch((error) => {
        console.log(error);
      })
    .finally(() => {
      setCountInProgress(false);
      publish("showWaitSpinner", { toggle: false });
    });
  }, [setCountInProgress, formValues]);

  const saveDraft = async () => {
    try {
      const mappedExclusionCriteria = formValues.exclusionCriteria.map((criteria) => {
        return {
          ...criteria,
          ruleKey: statusMapping[criteria.ruleKey],
        };
      });

      const updatedFormValues = {
        ...formValues,
        exclusionCriteria: mappedExclusionCriteria,
        initiator: accountName,
      };
      updatedFormValues.couponOfferMessage = null;

      updatedFormValues.startDate = dateToString(updatedFormValues.startDate);
      updatedFormValues.endDate = dateToString(updatedFormValues.endDate);

      const url = `${window.promotion_url}/draft`;
      const response = await axios.post(url, updatedFormValues, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      return response.data.id;
    } catch (error) {
      console.error("Error saving draft campaign", error);
      return null;
    }
  };

  const downloadUsers = async () => {
    setDownloadInProgress(true);
    setDownloadProgress(0.0);

    //save campaign before requesting download, only if component is running download only without saving.
    const campaignId = preventSave ? formValues.id : await saveDraft();

    if (!campaignId) {
      setDownloadInProgress(false);
      publish("showNotification", {
        severity: "error",
        message: "Could not fetch user data. Please retry later",
      });
      return;
    }

    if (!preventSave) {
      setFormValues({
        ...formValues,
        campaignId: campaignId,
      });
    }

    const startDate = dateToString(formValues.startDate);

    const exclusionRules = !!formValues?.exclusionCriteria ? formValues.exclusionCriteria : formValues.exclusionRules;

    let requestBody = {
      campaignId: campaignId,
      campaignStatus: "QUERY",
      eligibilityCriteria: formValues.eligibilityCriteria,
      exclusionRules: exclusionRules.map((rule) => ({
        id: rule.id,
        date: rule.date,
        value: getExclusionRuleValue(rule),
        ruleKey: getExclusionRuleRuleKey(rule),
      })),
      campaignStartDate: startDate,
      maxDistribution: formValues.maxDistribution,
    };

    const fetchUsersInLoop = async (page = 0, allData = []) => {
      const response = await axios.post(
        `${window.promotion_url}/customer-metadata/download?size=${
          window.download_page_size ? window.download_page_size : 1000
        }&page=${page}`,
        JSON.stringify(requestBody),
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );

      const currentPage = response.data.customerPage;

      allData = allData.concat(currentPage.content);

      setDownloadProgress(((currentPage.number + 1) / currentPage.totalPages) * 100);

      if (currentPage && !currentPage.last) {
        // Fetch next page
        return await fetchUsersInLoop(page + 1, allData);
      }

      return allData;
    };

    try {
      const allCustomerData = await fetchUsersInLoop();

      const cleanedData = allCustomerData.map((item) => {
        const cleanedItem = {};
        for (const key in item) {
          if (Object.hasOwnProperty.call(item, key)) {
            cleanedItem[key] =
              typeof item[key] === "string" ? '"' + item[key].replace(/[\r\n]/gm, "") + '"' : '"' + item[key] + '"';
          }
        }
        return cleanedItem;
      });

      setCustomerData(cleanedData);
      setTotalEligible(cleanedData.length);
      setDownloadInProgress(false);
    } catch (error) {
      console.log(error);
      publishErrorMessage("Could not fetch user data. Please retry later");
      setDownloadInProgress(false);
    }
  };

  const generateHeaders = (data) => {
    if (data.length > 0) {
      const headers = Object.keys(data[0]).map((key) => ({
        displayName: key.toUpperCase(),
        id: key,
      }));

      const customerIdIndex = headers.findIndex((header) => header.displayName === CUSTOMER_ID);

      if (customerIdIndex > -1) {
        const customerIdHeader = headers.splice(customerIdIndex, 1)[0];
        headers.unshift(customerIdHeader);
      }
      return headers;
    }

    return [];
  };

  useEffect(() => {
    if (
      !!customerData &&
      customerData.length > 0 &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.handleClick
    ) {
      setTimeout(() => {
        csvInstance.current.handleClick();
        setCustomerData([]); // reset customer data after download
      });
    }
  }, [customerData]);

  // Updated RegEx to replace all illegal characters in Windows File Names with dashes '-' instead.
  const fileName = `${formatDate(formValues.startDate, "dd-MM-yyyy")}_${formValues.name.replaceAll(
    /[/\\?%*:|"<>]/g,
    "-"
  )}_eligible-customers`;

  useEffect(() => {
    if (!showCalculate) {
      calculateUsers();
    }
  }, [showCalculate, calculateUsers]);

  return (
    <>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <h3>Eligible Users</h3>
        {showCalculate && (
          <Button
            data-testid={`calculate-users-button`}
            sx={{ textTransform: "none" }}
            variant="contained"
            id="upload-button-id"
            disabled={
              (formValues.eligibilityCriteria.primaryRuleGroup.length === 0 &&
              formValues.eligibilityCriteria.ruleGroups.filter((group) => group.length !== 0).length === 0)
                || countInProgress
            }
            onClick={calculateUsers}
          >
            Calculate eligible users
          </Button>
        )}
      </Grid>
      <Grid item xs={12} sx={{ background: `${window.theme.colors.secondary}`, p: 2, borderRadius: 2, mb: 2, ml: 2 }}>
        <p
          style={{ whiteSpace: "pre-wrap", marginTop: 0 }}
        >{`${totalEligible} eligible user(s), limited to ${maxDistribution}\n(includes customers that won't get the campaign due to high balance)`}</p>
        <br />
        <CsvDownload
          datas={customerData}
          disabled={downloadInProgress || disabledDownLink}
          filename={fileName}
          columns={generateHeaders(customerData)}
          extension=".csv"
          handleEmpty={() => void 0}
          ref={csvInstance}
        >
          <Button
            data-testid={`download-users-button`}
            id="down-link-button-id"
            sx={{ textTransform: "none" }}
            variant="contained"
            disabled={downloadInProgress || disabledDownLink}
            onClick={downloadUsers}
          >
            Download eligible users
          </Button>
        </CsvDownload>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={downloadInProgress}>
          <CircularProgress
            color="inherit"
            variant={"determinate"}
            value={downloadProgress}
            sx={{ width: "80px !important;", height: "80px !important" }}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption" component="div" color={"#fff"} sx={{ fontSize: "1.2rem" }}>
              {`${Math.round(downloadProgress)}%`}
            </Typography>
          </Box>
        </Backdrop>
      </Grid>
    </>
  );
};

export default DownloadUsers;
