import React from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Field, Form, Formik, useFormikContext } from "formik";
import { CloseTwoTone } from "@mui/icons-material";
import { connect } from "react-redux";
import ContentPreview from "./ContentPreview";
import { BannerImage1 } from "../../../assets";
import {
  ComponentGuard,
  CustomButton,
  FormikControl,
  PreviewImage,
} from "../../ComponentIndex";
import {
  handleModalConfirmation,
  handleModalQuestion,
  handleModalRejection,
} from "../../../store/actions/modal";
import { handleQuestion } from "../../../store/actions/questionBank";
import { questionTypeReturnInt } from "../../../constants/consts";

const AutoFillValue = ({ questionDetail }) => {
  const { setFieldValue } = useFormikContext();

  React.useEffect(() => {
    setFieldValue(
      "question_category",
      questionDetail?.data.question_category || "-"
    );
    setFieldValue("approval_status", questionDetail?.data.status);
    setFieldValue("title", questionDetail?.data?.title);
    setFieldValue("banner_image", questionDetail?.data?.banner_image);
    setFieldValue(
      "free_text_feedback",
      questionDetail?.data?.free_text_feedback
    );
    setFieldValue("question_type", questionDetail?.data?.question_type);
    setFieldValue("information", questionDetail?.data?.information);
    setFieldValue("feedback", questionDetail?.data?.show_feedback || false);
    setFieldValue("options", questionDetail?.data?.options || []);
    setFieldValue(
      "rejection_reason",
      questionDetail?.data?.rejection_reason
        ? questionDetail?.data?.rejection_reason
        : "-"
    );
  }, [questionDetail, setFieldValue]);
  return null;
};

const styleBox = {
  backgroundColor: "#D9D9D9",
  borderRadius: 3,
  paddingTop: 2,
  paddingLeft: 3,
  paddingRight: 3,
  overflowY: "auto",
};

function ContentDetail({ dispatch, questionDetail }) {
  function handleEdit(row) {
    handleModalQuestion(dispatch, {
      isOpen: true,
      type: "edit",
      data: {
        id: row.id,
      },
    });
    // Set handle question type
    handleQuestion(dispatch, {
      questionType: questionTypeReturnInt[row.question_type],
      questionStep: 1,
    });
  }

  return (
    <Paper sx={{ padding: "30px", borderRadius: 4 }}>
      <Stack
        justifyContent={"space-between"}
        alignItems={"baseline"}
        direction={"row-reverse"}
      >
        <IconButton
          onClick={() => {
            handleModalQuestion(dispatch, {
              isOpen: false,
              type: "",
              data: null,
            });
          }}
        >
          <CloseTwoTone
            fontSize="medium"
            sx={{
              backgroundColor: "#D8D8D8",
              padding: 0.5,
              borderRadius: 10,
            }}
          />
        </IconButton>
        <Typography gutterBottom variant="h2" fontWeight={"bold"}>
          View Question
        </Typography>
      </Stack>
      <Paper
        elevation={4}
        sx={{ p: 4, borderRadius: 6, overflow: "auto", height: 600 }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" fontWeight={"bold"}>
              Question Details
            </Typography>
          </Grid>

          <Formik
            initialValues={{
              title: "",
              question_category: "",
              approval_status: "",
              banner_image: "",
              free_text_feedback: "",
              question_type: "",
              information: "",
              feedback: true,
              options: [],
              rejection_reason: "",
            }}
          >
            {({ values }) => (
              <Form style={{ marginTop: "1rem", width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {questionDetail.isLoading && !questionDetail.error && (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                      </Box>
                    )}
                  </Grid>
                  {!questionDetail.isLoading && !questionDetail.error && (
                    <>
                      <Grid item xs={6}>
                        <FormikControl
                          control="input"
                          label={"Question Category"}
                          id="question_category"
                          name="question_category"
                          className="basic-input"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikControl
                          control="input"
                          label={"Approval Status"}
                          id="approval_status"
                          name="approval_status"
                          className="basic-input"
                          disabled
                        />
                      </Grid>
                      {values.feedback &&
                        (values.question_type === "FREE_TEXT" ? (
                          values.free_text_feedback?.length <= 120 ? (
                            <Grid item xs={12}>
                              <FormikControl
                                control="input"
                                label={"Answer Feedback Option"}
                                id={`free_text_feedback`}
                                name={`free_text_feedback`}
                                className="basic-input"
                                placeholder="-"
                                disabled
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <span
                                style={{
                                  display: "block",
                                  marginBottom: 8,
                                  color: "#808191",
                                  fontSize: 15,
                                }}
                              >
                                Answer Feedback Option
                              </span>
                              <Box sx={styleBox}>
                                <Typography
                                  sx={{ wordBreak: "break-all" }}
                                  minHeight={150}
                                  variant="h6"
                                  fontSize={16}
                                >
                                  {values.free_text_feedback}
                                </Typography>
                              </Box>
                            </Grid>
                          )
                        ) : (
                          values.options?.map((item, index) => (
                            <Grid item xs={12} key={item.id}>
                              <>
                                {values.options[index]["answer_feedback"]
                                  ?.length <= 120 ? (
                                  <FormikControl
                                    control="input"
                                    label={"Answer Feedback Option"}
                                    id={`answer_feedback${item.id}`}
                                    name={`options.${index}.answer_feedback`}
                                    className="basic-input"
                                    placeholder="-"
                                    disabled
                                  />
                                ) : (
                                  <>
                                    <span
                                      style={{
                                        display: "block",
                                        marginBottom: 8,
                                        color: "#808191",
                                        fontSize: 15,
                                      }}
                                    >
                                      Answer Feedback Option
                                    </span>
                                    <Box sx={styleBox}>
                                      <Typography
                                        sx={{ wordBreak: "break-all" }}
                                        minHeight={150}
                                        variant="h6"
                                        fontSize={16}
                                      >
                                        {
                                          values.options[index][
                                            "answer_feedback"
                                          ]
                                        }
                                      </Typography>
                                    </Box>
                                  </>
                                )}
                              </>
                            </Grid>
                          ))
                        ))}

                      <Grid item xs={6}>
                        <Typography variant="h7">Banner Image</Typography>
                        <div style={{ width: 80, marginTop: 10, height: 60 }}>
                          <PreviewImage
                            dataImage={values.banner_image}
                            defaultImage={BannerImage1}
                            alt={"BannerImage"}
                            styles={{ width: "100%", height: "inherit" }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle1"
                          mb={1.5}
                          sx={{ opacity: 0.6 }}
                        >
                          Show Feedback
                        </Typography>
                        <label className="switch">
                          <Field type="checkbox" name="feedback" disabled />
                          <span className="slider round disabled"></span>
                        </label>
                      </Grid>
                      <Grid item xs={4} className="mt-4">
                        <Typography
                          variant="subtitle1"
                          mb={1.5}
                          sx={{ opacity: 0.6 }}
                        >
                          Question Preview
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className="mt-4">
                        <ContentPreview values={values} />
                      </Grid>
                      {values.approval_status !== "APPROVED" && (
                        <Grid item xs={12}>
                          <FormikControl
                            control="input"
                            label={"Reason for Rejection"}
                            id="question"
                            name="rejection_reason"
                            className="basic-input"
                            disabled
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
                {!questionDetail.isLoading && !questionDetail.error && (
                  <AutoFillValue questionDetail={questionDetail} />
                )}
              </Form>
            )}
          </Formik>
          {!questionDetail.isLoading && !questionDetail.error && (
            <Stack direction={"row"} sx={{ mt: 3 }}>
              {questionDetail?.data.status === "UNCHECKED" && (
                <>
                  <ComponentGuard allowedRoles={window.appRoles.approveSurvey}>
                    <CustomButton
                      className={"custom_button white"}
                      style={{ width: 100, marginRight: 15 }}
                      onHandleClick={() =>
                        handleModalRejection(dispatch, {
                          isOpen: true,
                          approvalStatus: false,
                          reason: null,
                          type: "reject",
                          data: {
                            id: questionDetail.data?.id,
                          },
                        })
                      }
                    >
                      <span>Reject</span>
                    </CustomButton>
                  </ComponentGuard>
                  <ComponentGuard allowedRoles={window.appRoles.approveSurvey}>
                    <CustomButton
                      style={{ width: 100 }}
                      type="submit"
                      className={"custom_button black"}
                      onHandleClick={() =>
                        handleModalConfirmation(dispatch, {
                          isOpen: true,
                          type: "approve",
                          data: {
                            id: questionDetail.data?.id,
                            body: {
                              approval_status: true,
                              reason: null,
                            },
                          },
                        })
                      }
                    >
                      <span>Approve</span>
                    </CustomButton>
                  </ComponentGuard>
                </>
              )}

              <ComponentGuard allowedRoles={window.appRoles.createSurvey}>
                <CustomButton
                  style={{ width: 100, marginRight: 15 }}
                  className={"custom_button white"}
                  onHandleClick={() => {
                    handleModalQuestion(dispatch, {
                      isOpen: false,
                      type: "",
                      data: null,
                    });
                  }}
                >
                  <span>Back</span>
                </CustomButton>
              </ComponentGuard>
              {questionDetail?.data.status !== "APPROVED" &&
                questionDetail?.data.status !== "UNCHECKED" && (
                  <ComponentGuard allowedRoles={window.appRoles.createSurvey}>
                    <CustomButton
                      style={{ width: 100 }}
                      type="submit"
                      className={"custom_button black"}
                      onHandleClick={() => handleEdit(questionDetail.data)}
                    >
                      <span>Edit</span>
                    </CustomButton>
                  </ComponentGuard>
                )}
            </Stack>
          )}
        </Grid>
      </Paper>
    </Paper>
  );
}

const mapStatetoProps = (state) => {
  return {
    questionDetail: state.questionBank.questionDetail,
  };
};
export default connect(mapStatetoProps, null)(ContentDetail);
