const initialState = {
  questionList: { isLoading: false, data: false, error: null },
  questionDetail: { isLoading: true, data: false, error: null },
  checkQuestion: {
    questionType: 0,
    questionStep: 0,
  },
  questionData: {
    title: "",
    question_type: 1, // 1: binary, 2: free text, 3: single selection, 4: multiple selection, 5: information
    information: null, // required for question_type = 5
    show_feedback: true,
    free_text_feedback: null, // only required when the question type is 2 (free text)
    banner_image: "", // only required when the question type is 2 (free text)
    // options should be exactly 2 item for binary, minimum of 2 for single selection and multiple selection
    options: [
      {
        answer: "",
        answer_feedback: "",
        image: "",
      },
      {
        answer: "",
        answer_feedback: "",
        image: "",
      },
    ],

    // should be null for free text & information
    // options: null,
    is_submitted: false, // false(draft), true(submit)
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_QUESTION_BANK_LIST":
      return {
        ...state,
        questionList: {
          ...state.questionList,
          isLoading: action.payload?.isLoading,
          data: action.payload?.data,
          error: action.payload?.error,
        },
      };
    case "SET_QUESTION_BANK_DETAIL":
      return {
        ...state,
        questionDetail: {
          ...state.questionDetail,
          isLoading: action.payload?.isLoading,
          data: action.payload?.data,
          error: action.payload?.error,
        },
      };
    case "SET_QUESTION":
      return {
        ...state,
        checkQuestion: {
          ...state.checkQuestion,
          questionType: action.payload?.questionType,
          questionStep: action.payload?.questionStep,
        },
      };
    case "SET_QUESTION_TYPE":
      return {
        ...state,
        questionData: {
          ...state.questionData,
          question_type: action.payload?.question_type,
        },
      };
    case "SET_QUESTION_DATA":
      return {
        ...state,
        questionData: {
          ...state.questionData,
          title: action.payload?.title,
          banner_image: action.payload?.banner_image,
          options: action.payload?.options,
          question_type: action.payload?.question_type,
          information: action.payload?.information,
          show_feedback: action.payload?.show_feedback,
          free_text_feedback: action.payload?.free_text_feedback,
          is_submitted: action.payload?.is_submitted,
        },
      };
    default:
      return state;
  }
};

export default reducer;
