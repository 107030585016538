import React from "react";
import {
  Paper,
  Grid,
  Button,
  Stack,
  Autocomplete,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@mui/icons-material";
import { handleModalQuestion } from "../../../store/actions/modal";
import { ComponentGuard } from "../../ComponentIndex";
import {
  quesitonStatusFilter,
  questionTypeFilter,
} from "../../../constants/consts";

const styleButton = {
  textTransform: "none",
  width: 200,
  fontSize: 15,
  fontWeight: 600,
  margin: 0,
  height: 45,
  borderRadius: "8px",
  backgroundColor: "#0066FF",
  "&:hover": {
    backgroundColor: "#0066FF",
  },
};

function HeaderQuestionList({
  dispatch,
  valueFilter,
  setValueFilter,
  searchQuestion,
  setIsFiltered
}) {
  return (
    <>
      <Paper sx={{ p: 3, borderRadius: 4 }}>
        <Stack sx={{ position: "relative" }}>
          <Stack direction="row" spacing={3}>
            <>
              <Grid item xs={3}>
                <TextField
                  data-testid={`search-question`}
                  id="search-input"
                  label="Search Question Name"
                  variant="outlined"
                  size="small"
                  type="search"
                  onChange={(e) => searchQuestion(e.target.value)}
                  InputProps={{
                    sx: { fontSize: 20 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchOutlined />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "280px", margin: "0" }}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  data-testid={`status-filter`}
                  id="status-filter"
                  value={valueFilter.status}
                  onChange={(event, newValue) => {
                    setValueFilter((prev) => ({
                      ...prev,
                      status: newValue,
                    }));
                    setIsFiltered(true)
                  }}
                  options={quesitonStatusFilter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filter by Status"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        sx: { fontSize: 20 },
                      }}
                      sx={{ width: "280px", margin: "0" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  data-testid={`question-type-filter`}
                  id="question-type-filter"
                  value={valueFilter.questionType}
                  onChange={(event, newValue) => {
                    setValueFilter((prev) => ({
                      ...prev,
                      questionType: newValue,
                    }));
                    setIsFiltered(true)
                  }}
                  options={questionTypeFilter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filter by Question Type"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        sx: { fontSize: 20 },
                      }}
                      sx={{ width: "280px", margin: "0" }}
                    />
                  )}
                />
              </Grid>
              <ComponentGuard allowedRoles={window.appRoles.createSurvey}>
                <Grid item xs={3}>
                  <Link
                    onClick={() => {
                      handleModalQuestion(dispatch, {
                        isOpen: true,
                        type: "create",
                        data: null,
                      });
                    }}
                  >
                    <Button
                      data-testid={`question-bank`}
                      id="newQuestionBank"
                      variant="contained"
                      size="small"
                      sx={styleButton}
                    >
                      <span>Create New Question</span>
                    </Button>
                  </Link>
                </Grid>
              </ComponentGuard>
            </>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
}

export default HeaderQuestionList;
