import React from "react";
import { fetchImage } from "../../utilities/utils";

function ComponentPreviewImage({ dataImage, defaultImage, alt, styles, className }) {
  const [image, setImage] = React.useState("")
  
  React.useEffect(() => {
    if (typeof dataImage === "string") {
      const checkValidImage = dataImage.split("/")
      if (checkValidImage?.length > 1) {
        getImage(dataImage)
      }
    }
  }, [dataImage])

  async function getImage(image) {
    setImage("")
    try {
      const result = await fetchImage(image, "detail")
      setImage(result)
    } catch (error) {
      console.error(`Error fetching image`, error);
    }
  }

  return (
    <img
      src={
        typeof dataImage === "string"
          ? (dataImage && image) || defaultImage
          : (dataImage && URL.createObjectURL(dataImage)) || defaultImage
      }
      alt={alt}
      style={styles}
      className={className}
    />
  );
}

export default ComponentPreviewImage;
