import React, {useEffect, useState} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {campaignStatusMap} from "../../../constants/consts";
import {
  formatDate,
  formatLargeNumberWithCommas,
  tradeInGenericDisplayed
} from "../../../utilities/utils";
import axios from "axios";
import {API_CMS} from "../../../utilities/api-url";

const CampaignDetailsSection = ({ campaign, expanded = true, isTemplate = false }) => {
  const [isExpanded, setIsExpanded] = useState(expanded),
        [surveyName, setSurveyName] = useState("fetching...");

  const handleAccordionChange = (event, newExpandedState) => {
    setIsExpanded(newExpandedState);
  };

  useEffect(() => {
    if (!!campaign.url) {
      axios
      .get(API_CMS.SURVEY_DETAIL(campaign.url))
      .then((response) => {
        setSurveyName(response?.data?.name);
      })
      .catch((error) => {
        console.error("Couldn't fetch survey name", error);
        setSurveyName(campaign.url);
      });
    }
  }, [campaign.url]);

  const startDateDisplay = () => {
    return (
      <>
        <Grid item xs={6}>
          <Typography component="p">
            Offer available start date: {formatDate(campaign.startDate, "dd/MM/yyyy")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography component="p">
            Offer available end date: {formatDate(campaign.endDate, "dd/MM/yyyy")}
          </Typography>
        </Grid>
      </>
    )
  }

  return (
    <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="campaign-details-content"
        id="campaign-details-header"
        sx={{
          background: `${window.theme.colors.header}`,
          borderBottom: `1px solid ${window.theme.colors.notSelectable}`,
        }}
      >
        <Typography variant="h6">Campaign Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <Typography component="p">Name: {campaign.name || campaign.templateName}</Typography>
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <Typography component="p">Campaign type: {campaign.campaignType}</Typography>
          </Grid>
          {tradeInGenericDisplayed(campaign) && (
            <Grid item xs={6}>
              <Typography component="p">
                Trade-in Generic Campaign: {!!campaign?.isGenericTradeIn ? "Yes" : "No"}
              </Typography>
            </Grid>
          )}
          {!!campaign.url && (
            <Grid item xs={12}>
              <Typography component="p">
                External Url: <br />
                {surveyName}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography component="p">
              Description: <br />
              {campaign.description}
            </Typography>
          </Grid>
          {!isTemplate && startDateDisplay()}
          <Grid item xs={6}>
            <Typography component="p">
              Created date:{" "}
              {!!campaign.createdDate
                ? formatDate(campaign.createdDate, "dd/MM/yyyy")
                : formatDate(new Date(), "dd/MM/yyyy")}
            </Typography>
          </Grid>
          {!!campaign.creator && (
            <Grid item xs={6}>
              <Typography component="p">Campaign Creator: {campaign.creator}</Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <Typography component="p">Coupon value: ${formatLargeNumberWithCommas(campaign.couponValue)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="p">
              Maximum distribution: {formatLargeNumberWithCommas(campaign.maxDistribution)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="p">
              Status:{" "}
              <span
                style={{
                  color: `${campaign.status === campaignStatusMap.DELETED} ? ${window.theme.colors.error} : ''`,
                }}
              >
                {campaign.status}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="p">
              Balance expires after days:{" "}
              {campaign.rewardExpireDays !== null && (
                <>
                  {campaign.rewardExpireDays} {campaign.rewardExpireDays === 1 ? "day" : "days"}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CampaignDetailsSection;
