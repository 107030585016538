import { useState, useEffect } from "react";

import { getCampaignColumnHeaders, getCampaignTemplateColumnHeaders, tableTypePathMap } from "../constants/consts";

const useColumnHeaders = (type) => {
  const [columnHeaders, setColumnHeaders] = useState([]);

  useEffect(() => {

    if (type === tableTypePathMap.TEMPLATE) {
      setColumnHeaders(getCampaignTemplateColumnHeaders(window.innerWidth));

    } else {
      setColumnHeaders(getCampaignColumnHeaders(window.innerWidth));
    }

  }, [type]);

  return columnHeaders;
};

export default useColumnHeaders;
