import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { BannerImage1 } from "../../assets";
import { PreviewImage } from "../ComponentIndex";

const styles = {
  marginTop: -2,
  paddingBottom: 3,
};

function InformationPreview({ questionDetail }) {
  return (
    <Stack gap={1} sx={styles}>
      <div>
        <PreviewImage
          dataImage={questionDetail.banner_image}
          defaultImage={BannerImage1}
          alt={"InformationBanner"}
          className={"banner_image"}
        />
      </div>
      <Typography variant="h7" textAlign={"center"} mb={2} mt={1}>
        {questionDetail?.title}
      </Typography>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={12} sx={{ wordBreak: "break-all" }}>
          <p
            dangerouslySetInnerHTML={{
              __html: questionDetail.information,
            }}
            style={{ color: "#FFF" }}
          ></p>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default InformationPreview;
