/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  QuestionBankList,
  HeaderQuestionList,
  CustomModalConfirmation,
  CustomModal,
  ViewDetailQuestion,
} from "../components/ComponentIndex";
import { Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import {
  createQuestionBank,
  deleteQuestionBank,
  editQuestionBank,
  getListQuestionBank,
} from "../store/actions/questionBank";
import { publish, publishErrorMessage } from "../events/events";
import NewQuestionBank from "./NewQuestionBank";
import { getIDTokenClaims, splitData } from "../utilities/utils";
import {
  handleModalConfirmation,
  handleModalQuestion,
  handleModalRejection,
  handleModalSurvey,
} from "../store/actions/modal";

function handleSuccessMessage(type, action) {
  if (action === "create") {
    if (type === "draft") {
      return "Question successfully Saved as Draft";
    } else if (type === "submit") {
      return "Question successfully Added";
    }
  } else if (action === "edit") {
    return "Question successfully Edited";
  } else {
    if (type === "delete") {
      return "Question bank successfully deleted";
    }
  }
}

const MAPPING_QUESTION_TYPE = {
  BINARY: 1,
  FREE_TEXT: 2,
  SINGLE_SELECTION: 3,
  MULTIPLE_SELECTION: 4,
  INFORMATION: 5,
};

function QuestionBank({
  dispatch,
  questionList,
  modalConfirmation,
  modalQuestion,
}) {
  const [timer, setTimer] = React.useState(null);
  const [isLoading, setIsloading] = React.useState(false);
  const [isFiltered, setIsFiltered] = React.useState(false);
  const [isRendered, setIsrendered] = React.useState(false);

  const [totalData, setTotalData] = React.useState({
    active: 0,
    draft: 0,
    archive: 0,
  });
  const [limitasi, setLimitasi] = React.useState({
    active: 5,
    draft: 5,
    archive: 5,
  });
  const [valueFilter, setValueFilter] = React.useState({
    search: "",
    status: null,
    questionType: null,
  });
  const [dataQuestion, setDataQuestion] = React.useState({
    active: null,
    draft: null,
    archive: null,
  });

  React.useEffect(() => {
    // Handle Back from Browser
    window.onpopstate = () => {
      handleModalConfirmation(dispatch, {
        isOpen: false,
        type: "",
        data: null,
      });
      handleModalRejection(dispatch, {
        isOpen: false,
        approvalStatus: false,
        reason: null,
        data: null,
        type: "",
      });
      handleModalQuestion(dispatch, {
        isOpen: false,
        type: "",
        data: null,
      });
      handleModalSurvey(dispatch, {
        isOpen: false,
        type: "",
        data: null,
      });
    };
  });

  React.useEffect(() => {
    getAllQuestionList({
      title: valueFilter.search || "",
      status: valueFilter.status?.value || null,
      question_type: valueFilter.questionType?.value || null,
    });
  }, [
    dispatch,
    valueFilter.search,
    valueFilter.status?.value,
    valueFilter.questionType?.value,
  ]);

  // search question title
  function searchQuestion(value) {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        setValueFilter((prev) => ({ ...prev, search: value }));
        setIsFiltered(true);
      }, 1000)
    );
  }

  async function getAllQuestionList({ title, status, question_type }) {
    setIsrendered(true);
    const payload = {
      role_names: getIDTokenClaims(),
      size: 5,
      title,
      status,
      question_type,
    };
    const [activeQuestion, draftQuestion, archiveQuestion] =
      await Promise.allSettled([
        getListQuestionBank(dispatch, { type: "ACTIVE", sort: "updated_date,DESC", ...payload }),
        getListQuestionBank(dispatch, { type: "DRAFT", sort: "updated_date,DESC", ...payload }),
        getListQuestionBank(dispatch, { type: "ARCHIVED", sort: "deleted_date,DESC", ...payload }),
      ]);
    setDataQuestion((prev) => ({
      ...prev,
      active: activeQuestion.status === "fulfilled" ? activeQuestion.value : [],
      draft: draftQuestion.status === "fulfilled" ? draftQuestion.value : [],
      archive:
        archiveQuestion.status === "fulfilled" ? archiveQuestion.value : [],
    }));
  }
  const onSubmitDraftDelete = async (data) => {
    setIsloading(true);
    if (data.type === "submit") {
      data.data.is_submitted = true;
    } else if (data.type === "draft") {
      data.data.is_submitted = false;
    }

    if (modalQuestion.type === "edit") {
      const mappingQuestionType =
        MAPPING_QUESTION_TYPE[data.data.body.question_type];
      
      let splitBannerImage = splitData(data.data?.body?.banner_image, "/")
      if (splitBannerImage?.length > 0) {
        splitBannerImage = splitBannerImage?.pop();
      }
      const mappingOptionImage =
        data.data?.body?.options?.length > 0
          ? data.data.body.options.map((item) => {
              return {
                ...item,
                image: splitData(item.image, "/")?.length > 0 ? splitData(item.image, "/")?.pop() : "",
              };
            })
          : null;

      const payload = {
        id: data.data.id,
        body: {
          ...data.data.body,
          banner_image: splitBannerImage,
          options: mappingOptionImage,
          is_submitted: data.data.is_submitted,
          question_type: mappingQuestionType,
        },
      };

      callAPIURL(editQuestionBank, payload, data.type, "edit");
    } else if (modalQuestion.type === "create") {
      let splitBannerImage = splitData(data.data?.banner_image, "/")
      if (splitBannerImage?.length > 0) {
        splitBannerImage = splitBannerImage?.pop();
      }
      const mappingOptionImage =
        data.data?.options?.length > 0
          ? data.data.options.map((item) => {
              return {
                ...item,
                image: splitData(item.image, "/")?.length > 0 ? splitData(item.image, "/")?.pop() : "",
              };
            })
          : null;
      const payload = {
        ...data.data,
        banner_image: splitBannerImage,
        options: mappingOptionImage,
      };
      callAPIURL(createQuestionBank, payload, data.type, "create");
    } else if (data.type === "delete") {
      callAPIURL(deleteQuestionBank, data.data.id, data.type);
    }
  };

  const callAPIURL = (apiURL, data, type, action) => {
    apiURL(data)
      .then(() => {
        publish("showNotification", {
          message: handleSuccessMessage(type, action),
          severity: "success",
        });
      })
      .catch((error) => {
        publishErrorMessage(error?.response?.data?.title);
      })
      .finally(() => {
        window.location.reload();
      });
  };

  return (
    <>
      <Grid container gap={2}>
        <Grid item xs={12}>
          <HeaderQuestionList
            dispatch={dispatch}
            searchQuestion={searchQuestion}
            valueFilter={valueFilter}
            setValueFilter={setValueFilter}
            setIsFiltered={setIsFiltered}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <QuestionBankList
                dispatch={dispatch}
                status="ACTIVE"
                title="Active Question"
                questionList={dataQuestion.active}
                isLoading={questionList.isLoading}
                isError={questionList.error}
                isFiltered={isFiltered}
                valueFilter={valueFilter}
                setLimitasi={setLimitasi}
                limitasi={limitasi}
                setDataQuestion={setDataQuestion}
                dataLength={limitasi.active}
                setTotalData={setTotalData}
                totalData={totalData.active}
                isRendered={isRendered}
                setIsrendered={setIsrendered}
              />
            </Grid>
            <Grid item xs={12}>
              <QuestionBankList
                dispatch={dispatch}
                status="DRAFT"
                title="Draft Question"
                questionList={dataQuestion.draft}
                isLoading={questionList.isLoading}
                isError={questionList.error}
                isFiltered={isFiltered}
                valueFilter={valueFilter}
                setLimitasi={setLimitasi}
                setDataQuestion={setDataQuestion}
                limitasi={limitasi}
                dataLength={limitasi.draft}
                setTotalData={setTotalData}
                totalData={totalData.draft}
                isRendered={isRendered}
                setIsrendered={setIsrendered}
              />
            </Grid>
            <Grid item xs={12}>
              <QuestionBankList
                dispatch={dispatch}
                status="ARCHIVE"
                title="Archive Question"
                questionList={dataQuestion.archive}
                isLoading={questionList.isLoading}
                isError={questionList.error}
                isFiltered={isFiltered}
                valueFilter={valueFilter}
                setLimitasi={setLimitasi}
                setDataQuestion={setDataQuestion}
                limitasi={limitasi}
                dataLength={limitasi.archive}
                setTotalData={setTotalData}
                totalData={totalData.archive}
                isRendered={isRendered}
                setIsrendered={setIsrendered}
              />
            </Grid>
          </Grid>
        </Grid>

        <CustomModalConfirmation
          dispatch={dispatch}
          modalConfirmation={modalConfirmation}
          onHandleSubmit={onSubmitDraftDelete}
          setIsloading={setIsloading}
          isLoading={isLoading}
        >
          {modalConfirmation.type === "submit" && (
            <>
              <Typography
                id="modal-modal-title"
                variant="body1"
                fontWeight={600}
                fontSize={32}
                lineHeight={"normal"}
              >
                Are you sure all data input are correct?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Submitted data will be reviewed by checker.
              </Typography>
            </>
          )}
          {modalConfirmation.type === "draft" && (
            <>
              <Typography
                id="modal-modal-title"
                variant="body1"
                fontWeight={600}
                fontSize={32}
                lineHeight={"normal"}
              >
                Save to draft and continue later?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                All entered data will be stay as your latest input.
              </Typography>
            </>
          )}
          {modalConfirmation.type === "delete" && (
            <>
              <Typography
                id="modal-modal-title"
                variant="body1"
                fontWeight={600}
                fontSize={32}
                lineHeight={"normal"}
              >
                Are you sure you want to delete this question?
              </Typography>
            </>
          )}
          {modalConfirmation.type === "approve" && (
            <Typography
              id="modal-modal-title"
              variant="body1"
              fontWeight={600}
              fontSize={32}
              lineHeight={"normal"}
            >
              Are you sure to approve this question?
            </Typography>
          )}
        </CustomModalConfirmation>
        {modalQuestion.type !== "" && (
          <CustomModal isOpen={modalQuestion.isOpen}>
            <>
              {(modalQuestion.type === "create" ||
                modalQuestion.type === "edit") && <NewQuestionBank />}
              {modalQuestion.type === "preview" && <ViewDetailQuestion />}
            </>
          </CustomModal>
        )}
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    modalConfirmation: state.modal.modalConfirmation,
    questionList: state.questionBank.questionList,
    modalQuestion: state.modal.modalQuestion,
  };
};

export default connect(mapStatetoProps, null)(QuestionBank);
