import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Stack } from "@mui/system";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { ImageListQuestion1 } from "../../assets";
import {
  handleModalConfirmation,
  handleModalSurvey,
} from "../../store/actions/modal";
import { ComponentGuard } from "../ComponentIndex";
import { apiGet } from "../../utilities/api";
import { API_CMS } from "../../utilities/api-url";
import { publishErrorMessage } from "../../events/events";
import { fetchImage, getIDTokenClaims } from "../../utilities/utils";

const columns = [
  { id: "survey", label: "Survey", minWidth: 170 },
  { id: "sequence_id", label: "Sequence ID", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

export default function SurveyTable({
  dispatch,
  surveyList,
  isLoading,
  isError,
  isFiltered,
  valueFilter,
  setLimitasi,
  limitasi,
  setDataSurvey,
  scrollableTarget,
  dataLength,
  setTotalData,
  totalData,
  isRendered,
  setIsrendered,
}) {

  const [newImages, setNewImages] = React.useState([])

  React.useEffect(() => {
    if (isRendered) {
      setIsrendered(false);
      setLimitasi({
        active: 5,
        draft: 5,
        archive: 5,
      });
      setTotalData({
        active: 0,
        draft: 0,
        archive: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRendered]);
  function handleModal(action, type, row) {
    action(dispatch, {
      isOpen: true,
      type,
      data: {
        id: row.id,
      },
    });
  }
  const fetchInfiniteScroll = async (params, limit) => {
    let sort  = {}
    if (scrollableTarget === "ACTIVE" || scrollableTarget === "DRAFT") {
      sort = 'updated_date,DESC'
    } else if (scrollableTarget === "ARCHIVE") {
      sort = 'deleted_date,DESC'
    }
    const payload = {
      type: `${params}`,
      role_names: getIDTokenClaims(),
      size: limit,
      title: valueFilter?.title || "",
      status: valueFilter.status?.value || null,
      sort
    };
    try {
      const response = await apiGet(API_CMS.SURVEY_LIST, payload);
      let data = {};
      if (scrollableTarget === "ACTIVE") {
        setTotalData((prev) => ({
          ...prev,
          active: response.data.total_elements,
        }));
        data.active = response.data;
      } else if (scrollableTarget === "DRAFT") {
        setTotalData((prev) => ({
          ...prev,
          draft: response.data.total_elements,
        }));
        data.draft = response.data;
      } else if (scrollableTarget === "ARCHIVE") {
        setTotalData((prev) => ({
          ...prev,
          archive: response.data.total_elements,
        }));
        data.archive = response.data;
      }
      setDataSurvey((prev) => ({
        ...prev,
        ...data,
      }));
    } catch (error) {
      publishErrorMessage(error?.response?.data?.title);
    }
  };
  const fetchMoreData = () => {
    if (!isRendered) {
      setTimeout(() => {
        if (scrollableTarget === "ACTIVE") {
          if (
            totalData === 0 ||
            totalData >= dataLength ||
            totalData === dataLength - 1
          ) {
            const limit = limitasi.active + 5;
            fetchInfiniteScroll(scrollableTarget, limit);
            setLimitasi((prev) => ({ ...prev, active: limit }));
          }
        } else if (scrollableTarget === "DRAFT") {
          if (
            totalData === 0 ||
            totalData >= dataLength ||
            totalData === dataLength - 1
          ) {
            const limit = limitasi.draft + 5;
            fetchInfiniteScroll(scrollableTarget, limit);
            setLimitasi((prev) => ({ ...prev, draft: limit }));
          }
        } else if (scrollableTarget === "ARCHIVE") {
          if (
            totalData === 0 ||
            totalData >= dataLength ||
            totalData === dataLength - 1
          ) {
            const limit = limitasi.archive + 5;
            fetchInfiniteScroll(`${scrollableTarget}D`, limit);
            setLimitasi((prev) => ({ ...prev, archive: limit }));
          }
        }
      }, 500);
    }
  };

  function showImage(path) {
    const findData = newImages.find(item => item[path] )
    if (findData !== undefined && Object.keys(findData).length > 0) {
      return findData[path]
    }
    return ""
  }

  async function convertImage(imageURL) {
    try {
      const result = await fetchImage(imageURL, "list");
      if (result) {
        setNewImages(prev => [...prev, result]);
      } 
    } catch (error) {
      console.error(`Error fetching image`, error);
    }
  }

  function getArrayList(limit) {
    surveyList?.content?.slice(limit - 5)?.forEach((item) => {
      convertImage(item.banner_image);
    });
  }

  React.useEffect(() => {
    if (!surveyList?.empty) {
      if (surveyList?.content?.length > 0) {
        if (scrollableTarget === "ACTIVE") {
          getArrayList(limitasi.active)
        }
        if (scrollableTarget === "DRAFT") {
          getArrayList(limitasi.draft)
        }
        if (scrollableTarget === "ARCHIVE") {
          getArrayList(limitasi.archive)
        }
      }
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableTarget, surveyList?.content, surveyList?.empty]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 530 }} id={`${scrollableTarget}`}>
        <InfiniteScroll
          style={{overflow: 'unset'}}
          dataLength={dataLength}
          next={fetchMoreData}
          hasMore={true}
          scrollableTarget={`${scrollableTarget}`}
          loader={
            scrollableTarget &&
            surveyList?.content.length > 1 &&
            surveyList?.total_elements > 5 && // 5 is size element per page
            (totalData === 0 || totalData >= dataLength) ? (
              <h6 style={{ marginLeft: 16 }}>Loading...</h6>
            ) : (
              ""
            )
          }
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isLoading && !isError && (
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={4}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {!isLoading && !surveyList?.empty && (
              <TableBody>
                {surveyList?.content?.map((row, i) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th">
                        <Stack direction={"row"} gap={2}>
                          <Avatar
                            alt="ImageListQuestion1"
                            src={showImage(row?.banner_image) || ImageListQuestion1}
                            sx={{ width: 90, height: 70, borderRadius: 2 }}
                            variant="square"
                          />
                          <Stack>
                            <Link
                              sx={{ cursor: "pointer" }}
                              underline="none"
                              onClick={() => {
                                handleModal(handleModalSurvey, "preview", row);
                              }}
                            >
                              <Typography
                                gutterBottom
                                variant="h7"
                                fontWeight={600}
                                fontSize={16}
                                color={"#323232"}
                              >
                                {row.name}
                              </Typography>
                            </Link>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell>{row.sequence_id}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={0}>
                          <ComponentGuard
                            allowedRoles={window.appRoles.viewSurvey}
                          >
                            <IconButton
                              onClick={() => {
                                handleModal(handleModalSurvey, "preview", row);
                              }}
                            >
                              <RemoveRedEyeOutlinedIcon />
                            </IconButton>
                          </ComponentGuard>
                          {(scrollableTarget === "ARCHIVE" ||
                            row.status === "DRAFTED" ||
                            row.status === "REJECTED") && (
                            <ComponentGuard
                              allowedRoles={window.appRoles.createSurvey}
                            >
                              <IconButton
                                onClick={() => {
                                  handleModal(handleModalSurvey, "edit", row);
                                }}
                              >
                                <BorderColorOutlinedIcon />
                              </IconButton>
                            </ComponentGuard>
                          )}
                          {scrollableTarget !== "ARCHIVE" &&
                            row.status !== "UNCHECKED" && 
                            row.status !== "APPROVED" && (
                              <ComponentGuard
                                allowedRoles={window.appRoles.createSurvey}
                              >
                                <IconButton
                                  onClick={() => {
                                    handleModal(
                                      handleModalConfirmation,
                                      "delete",
                                      row
                                    );
                                  }}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                              </ComponentGuard>
                            )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
            {!isLoading && !isError && surveyList?.empty && !isFiltered && (
              <TableBody>
                <TableRow>
                  <TableCell component="th">
                    <Typography gutterBottom variant="body1" fontWeight={500}>
                      Data not found
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {!isLoading && !isError && surveyList?.empty && isFiltered && (
              <TableBody>
                <TableRow>
                  <TableCell component="th">
                    <Typography gutterBottom variant="body1" fontWeight={500}>
                      Result not found
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </InfiniteScroll>
      </TableContainer>
    </Paper>
  );
}
