import {
  Grid,
  Paper,
  Tab,
  Box,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import React from "react";
import { TabContext, TabList } from "@mui/lab";
import {
  ComponentCardStep,
  CustomModalConfirmation,
  TemplateQuestionTypes,
} from "../components/ComponentIndex";
import { CloseTwoTone } from "@mui/icons-material";
import { connect } from "react-redux";
import { handleModalQuestion, handleModalConfirmation } from "../store/actions/modal";
import {
  detailQuestionBank,
  handleQuestion,
  resetQuestionData,
  setQuestionType,
} from "../store/actions/questionBank";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    style: { opacity: 1 },
  };
}
function defaultStyle(defaultValue, valueTab) {
  if (defaultValue === valueTab) {
    return { backgroundColor: "#00A45F", color: "#FFF" };
  }
  return { borderColor: "#0066FF", color: "#000", border: "1px solid #0066FF" };
}
function activeStepQuestion(questionStep, params) {
  if (questionStep === params) {
    return {
      backgroundColor: "#00A45F",
      borderRadius: 10,
      textTransform: "none",
      color: "#FFF",
    };
  }
  return {
    boxShadow: "none",
    color: "#323232",
  };
}
function handleCursor(currStep, defaultStep, modalQuestion) {
  if (
    currStep < defaultStep ||
    (modalQuestion.type === "edit" && defaultStep === 0)
  ) {
    return {
      cursor: "default",
    };
  }
  return { cursor: "pointer" };
}
const questionList = [
  {
    title: "1. Question Type",
    description: "Pick type of the question from the options provided.",
  },
  {
    title: "2. Details",
    description: "Fill question, answer, and feedback details.",
  },
  { title: "3. Images", description: "Upload thumbnail question and answers." },
  { title: "4. Preview", description: "Question preview." },
];
const mappingTitle = {
  create: "Create",
  edit: "Edit",
};
const stylePaper = {
  p: 4,
  borderRadius: 6,
  overflow: "auto",
  height: 600,
  scrollbarWidth: "none",
};

const styleTab = (index, questionType) => ({
  borderRadius: 10,
  padding: "10px 15px",
  textTransform: "none",
  ...defaultStyle(index, questionType),
});

function NewQuestionBank({ dispatch, question, modalQuestion, modalConfirmation, formQuestion }) {
  const [isDataChanged, setIsDataChanged] = React.useState(false);
  const [stateQuestionType, setStateQuestionType] = React.useState(0)

  React.useEffect(() => {
    if (modalQuestion.type === "edit") {
      detailQuestionBank(dispatch, modalQuestion?.data?.id);
    }
  }, [dispatch, modalQuestion, modalQuestion?.data?.id]);

  React.useEffect(() => {
    if (modalQuestion.type === "create") {
      // initial question
      handleQuestion(dispatch, {
        questionType: 0,
        questionStep: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleQuestionType = (event, value) => {
    setStateQuestionType(value)
    handleQuestion(dispatch, {
      questionType: value,
      questionStep: question.questionStep,
    });
  };

  const handleStepQuestion = (currStep, defaultStep) => {
    if (
      currStep < defaultStep ||
      (modalQuestion.type === "edit" && defaultStep === 0)
    ) {
      return;
    }

    handleQuestion(dispatch, {
      questionType: `${question.questionType}`,
      questionStep: defaultStep,
    });
  };

  const handleChangeQuestion = () => {
    resetQuestionData(dispatch);
    handleModalConfirmation(dispatch, {
      isOpen: false,
      type: "",
      data: null,
    });

    handleQuestion(dispatch, {
      questionType: stateQuestionType,
      questionStep: question.questionStep + 1,
    });
    
    switch (+stateQuestionType) {
      case 0:
        setQuestionType(dispatch, {
          question_type: 1
        }) // 1 = Binary
        break;
      case 1:
        setQuestionType(dispatch, {
          question_type: 3
        }) // 3 = Single Select
        break;
      case 2:
        setQuestionType(dispatch, {
          question_type: 4
        }) // 4 = Multiple Select
        break;
      case 3:
        setQuestionType(dispatch, {
          question_type: 2
        }) // 2 = Free Text
        break;
      case 4:
        setQuestionType(dispatch, {
          question_type: 5
        }) // 5 = Information
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
        padding={4}
      >
        <Grid item xs={10}>
          <Paper sx={{ padding: 3, borderRadius: 4 }}>
            <Stack
              justifyContent={"space-between"}
              alignItems={"baseline"}
              direction={"row-reverse"}
            >
              <IconButton
                onClick={() => {
                  handleModalQuestion(dispatch, {
                    isOpen: false,
                    type: "",
                    data: null,
                  });
                  resetQuestionData(dispatch);
                }}
              >
                <CloseTwoTone
                  fontSize="medium"
                  sx={{
                    backgroundColor: "#D8D8D8",
                    padding: 0.5,
                    borderRadius: 10,
                  }}
                />
              </IconButton>
              <Typography
                gutterBottom
                variant="h2"
                fontWeight={"bold"}
                fontSize={40}
              >
                {mappingTitle[modalQuestion.type]} Question
              </Typography>
            </Stack>

            <Paper elevation={4} sx={stylePaper}>
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <Stack gap={2}>
                    {questionList.map((item, index) => {
                      return (
                        <ComponentCardStep
                          key={index}
                          index={index}
                          styleCard={activeStepQuestion(
                            question.questionStep,
                            index
                          )}
                          styleCardAction={handleCursor(
                            question.questionStep,
                            index,
                            modalQuestion
                          )}
                          title={item.title}
                          step={question.questionStep}
                          onHandleClick={() =>
                            handleStepQuestion(question.questionStep, index)
                          }
                        >
                          {item.description}
                        </ComponentCardStep>
                      );
                    })}
                  </Stack>
                </Grid>

                <Grid item sm={9}>
                  <TabContext value={`${question.questionType}`}>
                    {question.questionStep.toString() === "0" && (
                      <Box mb={3}>
                        <Typography variant="h6" fontWeight={500} mb={2}>
                          Choose question type
                        </Typography>
                        <TabList
                          sx={{ marginLeft: 0, paddingLeft: 0 }}
                          onChange={handleQuestionType}
                          aria-label="aria-label-tablist"
                          indicatorColor=""
                          TabIndicatorProps={{
                            style: { backgroundColor: "transparent" },
                          }}
                        >
                          {[
                            "Binary",
                            "Single Select",
                            "Multi Select",
                            "Free Text",
                            "Information",
                          ].map((item, index) => (
                            <Tab
                              key={index}
                              value={`${index}`}
                              label={
                                <div
                                  style={styleTab(
                                    `${index}`,
                                    `${question.questionType}`
                                  )}
                                >
                                  {item}
                                </div>
                              }
                              {...a11yProps(index)}
                            />
                          ))}
                        </TabList>
                      </Box>
                    )}

                    <TabPanel
                      sx={{ marginLeft: 0.5, paddingLeft: 0.5, pt: 0 }}
                      value={`${question.questionType}`}
                    >
                      {TemplateQuestionTypes({
                        dispatch,
                        isDataChanged,
                        setIsDataChanged,
                      }).map((item, index) => {
                        if (
                          item[0].toString() ===
                            question.questionStep.toString() &&
                          item[1].toString() === `${question.questionType}`
                        ) {
                          return <div key={index}>{item[2]}</div>;
                        }
                        return null;
                      })}
                    </TabPanel>
                  </TabContext>
                </Grid>
              </Grid>
              {modalConfirmation.type === "change-question" && (
                <CustomModalConfirmation
                  dispatch={dispatch}
                  modalConfirmation={modalConfirmation}
                  onHandleSubmit={handleChangeQuestion}
                >
                  <>
                    <Typography
                      id="modal-title"
                      variant="body1"
                      fontWeight={600}
                      fontSize={32}
                      lineHeight={"normal"}
                    >
                      Do you wish to continue?
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                      Changing question type will remove all the details you have
                      entered
                    </Typography>
                  </>
                </CustomModalConfirmation>
              )}
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    question: state.questionBank.checkQuestion,
    modalQuestion: state.modal.modalQuestion,
    modalConfirmation: state.modal.modalConfirmation,
    formQuestion: state.questionBank.questionData,
  };
};
export default connect(mapStatetoProps, null)(NewQuestionBank);
