import React from "react";

import { Grid, Typography } from "@mui/material";
import { ImageForm } from "../ComponentIndex";
import { imageTitles } from "../../constants/consts";
import {isMarketingCampaignType} from "../../utilities/campaignHelpers";

const SelectImages = ({ formValues, images, setImage, existingImagesUrls, removeImageUrl }) => {
  const getImageUrl = (imageType) => {
    const imageObject = existingImagesUrls.find(
        (image) => image.name === imageType);
    return imageObject ? imageObject.value : "";
  };

  return (
      <Grid container spacing={2} sx={{p: "16px 0"}}>
        <Grid item xs={12}>
          <Typography variant="h3" color="primary">
            Images
          </Typography>
        </Grid>

        {!isMarketingCampaignType(formValues) && (
            <Grid item xs={6} sm={12}>
              <ImageForm
                  id="brand-image-selection"
                  label={imageTitles.BRAND}
                  selectedImage={images["BRAND"]}
                  setImage={(image) => setImage(image, "BRAND")}
                  imageUrl={getImageUrl(imageTitles.BRAND)}
                  removeImageUrl={() => removeImageUrl(imageTitles.BRAND)}
              />
            </Grid>
        )}
        <Grid item xs={6} sm={12}>
          <ImageForm
              id="hero-image-selection"
              label={imageTitles.HERO}
              selectedImage={images["HERO"]}
              setImage={(image) => setImage(image, "HERO")}
              imageUrl={getImageUrl(imageTitles.HERO)}
              removeImageUrl={() => removeImageUrl(imageTitles.HERO)}
          />
        </Grid>
      </Grid>
  );
};

export default SelectImages;
