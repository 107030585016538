import { List, ListItem, ListItemText, Stack } from "@mui/material";
import React from "react";

const styleList = {
  width: "100%",
  pl: 2,
  listStyle: "inherit",
  color: "#808191",
  "& li": {
    lineHeight: "normal",
    margin: 0,
    padding: 0,
  },
};

function FileUploadRequirement({ dimension = "484 x 484", size = 150 }) {
  return (
    <>
      <Stack sx={{ pl: 2 }}>
        <List sx={styleList}>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText secondary="Acceptable formats are JPEG, PNG, GIF, or Lottie files." />
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText secondary="Ensure the image is clear, relevant, and free from any copyright restrictions." />
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText
              secondary={`Each image should be exactly ${dimension} pixels for optimal display.`}
            />
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText
              secondary={`File size should not exceed ${size}KB to ensure quick loading times and optimal performance.`}
            />
          </ListItem>
        </List>
      </Stack>
    </>
  );
}

export default FileUploadRequirement;
