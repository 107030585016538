import {
  isDateFormatValid,
  isEndDateBeforeStartDate,
  isStartDateInThePast,
  isNameInvalid,
  isValidUrl,
  toIsoDate, doesNameContainIllegalCharacters,
} from "./utils";

export const checkAssignmentRulesExist = (formValues) => {
  return (
    !!(formValues?.assignmentCriteria?.ruleGroups?.length === 0) &&
    !!(formValues?.assignmentCriteria?.primaryRuleGroup?.rules?.length === 0)
  );
};

export const isActionItemCampaign = (formValues) => {
  return formValues?.campaignType?.toUpperCase() === "GENERIC TIME BOUND" ||
      formValues?.campaignType?.toUpperCase() === "PLAN KICKER" ;
}

export const isMarketingCampaignType = (formValues) => {
  return formValues?.campaignType === "Marketing";
};

export const isOverZero = (value) => {
  return value > 0;
};

export const isOverThirty = (value) => {
  return value?.length > 30;
};

export const isOverFiveHundred = (value) => {
  return value?.length > 500;
};

export const isBetweenOneAndFiveHundred = (value) => {
  return value?.length > 0 && value?.length <= 500;
};

export const isOverHundredAndRequired = (value) => {
  return value?.length < 1 || value?.length > 100;
};

export const isOverHundred = (value) => {
  return value?.length > 100;
};

export const isOverSixty = (value) => {
  return value?.length > 60;
};

export const isOverThousand = (value) => {
  return value?.length > 1000;
};

export const isOverThousandAndRequired = (value) => {
  return value?.length < 1 || value?.length > 1000;
};

export const isValidExternalUrl = (value) => {
  const regex = new RegExp("^(https?|http)://([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?");
  return value?.length > 200 ? false : regex.test(value);
};

export const isValid = (value) => {
  return value?.length > 0;
};

export const isValidValue = (value) => {
  if (typeof value === "string") {
    const testValue = value.replace(/[$,]/g, "");
    return testValue > 0 && (testValue !== "" || testValue !== undefined || testValue !== null);
  } else if (typeof value === "number") {
    return value > 0 && (value !== "" || value !== undefined || value !== null);
  } else {
    return false;
  }
};

export const isInvalidEndDate = (value) => {
  return value === null || value === "" || value === undefined || !isDateFormatValid(value);
};

export const isDraftEndDateInPast = (value) => {
  if (value === null || value === "" || value === undefined) {
    return false;
  }
  const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  const endDate = new Date(new Date(value).setHours(0, 0, 0, 0));

  return endDate < currentDate;
};

export const isValidStartDate = (value) => {
  if (!isDateFormatValid(value) || value === null || value === "" || value === undefined) {
    return false;
  }
  const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  const targetDate = value instanceof Date ? value : new Date(new Date(value).setUTCHours(0, 0, 0, 0));
  return currentDate <= targetDate;
};

export const verifyCampaignType = (formValues, campaignTypes) => {
  if (formValues.campaignType === '' || formValues.campaignType === null) {
    return false;
  }
  let campaignTypeDeactivation = campaignTypes.filter(function (campaign) {
    return campaign.name === formValues.campaignType;
  });
  return campaignTypeDeactivation === null || campaignTypeDeactivation === undefined ||
    campaignTypeDeactivation.length === 0;
}

export const verifyCampaignValues = (formValues, campaigns, editMode, campaignTypes, isTemplate = false) => {
  return (
    formValues.name.length < 1 ||
    formValues.name.length > 30 ||
    formValues.description.length > 500 ||
    formValues.campaignType < 1 ||
    (!isTemplate && (
      (!isMarketingCampaignType(formValues) && formValues.rewardExpireDays < 1) ||
      (!formValues.isGenericTradeIn && !isMarketingCampaignType(formValues) && formValues.couponValue < 1) ||
      (formValues.couponValue === undefined ||
      formValues.couponValue === null ||
      isNaN(formValues.couponValue)) ||
      formValues.maxDistribution < 1 ||
      isNaN(formValues.maxDistribution) ||
      !isDateFormatValid(formValues.startDate) ||
      !isDateFormatValid(formValues.endDate) ||
      isEndDateBeforeStartDate(formValues.startDate, formValues.endDate) ||
      isStartDateInThePast(formValues.startDate) ||
      isNameInvalid(formValues, campaigns, editMode) ||
      doesNameContainIllegalCharacters(formValues, editMode) ||
      formValues.endDate === "" ||
      verifyCampaignType(formValues, campaignTypes)
    ))
  );
};

export const verifyCriteriaValues = (formValues) => {
  let showError = false;
  let errorMessage = "";

  // returns an array of invalid eligibility criteria primary group rules
  const invalidCriteriaPrimaryRules = formValues?.eligibilityCriteria?.primaryRuleGroup?.rules?.filter((rule) => {
    return rule.ruleKey === "" || rule.value === "";
  });

  // returns an array of invalid assignment criteria primary group rules
  const invalidAssignmentCriteriaPrimaryRules = formValues?.assignmentCriteria?.primaryRuleGroup?.rules?.filter(
    (rule) => {
      return rule.ruleKey === "" || rule.value === "";
    }
  );

  if (invalidCriteriaPrimaryRules.length > 0 || invalidAssignmentCriteriaPrimaryRules.length > 0) {
    showError = true;
    errorMessage = "Please check the form and fill out all required rules fields. ";
  }

  // returns an array of eligibility criteria groups with zero rules
  const invalidEligibilityGroups = formValues?.eligibilityCriteria?.ruleGroups?.filter((group) => {
    return !!group.rules && group.rules.length === 0;
  });

  // returns an array of invalid assignment criteria groups with zero rules
  const invalidAssignmentGroups = formValues?.assignmentCriteria?.ruleGroups?.filter((group) => {
    return !!group.rules && group.rules.length === 0;
  });

  if (invalidEligibilityGroups.length > 0 || invalidAssignmentGroups.length > 0) {
    showError = true;
    errorMessage += "Please ensure each rule group contains at least one rule. ";
  }

  // returns an array with eligibility groups that have invalid rules
  const criteriaGroupsWithInvalidRules = [];
  formValues?.eligibilityCriteria?.ruleGroups?.forEach((group) => {
    group.rules.forEach((rule) => {
      if (rule.ruleKey === "" || rule.value === "") {
        criteriaGroupsWithInvalidRules.push(group);
      }
    });
  });

  // returns an array with assignment groups that have invalid rules
  const assignmentGroupsWithInvalidRules = [];
  formValues?.assignmentCriteria?.ruleGroups?.forEach((group) => {
    group.rules.forEach((rule) => {
      if (rule.ruleKey === "" || rule.value === "") {
        assignmentGroupsWithInvalidRules.push(group);
      }
    });
  });

  // returns an array with exclusion criteria groups that have invalid rules
  const exclusionCriteriaInvalidRules = [];
  formValues?.exclusionCriteria?.forEach((rule) => {
    if (rule.ruleKey === "" || rule.value === "") {
      exclusionCriteriaInvalidRules.push(rule);
    }
  });

  // returns an array with exclusion criteria that have dates after the campaign start date
  const exclusionCriteriaDateAfterStartDate = [];
  formValues?.exclusionCriteria?.forEach((rule) => {
    if (!!rule.date && rule.date > toIsoDate(formValues.startDate)) {
      exclusionCriteriaDateAfterStartDate.push(rule);
    }
  });

  if (exclusionCriteriaDateAfterStartDate.length > 0) {
    showError = true;
    errorMessage += "Please ensure that the date is not after the start date of the campaign. ";
  }

  if (exclusionCriteriaInvalidRules.length > 0) {
    showError = true;
    errorMessage += "Please check the exclusion rules and make sure all required fields are filled. ";
  }

  if (criteriaGroupsWithInvalidRules.length > 0 || assignmentGroupsWithInvalidRules.length > 0) {
    showError = true;
    errorMessage += "Please check the rules within each rule group and make sure all required fields are filled. ";
  }

  return { showError, errorMessage };
};

export const verifyOfferMessageValues = (messageFormValues) => {
  const isValidExternalUrl = isValidUrl(messageFormValues?.couponOfferExternalUrl);
  return (
    messageFormValues?.couponOfferName?.length < 1 ||
    messageFormValues?.couponOfferHeading?.length < 1 ||
    messageFormValues?.couponOfferAgentInstructions?.length < 1 ||
    messageFormValues?.couponOfferAgentInstructions?.length > 1000 ||
    messageFormValues?.couponOfferDetails?.length > 1000 ||
    messageFormValues?.couponOfferShortTAndC?.length > 500 ||
    messageFormValues?.couponOfferValueSubheading?.length > 100 ||
    messageFormValues?.couponCTATitle?.length > 60 ||
    messageFormValues?.couponOfferHeading?.length > 100 ||
    messageFormValues?.couponOfferUrlTitle?.length > 100 ||
    messageFormValues?.actionTitle?.length > 100 ||
    messageFormValues?.actionUrl?.length > 200 ||
    messageFormValues?.saveOfferSavedTitle > 100 ||
    messageFormValues?.saveOfferUnsavedTitle > 100 ||
    !isValidExternalUrl
  );
};

export const calculateRewardExpirationDate = (rewardExpireDays) => {
    const rewardExpireAfterDays = parseInt(rewardExpireDays, 10);
    const expirationDate = new Date();
    expirationDate.setHours(0, 0, 0, 0);
    expirationDate.setDate(expirationDate.getDate() + rewardExpireAfterDays);
    return expirationDate;
};