import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const styleBox = {
  backgroundColor: "#17412F",
  borderRadius: 3,
  paddingTop: 2,
  paddingLeft: 3,
  paddingRight: 3,
  overflowY: "auto",
};

function FreeTextPreview() {
  return (
    <Grid container spacing={2} justifyContent={"center"} display={"contents"}>
      <Box sx={styleBox}>
        <Typography
          color={"#fff"}
          sx={{ wordBreak: "break-all" }}
          height={250}
          variant="h6"
          fontSize={16}
        >
          Tell us what you think
        </Typography>
      </Box>
    </Grid>
  );
}

export default FreeTextPreview;
