import React from "react";
import BinaryPreview from "./BinaryPreview";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { BannerImage1, ImagePreview } from "../../../assets";
import { PreviewImage } from "../../ComponentIndex";
import SingleMultiPreview from "./SingleMultiplePreview";
import FreeTextPreview from "./FreeTextPreview";
import InformationPreview from "./InformationPreview";

const styleCard = {
  backgroundImage: `url(${ImagePreview})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  borderRadius: 10,
  overflow: "auto",
  height: "500px",
};

function ContentPreview({ values }) {
  const COMPONENT_DETAIL = {
    BINARY: <BinaryPreview values={values} />,
    SINGLE_SELECTION: <SingleMultiPreview values={values} />,
    MULTIPLE_SELECTION: <SingleMultiPreview values={values} />,
    FREE_TEXT: <FreeTextPreview values={values} />,
    INFORMATION: <InformationPreview values={values} />,
  };

  return (
    <>
      <Card style={styleCard}>
        <CardContent sx={{ color: "#FFF" }}>
          <Stack gap={1}>
            <div>
              <PreviewImage
                dataImage={values.banner_image}
                defaultImage={BannerImage1}
                alt={"BannerImage1"}
                styles={{ width: "100%", height: 100 }}
              />
            </div>
            <Typography variant="h7" textAlign={"center"} mb={4}>
              {values?.title}
            </Typography>
            {COMPONENT_DETAIL[values?.question_type]}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}

export default ContentPreview;
