import { Button, Grid, Stack } from "@mui/material";
import React from "react";
import { BinaryImage1 } from "../../../assets";
import { PreviewImage } from "../../ComponentIndex";

function BinaryPreview({ values }) {
  return (
    <Grid container spacing={2} justifyContent={"center"}>
      {values?.options?.map((item, index) => (
        <Grid item xs={6} key={index}>
          <Stack alignItems={"center"}>
            <PreviewImage
              dataImage={item.image}
              defaultImage={BinaryImage1}
              alt={`binary${index}`}
              styles={{ width: "80%" }}
            />
            <Button
              variant="contained"
              sx={{
                "&:hover": { backgroundColor: index % 2 === 1 ? "#FFF" : "#17412F"},
                mt: 4,
                backgroundColor: index % 2 === 1 ? "#FFF" : "#17412F" ,
                color: index % 2 === 1 ? "#000" : "#FFF",
                minHeight: 46,
                width: 150,
                borderRadius: 2,
                textTransform: "none",
              }}
            >
              {item.answer}
            </Button>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}

export default BinaryPreview;
