import React from "react";
import { Grid, FormControl, TextField, Autocomplete } from "@mui/material";

import { ErrorContainer } from "../../ComponentIndex";
import {isDropdownOperator, removeDuplicates} from "../../../utilities/utils";
import {
  dropDownOperatorOptions,
  operatorOptions
} from "../../../constants/consts";

export default function Rule({
  showErrorOnPage = false,
  name = "",
  isSubgroup = false,
  keys = [],
  id = "",
  label = "",
  inputType = "textfield",
  logicalOperator = "",
  valueOptions = [],
  value = "",
  onKeyChange = () => {},
  onOperatorChange = () => {
    if (logicalOperator === "NOT_HAVE") {
      value = "";
      valueOptions = []
    }
  },
  onValueChange = () => {},
}) {
  const valueIdName = `${isSubgroup ? "group" : "individual"}-${name.toLowerCase()}-value`;
  const comparatorIdName = `${isSubgroup ? "group" : "individual"}-${name.toLowerCase()}-comparator-input`;
  const parameterIdName = `${isSubgroup ? "group" : "individual"}-${name.toLowerCase()}-parameter-input`;

  return (
    <React.Fragment key={id}>
      {showErrorOnPage && (value === "" || label === "") && (
        <Grid item xs={12}>
          <ErrorContainer
            containerStyle={{ marginTop: "8px" }}
            errorMessage="Please ensure that every rule has a parameter and value."
          />
        </Grid>
      )}
      <Grid className="RuleParameter" item xs={8} md={4} sx={{ p: "0 10px 0 0" }}>
        <FormControl fullWidth>
          <Autocomplete
            data-testid={parameterIdName}
            disableClearable
            disablePortal
            isOptionEqualToValue={(option, value) => option.value === value.value || value === ""}
            id={parameterIdName}
            options={keys.sort((a, b) => a.label.localeCompare(b.label))}
            filterOptions={(options, { inputValue }) =>
              options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
            }
            value={label}
            renderInput={(params) => (
              <TextField error={showErrorOnPage && label === ""} {...params} label={`${name} Parameter *`} />
            )}
            onChange={onKeyChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4} md={2} sx={{ p: "0 5px 0 2px", mt: "2px" }}>
        <Autocomplete
          data-testid={comparatorIdName}
          disablePortal
          isOptionEqualToValue={(option, value) => option.value === value.value || value === ""}
          disableClearable
          id={comparatorIdName}
          options={inputType === "dropdown" ? dropDownOperatorOptions : operatorOptions}
          value={logicalOperator}
          onChange={onOperatorChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Grid>
      <Grid item xs={8} md={4} sx={{ pr: 1 }}>
        <FormControl fullWidth>
          {inputType === "dropdown" && isDropdownOperator(logicalOperator) ? (
            <Autocomplete
              sx={{ pr: "4px" }}
              data-testid={valueIdName}
              disableClearable
              disablePortal
              isOptionEqualToValue={(option, value) => option.value === value.value || value === ""}
              id={valueIdName}
              options={removeDuplicates(valueOptions).sort((a, b) => b.localeCompare(a))}
              filterOptions={(options, { inputValue }) =>
                options.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()))
              }
              value={value}
              onChange={(event, newValue) => {
                onValueChange(event, newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} error={showErrorOnPage && value === "" && logicalOperator !== "NOT_HAVE" } label={"Parameter *"} />
              )}
              disabled={logicalOperator === "NOT_HAVE"}
            />
          ) : (
            <TextField
              data-testid={valueIdName}
              id={valueIdName}
              aria-describedby="start-helper-text"
              name="value"
              label="Value *"
              value={value}
              error={showErrorOnPage && (value === "" && logicalOperator !== "NOT_HAVE")}
              onChange={(e) => {
                onValueChange(e, e.target.value);
              }}
              disabled={logicalOperator === "NOT_HAVE"}
            />
          )}
        </FormControl>
      </Grid>
    </React.Fragment>
  );
}
