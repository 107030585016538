import React, {useCallback, useContext, useEffect, useState} from "react";
import {Link as HrefLink, useNavigate} from "react-router-dom";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import EditModeContext from "../../contexts/EditModeContext";
import {publish, publishErrorMessage} from "../../events/events";
import useDeleteCampaign from "../../hooks/useDeleteCampaign";
import useColumnHeaders from "../../hooks/useColumnHeaders";
import {
  campaignFilterStatusMap,
  campaignStatusMap,
  tableTypePathMap
} from "../../constants/consts";
import {
  capitaliseWord,
  formatDate,
  formatLargeNumberWithCommas,
} from "../../utilities/utils";

import {ComponentGuard} from "../ComponentIndex";
import EditSVG from "../../assets/EditSVG";
import DeleteSVG from "../../assets/DeleteSVG";
import axios from "axios";
import useDebounce from "../../hooks/useDebounce";

export default function CampaignTable(props) {
  const [rows, setRows] = useState([]);
  const {
    deleteDialogOpen,
    deleteDialogTitle,
    deleteDialogDetail,
    showDeleteDialog,
    handleClose,
    deleteCampaign,
    handleDeleteReason,
    deleteReason,
    needsReason,
  } = useDeleteCampaign(props.path);

  const isArchive = props.isArchive,
      navigate = useNavigate(),
      [sortDirection, setSortDirection] = useState("desc"),
      [sortedAttribute, setSortedAttribute] = useState("createdDate"),
      [campaignNameSearch, setCampaignNameSearch] = useState(""),
      [selectedStatus, setSelectedStatus] = useState(""),
      [currentPage, setCurrentPage] = useState(1),
      [totalPages, setTotalPages] = useState(1),
      [page, setPage] = useState({}),
      {setEditMode} = useContext(EditModeContext),
      columnHeaders = useColumnHeaders(props.path),
      isTemplate = tableTypePathMap.TEMPLATE === props.path;

  const debouncedSearchTerm = useDebounce(campaignNameSearch, 750);

  const toggleFilter = (attribute) => {
    let newSortDirection;
    if (sortedAttribute === attribute) {
      newSortDirection = sortDirection === "asc" ? "desc" : "asc";
    } else {
      newSortDirection = "desc";
    }

    setSortDirection(newSortDirection);
    setSortedAttribute(attribute);
    setCurrentPage(1);
  }

  const handleStatusFilter = (event, value) => {
    setSelectedStatus(value);
    setCurrentPage(1);
  };

  const handleSearchInputChange = (searchValue) => {
    setCampaignNameSearch(searchValue);
    setCurrentPage(1);
  };

  const renderSortIcon = (attribute) => {
    if (attribute !== sortedAttribute) {
      return null;
    }

    return (
        <div style={{fontSize: "1.5em", display: "flex"}}>
          {sortDirection === "asc" ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
        </div>
    );
  };

  const renderUnselectedSortIcon = () => {
    return (
        <div style={{position: "relative", height: "30px"}}>
          <ArrowDropUpIcon
              sx={{fontSize: "1.5em", position: "absolute", top: 0}}/>
          <ArrowDropDownIcon
              sx={{fontSize: "1.5em", position: "absolute", bottom: 0}}/>
        </div>
    );
  };

  const isDeleteDisabled = (row) => {
    return row.name === window.defaultCampaign;
  };

  function closeNotifications() {
    publish("hideNotifications");
  }

  // size is handled by column.hide
  function formatCell(row, column) {
    let value = row[column.attribute];
    if (!!value) {
      if (column.isDate) {
        value = formatDate(value, "dd/MM/yyyy");

      } else if (column.isNumber) {
        value = formatLargeNumberWithCommas(value);

      } else if (column.isCurrency) {
        value = '$' + value;
      }
    }

    if (!!column.elementOverride) {
      return column.elementOverride(row, isArchive);
    }

    if (value == null) {
      value = 'n/a';
    } else {
      value = capitaliseWord(value.toLocaleString());
    }

    return (
        <TableCell key={`${column.id}-${row.id}`} name={`campaign-${column.id}`}
                   sx={column.sx}>{value}</TableCell>);
  }

  function addEditElement(row) {
    return (!isArchive && (
        <TableCell key={`edit-${row.id}`} sx={{textAlign: "right"}}>
          <ComponentGuard allowedRoles={isTemplate ? window.appRoles.editTemplates : window.appRoles.createCampaign}>
            {(!row.status || row.status === "DRAFT") && (
                <HrefLink
                    data-testid={`campaign-edit-${row.id}`}
                    to={`edit/${row.id}`}
                    state={{campaigns: rows}}
                    onClick={(event) => {
                      event.stopPropagation();
                      setEditMode(true);
                    }}
                >
                  <Button className="btn-edit"
                          sx={{minWidth: "45px", ml: 1, mr: 1}}>
                    <EditSVG/>
                  </Button>
                </HrefLink>
            )}
          </ComponentGuard>
          <ComponentGuard allowedRoles={isTemplate ? window.appRoles.deleteTemplates : window.appRoles.deleteCampaign}>
            {!isDeleteDisabled(row) && (
                <Button
                    data-testid={`campaign-delete-${row.id}`}
                    className="btn-delete"
                    sx={{minWidth: "45px", ml: 1, mr: 1}}
                    onClick={(event) => {
                      event.stopPropagation();
                      showDeleteDialog(row);
                    }}
                >
                  <DeleteSVG/>
                </Button>
            )}
          </ComponentGuard>
        </TableCell>
    ))
  }

  const rowData =
      rows.map((row) => {
        let formattedCells = columnHeaders.map((column) =>
            column.hide ? null : formatCell(row, column)
        );
        formattedCells.push(addEditElement(row));
        return (<TableRow
            data-testid={`campaign-${row.id}`}
            className={`selectable ${row.status.trim()}`}
            key={row.id}
            onClick={() => {
              navigate("" + row.id, {state: {campaigns: rows}});
            }}
        >
          {formattedCells}
        </TableRow>);
      });

  const fetchCampaigns = useCallback(() => {

    publish("showWaitSpinner", {toggle: true});

    let urlPath = props.path;
    if (tableTypePathMap.CAMPAIGN === props.path) {
      urlPath = '/v2/campaigns'
    }

    const searchParams = {
      page: currentPage - 1,
      size: 10
    };

    if (selectedStatus && !isArchive) {
      searchParams["status"] = selectedStatus;
    }
    if (isArchive) {
      searchParams["status"] = campaignStatusMap.DELETED;
    } else {
      searchParams["excludedStatus"] = campaignStatusMap.DELETED;
    }
    if (debouncedSearchTerm) {
      searchParams["campaignName"] = debouncedSearchTerm;
    }
    if (sortedAttribute) {
      searchParams["sort"] = sortedAttribute + "," + sortDirection;
    }

    let fullPath = window.promotion_url + urlPath;

    axios
    .get(fullPath, {
      params: searchParams
    })
    .then((response) => {
      if (tableTypePathMap.CAMPAIGN === props.path) {
        setTotalPages(response.data.totalPages);
        setPage(response.data.pageable);
        setRows(response.data.content);
      } else {
        setRows(response.data);
      }
      publish("showWaitSpinner", {toggle: false});
    })
    .catch((error) => {
      publish("showWaitSpinner", {toggle: false});
      const hasMessage = error.message !== undefined;
      let errorMessage;
      if (hasMessage) {
        errorMessage = error.message;
        console.error("Error fetching campaigns:", errorMessage);
      } else {
        errorMessage = "Unknown error while fetching campaigns";
        console.error("Error fetching campaigns:", error);
      }

      publishErrorMessage("Failed to fetch campaigns: " + errorMessage);
    });
  }, [props.path, currentPage, sortedAttribute, sortDirection, debouncedSearchTerm, selectedStatus, isArchive]);

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  return (
      <React.Fragment>
        <Dialog
            open={deleteDialogOpen}
            onClose={closeNotifications}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{deleteDialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText
                id="alert-dialog-description">{deleteDialogDetail}</DialogContentText>
            {needsReason && (
                <TextField
                    data-testid={`handle-delete-reason-input`}
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    label="Reason"
                    type="text"
                    fullWidth
                    autoComplete="off"
                    sx={{marginTop: "10px"}}
                    onChange={(event) => handleDeleteReason(event.target.value)}
                />
            )}
          </DialogContent>
          <DialogActions>
            <Button
                data-testid={`confirm-delete-button`}
                onClick={deleteCampaign}
                color="primary"
                disabled={needsReason && !deleteReason.trim()}
            >
              Yes
            </Button>
            <Button data-testid={`cancel-delete-button`} onClick={handleClose}
                    color="error">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Stack sx={{flexDirection: "row-reverse"}}>
          <Stack direction="row" spacing={5}>
            {!isArchive && columnHeaders.filter(
                header => header.attribute === 'status').length > 0 && (
                <Autocomplete
                    data-testid={`status-filter`}
                    id="status-filter"
                    options={campaignFilterStatusMap[props.path]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Filter by Status"
                            variant="outlined"
                            size="small"
                            InputProps={{
                              ...params.InputProps,
                              sx: {fontSize: 15},
                            }}
                            sx={{width: "180px", margin: "0"}}
                        />
                    )}
                    onChange={handleStatusFilter}
                />
            )}
            <TextField
                data-testid={`search-campaign`}
                id="search-input"
                label="Search Names"
                variant="outlined"
                size="small"
                value={campaignNameSearch}
                onChange={(event) => handleSearchInputChange(
                    event.target.value)}
                InputProps={{
                  sx: {fontSize: 15},
                }}
                sx={{width: "180px"}}
            />
          </Stack>
        </Stack>
          <Table size="small"
                 sx={{marginTop: 3, width: "100%", tableLayout: "fixed"}}>
            <TableHead sx={{background: `${window.theme.colors.header}`}}>
              <TableRow>
                {columnHeaders.map((column) =>
                    column.hide ? null : (
                        <TableCell
                            data-testid={`column-filter${column.attribute}`}
                            key={`${column.attribute}-key`}
                            onClick={() => toggleFilter(column.attribute)}
                            sx={{
                              cursor: "pointer",
                              fontWeight: "bold",
                              width: column.width,
                            }}
                        >
                          <div style={{display: "flex", alignItems: "center"}}>
                            {column.name}
                            {column.attribute === ""
                                ? null
                                : column.attribute === sortedAttribute
                                    ? renderSortIcon(column.attribute)
                                    : renderUnselectedSortIcon()}
                          </div>
                        </TableCell>
                    )
                )}
                {!isArchive && (
                    <TableCell
                        sx={{
                          width: "130px",
                        }}
                    />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows ? rowData : ""}
            </TableBody>
          </Table>
        <Stack sx={{ marginTop: 3 }} alignItems="center">
          <Pagination count={totalPages} page={currentPage} size={"large"}
                      onChange={(event, value) => setCurrentPage(value)}
                      disabled={!page}/>
        </Stack>
      </React.Fragment>
  );
}
