/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import { CircularProgress, Grid, Stack } from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import { CustomButton, FormikControl, Title } from "../../../../ComponentIndex";
import * as Yup from "yup";
import { setQuestionData } from "../../../../../store/actions/questionBank";
import draftjsToHtml from "draftjs-to-html";
import { connect } from "react-redux";
import { handleModalConfirmation } from "../../../../../store/actions/modal";

const AutoFillValue = ({ questionDetail }) => {
  const { setFieldValue } = useFormikContext();
  React.useEffect(() => {
    setFieldValue("dataQuestion", {
      title: questionDetail.data.title,
      banner_image: questionDetail.data.banner_image,
      question_type: questionDetail.data.question_type,
      information: questionDetail.data.information,
      show_feedback: questionDetail.data.show_feedback,
      free_text_feedback: questionDetail.data.free_text_feedback,
      options: null,
      is_submitted: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionDetail]);

  return null;
};

const InformationDetails = ({
  dispatch,
  question,
  formQuestion,
  modalConfirmation,
  handleQuestion,
  modalQuestion,
  questionDetail,
  isDataChanged,
  setIsDataChanged,
}) => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [value, setValue] = React.useState("");
  const [focused, setFocused] = React.useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const validationSchema = Yup.object({
    dataQuestion: Yup.object().shape({
      title: Yup.string().required("This is a required field"),
    }),
  });

  React.useEffect(() => {
    setModalType(modalConfirmation.type);
  }, [modalConfirmation]);

  useEffect(() => {
    // HTML content you want to set initially
    if (modalQuestion.type === "edit") {
      if (!isDataChanged) {
        const data = questionDetail?.data?.information;
        if (data) {
          convertText(data);
        }
      } else {
        convertText(formQuestion.information);
      }
    } else {
      const data = formQuestion?.information;
      if (data) {
        convertText(data);
      }
    }
  }, [questionDetail?.data?.information, formQuestion?.information]);
  function convertText(htmlContent) {
    const trimmedHtmlContent = htmlContent?.trim();
    // Convert HTML to ContentState
    const blocksFromHTML = convertFromHTML(trimmedHtmlContent);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    setValue(htmlContent);
    // Set editor state with the converted content
    setEditorState(EditorState.createWithContent(contentState));
  }

  function handleQuestionNextBack(nextOrBack) {
    handleQuestion(dispatch, {
      questionType: question.questionType,
      questionStep: nextOrBack,
    });
  }

  function handleModal(type, data) {
    setModalType(type);
    if (modalQuestion.type === "edit") {
      data = {
        id: modalQuestion.data.id,
        body: data,
      };
    }
    handleModalConfirmation(dispatch, {
      isOpen: true,
      type,
      data,
    });
  }

  function onChange(val) {
    setValue(val.blocks[0].text);
  }

  const updateInformation = async (state) => {
    await setEditorState(state);
  };

  return (
    <>
      <Title>Question Details</Title>
      {questionDetail.isLoading &&
        !questionDetail.data &&
        modalQuestion.type === "edit" && <CircularProgress />}
      {((!questionDetail.isLoading &&
        questionDetail.data &&
        Object.keys(questionDetail.data).length) ||
        modalQuestion.type === "create") && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                dataQuestion: formQuestion,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                setIsSubmitted(true);
                if (!value) return;

                const newData = {
                  ...values.dataQuestion,
                  options: null,
                  free_text_feedback: null,
                  information: draftjsToHtml(
                    convertToRaw(editorState.getCurrentContent())
                  ),
                };

                if (modalType === "draft") {
                  handleModal("draft", newData);
                } else {
                  handleQuestionNextBack(question.questionStep + 1);
                }
                setIsDataChanged(true);
                setQuestionData(dispatch, newData);
              }}
            >
              {(props) => (
                <Form style={{ width: "100%" }}>
                  <Grid container wrap="wrap" direction={"column"}>
                    <FormikControl
                      control="input"
                      label={[
                        "Information Title",
                        `(${
                          props.values?.dataQuestion?.title?.length || 0
                        }/45)`,
                      ]}
                      id="information_title"
                      name="dataQuestion.title"
                      placeholder="Type information title"
                      className="basic-input"
                      inputProps={{ maxLength: 45 }}
                    />
                  </Grid>
                  <Stack mt={2.5}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <h5
                        style={{
                          color: focused ? "#00A45F" : "#808191",
                          fontSize: 15,
                          fontWeight: 500,
                        }}
                      >
                        Information
                      </h5>
                    </Stack>
                    <Editor
                      onFocus={onFocus}
                      onBlur={onBlur}
                      editorState={editorState}
                      onEditorStateChange={updateInformation}
                      onChange={onChange}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                    />

                    {!value && isSubmitted && (
                      <span style={{ color: "red" }}>
                        This is a required field
                      </span>
                    )}
                  </Stack>
                  <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
                    <CustomButton
                      className={"custom_button white"}
                      style={{ width: 100 }}
                      disabled={modalQuestion.type === "edit"}
                      onHandleClick={() =>
                        modalQuestion.type === "edit" ? {} : handleQuestionNextBack(question.questionStep - 1)
                      }
                    >
                      <span>Back</span>
                    </CustomButton>
                    <CustomButton
                      className={"custom_button white"}
                      style={{ width: 133 }}
                      type="submit"
                      onHandleClick={() => setModalType("draft")}
                    >
                      <span>Save to Drafts</span>
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      className={"custom_button black"}
                    >
                      <span>Continue</span>
                    </CustomButton>
                  </Stack>
                  {modalQuestion.type === "edit" &&
                    !isDataChanged &&
                    !questionDetail.isLoading &&
                    questionDetail.data &&
                    Object.keys(questionDetail.data).length && (
                      <AutoFillValue questionDetail={questionDetail} />
                    )}
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    questionDetail: state.questionBank.questionDetail,
    formQuestion: state.questionBank.questionData,
    question: state.questionBank.checkQuestion,
    modalConfirmation: state.modal.modalConfirmation,
    modalQuestion: state.modal.modalQuestion,
  };
};
export default connect(mapStatetoProps, null)(InformationDetails);
