import React, {useContext, useEffect, useState} from "react";
import {msalConfig} from "../../config/AuthConfig";
//import { AccountInfo } from "@azure/msal-browser";
import {msalInstance} from "../../lib/MsalAuth";
import Typography from "@mui/material/Typography";
import {AuthContext} from "react-oauth2-code-pkce";
import {TTokenData} from "react-oauth2-code-pkce/dist/types";

const ComponentGuard = ({ allowedRoles, children, alternateText }) => {
  const [isAuthorized, setIsAuthorized] = useState(window.disableSso || false);
  const { tokenData } : TTokenData = useContext(AuthContext);
  const onLoad = async () => {
    if (window.disableSso) {
      return;
    }

    if (window.authorizationFlow) {
      if (tokenData) {
        setIsAuthorized(tokenData.roles.some((role) => allowedRoles.includes(role.substring(5))));
      }
    } else {
      const currentAccount = msalInstance.getActiveAccount();
      if (currentAccount) {
        if (currentAccount.tenantId === msalConfig.auth.tenantId) {
          const idTokenClaims = currentAccount.idTokenClaims;
          setIsAuthorized(
              idTokenClaims.roles.some((role) => allowedRoles.includes(role)));
        }
      }
    }
  };
  useEffect(() => {
    onLoad();
  });

  if (isAuthorized) {
    return <>{children}</>;
  } else {
    return <Typography>{alternateText}</Typography>;
  }
};

export default ComponentGuard;
