import { React } from "react";
import { Grid, Paper, Typography } from "@mui/material";

import { CONSTANTS, exclusionRuleStatuses } from "../../constants/consts";

import {
  RuleFormatter,
  EligibilityRadioGroup,
  ExclusionRuleFormatter,
  DownloadUsers,
  ErrorContainer,
} from "../ComponentIndex";

export default function EligibilityCriteria(props) {
  const formValues = props.formValues,
    setFormValues = props.setFormValues,
    campaignTypes = props.campaignTypes,
    campaigns = props.campaigns,
    ruleData = props.ruleData,
    noRulesAdded = props.noRulesAdded,
    showErrorOnPage = props.showErrorOnPage,
    masterdata = props.masterdata,
    isTemplate = props.isTemplate,
    ruleConfig = formValues.ruleConfig;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleExclusionChange = (newCriteria) => {
    setFormValues({
      ...formValues,
      exclusionCriteria: newCriteria,
    });
  };

  let assignmentRuleFormatter;

  let campaignType = campaignTypes.find(type => type.name === formValues.campaignType);
  if (!campaignType?.assignmentCriteriaEnabled) {
    assignmentRuleFormatter =
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3>Customers must meet these criteria to earn the value of the
              coupon</h3>
          </Grid>
          <Paper sx={{
            background: `${window.theme.colors.secondary}`,
            p: 2,
            borderRadius: 2,
            mb: 2,
            ml: 2
          }}>
            <Typography variant="p">
              Assignment criteria are disabled for this campaign type
            </Typography>
          </Paper>
        </Grid>
    ;
  } else {
    assignmentRuleFormatter = <RuleFormatter
      header="Customers must meet these criteria to earn the value of the coupon"
      content="To know who can earn the coupon, you can set up rules or groups, representing a set of rules."
      ruleName="Assignment"
      ruleKeys={masterdata}
      ruleWrapper={formValues.assignmentCriteria}
      onChange={(event, newCriteria) => {
        setFormValues({
          ...formValues,
          assignmentCriteria: newCriteria,
        });
      }}
      showErrorOnPage={showErrorOnPage}
    />;

  }

  return (
    <Grid container spacing={2} sx={{ p: "16px 0" }}>
      <Grid item xs={12}>
        <Typography variant="h3" color="primary">
          Eligibility
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RuleFormatter
          header="Include following customers for offer:"
          content="To know who is eligible for the offer, you can set up rules or groups, representing a set of rules."
          ruleName="Eligibility"
          ruleKeys={masterdata}
          ruleWrapper={formValues.eligibilityCriteria}
          onChange={(event, newCriteria) => {
            setFormValues({
              ...formValues,
              eligibilityCriteria: newCriteria,
            });
          }}
          showErrorOnPage={showErrorOnPage}
        />
      </Grid>

      <Grid item xs={12} sx={{ mb: 2 }}>
        <h3>Exclude following customers from offer:</h3>
        <Paper sx={{ p: 2, borderRadius: 2, background: `${window.theme.colors.secondary}` }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, borderRadius: 2 }}>
                <p style={{ paddingLeft: "2px" }}>
                  Exclude customers with a certain coupon status and in a certain campaign.
                </p>
                <ExclusionRuleFormatter
                  onChange={handleExclusionChange}
                  criteria={formValues.exclusionCriteria}
                  withDate={false}
                  valueKey="exclusionCriteria"
                  objects={[
                    { name: CONSTANTS.COUPON_STATUS, list: exclusionRuleStatuses.map((status) => ({ name: status })) },
                    { name: CONSTANTS.CAMPAIGN, list: campaigns },
                  ]}
                  showErrorOnPage={showErrorOnPage}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, borderRadius: 2 }}>
                <p style={{ paddingLeft: "2px" }}>
                  Exclude customers with a certain coupon status in any campaign type, since a certain date.
                </p>
                <ExclusionRuleFormatter
                  onChange={handleExclusionChange}
                  criteria={formValues.exclusionCriteria}
                  withDate={true}
                  startDate={formValues.startDate}
                  valueKey="exclusionCriteria"
                  objects={[
                    { name: CONSTANTS.COUPON_STATUS, list: exclusionRuleStatuses.map((status) => ({ name: status })) },
                    { name: CONSTANTS.CAMPAIGN_TYPE, list: campaignTypes },
                  ]}
                  showErrorOnPage={showErrorOnPage}
                />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <EligibilityRadioGroup
        ruleData={ruleData}
        onSelect={handleInputChange}
        valueKey="ruleConfig"
        value={ruleConfig}
      />

      <Grid item xs={12} sx={{ mb: 2 }}>
        {assignmentRuleFormatter}
      </Grid>

      <DownloadUsers formValues={formValues} setFormValues={setFormValues} showCalculate={true} preventSave={false} />
      {noRulesAdded && !isTemplate && (
        <Grid item xs={12}>
          <ErrorContainer
            styleOverrides={{ fontSize: "1rem", marginLeft: "2px" }}
            errorMessage="Please add an eligibility rule before continuing."
          />
        </Grid>
      )}
    </Grid>
  );
}
