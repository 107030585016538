import * as React from "react";
import { Link } from "react-router-dom";
import { AppBar, Box, Toolbar, Button } from "@mui/material";
import { LogInOutButton, ComponentGuard } from "../ComponentIndex";
import SettingsSVG from "../../assets/SettingsSVG";

const createNavLink = (testId, href, label) => {
  return (
    <Button
      data-testid={testId}
      size="large"
      variant="h3"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{
        mr: 2,
        fontSize: "19px",
      }}
      component={Link}
      to={href}
    >
      {label}
    </Button>
  );
};

export default function ButtonAppBar() {
  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          {createNavLink("home-button", "/", "Campaigns")}
          <ComponentGuard allowedRoles={window.appRoles.viewTemplates}>
            {createNavLink("campaign-template-button", "/campaigns/templates",
                "Templates")}
          </ComponentGuard>
          <ComponentGuard allowedRoles={window.appRoles.viewSurvey}>
            {createNavLink("question-button", "/question-bank", "Question Bank")}
            {createNavLink("survey-button", "/survey", "Survey")}
          </ComponentGuard>
          {createNavLink("archive-button", "/campaigns/archive", "Archive")}
          <div style={{flexGrow: 1}}></div>
          <ComponentGuard allowedRoles={window.appRoles.admin}>
            {createNavLink("settings", "/settings", <SettingsSVG/>)}
          </ComponentGuard>
          <LogInOutButton disableLogin={true}/>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
