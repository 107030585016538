import { Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { BannerImage2 } from "../../../../../assets";
import {
  ComponentUploadImage,
  CustomButton,
  FileUploadRequirement,
  Title,
} from "../../../../ComponentIndex";
import { useFormik } from "formik";
import {
  questionUploadImage,
  setQuestionData,
} from "../../../../../store/actions/questionBank";
import { connect } from "react-redux";
import * as Yup from "yup";
import { handleModalConfirmation } from "../../../../../store/actions/modal";
import { publishErrorMessage } from "../../../../../events/events";

function InformationImages({
  dispatch,
  question,
  formQuestion,
  modalConfirmation,
  handleQuestion,
  modalQuestion,
}) {
  const [isUploaded, setIsUploaded] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const uploadRefs = React.useRef(null);

  const validationSchema = Yup.object({
    dataQuestion: Yup.object().shape({
      banner_image: Yup.mixed()
        .required("*This is a required field")
        .test(
          "fileFormat",
          "*The file format could not be uploaded. Please upload the image with Dynamic image, GIF, PNG, JPG, or JPEG format",
          (value) => {
            if (typeof value === "object" && value) {
              const supportedFormats = ["jpg", "jpeg", "png", "gif"]; //allowed file
              return supportedFormats.includes(value?.name.split(".").pop());
            } else {
              if (value) return true;
              return false;
            }
          }
        )
        .test(
          "fileAspectRatio",
          "*The uploaded file exceeds maximum upload ratio. Please upload image ratio under 327 x 109 pixels",
          async (value) => {
            let img;
            if (typeof value === "object" && value) {
              return new Promise((resolve) => {
                if (value) {
                  img = new Image();
                  var objectUrl = URL.createObjectURL(value);
                  img.onload = function () {
                    // 327 x 109 pixels for banner image
                    if (+this.width > 327 || +this.height > 109) {
                      resolve(false);
                    } else {
                      resolve(true);
                    }
                  };
                  img.src = objectUrl;
                } else {
                  resolve(false);
                }
              }).then((result) => {
                return result;
              });
            } else {
              if (value) return true;
              return false;
            }
          }
        )
        .test(
          "fileSize",
          "*The uploaded file exceeds maximum upload size. Please upload image size under 150KB",
          (value) => {
            if (typeof value === "object" && value) {
              return value.size <= 150000; //max file size is 150KB
            }
            return true;
          }
        ),
    }),
  });

  function uploadImage(image, fileType) {
    const response = questionUploadImage({
      fileName: image?.name,
      fileType,
      body: new Blob([image]),
    });

    return response;
  }

  const formik = useFormik({
    initialValues: {
      dataQuestion: formQuestion,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        if (typeof values.dataQuestion.banner_image !== "string") {
          const response1 = await uploadImage(
            values.dataQuestion.banner_image,
            "question_banner"
          );
          values.dataQuestion.banner_image = response1.file_name;
        }

        setIsLoading(false);
        if (modalType === "draft") {
          handleModal("draft", values.dataQuestion);
        } else {
          handleQuestionNextBack(question.questionStep + 1);
        }
        setQuestionData(dispatch, values.dataQuestion);
      } catch (error) {
        publishErrorMessage(error?.response?.data?.title);
        setIsLoading(false);
      }
    },
  });

  React.useEffect(() => {
    setModalType(modalConfirmation.type);
  }, [modalConfirmation]);

  React.useEffect(() => {
    // set Value IsUploaded tobe true or false base on image
    setIsUploaded(!!formQuestion.banner_image);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formQuestion]);

  function handleQuestionNextBack(nextOrBack) {
    handleQuestion(dispatch, {
      questionType: question.questionType,
      questionStep: nextOrBack,
    });
  }
  function handleModal(type, data) {
    setModalType(type);
    let newData = {}
    if (modalQuestion.type === "edit") {
      newData = {
        id: modalQuestion.data.id,
        body: data,
      };
    } else {
      newData = data
    }
    handleModalConfirmation(dispatch, {
      isOpen: true,
      type,
      data: newData,
    });
  }

  return (
    <>
      <Grid container spacing={1}>
        <Title> Upload image files </Title>
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={4} sx={{ mb: 3 }}>
              <Typography variant="body1">
                Note: Always adhere to the guidelines to ensure the survey is
                visually appealing, and provides a seamless experience for the
                users.
              </Typography>
              <Typography variant="body1">
                The banner image serves to visually represent or complement the
                question, enhancing user engagement and understanding.
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="h7" mb={1.5}>
                Banner Image
              </Typography>
              <ComponentUploadImage
                dataImage={formik.values?.dataQuestion?.banner_image}
                defaultImage={BannerImage2}
                isDisabled={isUploaded}
                uploadImageRef={uploadRefs}
                isLoading={isLoading}
                isError={false}
                name="dataQuestion.banner_image"
                onHandleClick={() => {
                  setIsUploaded(false);
                  uploadRefs.current.value = null;
                  formik.setFieldValue("dataQuestion.banner_image", "");
                }}
                onHandleChange={(e) => {
                  if (e.target.files) {
                    setIsUploaded(true);

                    formik.setFieldValue(
                      "dataQuestion.banner_image",
                      e.target.files[0]
                    );
                  }
                }}
              />
            </Stack>
            <FileUploadRequirement dimension="327 x 109" size="150" />
            {formik.touched && formik.errors?.dataQuestion?.banner_image && (
              <span style={{ color: "red" }}>
                {formik.errors?.dataQuestion?.banner_image}
              </span>
            )}
            <Divider sx={{ mt: 2, mb: 2, borderWidth: 1 }} />

            <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
              <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
                <CustomButton
                  className={"custom_button white"}
                  style={{ minWidth: 100 }}
                  disabled={isLoading}
                  onHandleClick={() =>
                    handleQuestionNextBack(question.questionStep - 1)
                  }
                >
                  <span>Back</span>
                </CustomButton>
                <CustomButton
                  className={"custom_button white"}
                  type="submit"
                  style={{ minWidth: 133 }}
                  disabled={isLoading}
                  onHandleClick={() => setModalType("draft")}
                >
                  <span>Save to Drafts</span>
                </CustomButton>
                <CustomButton
                  type="submit"
                  disabled={isLoading}
                  onHandleClick={() => setModalType("submit")}
                  className={"custom_button black"}
                >
                  <span>Continue</span>
                </CustomButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    formQuestion: state.questionBank.questionData,
    question: state.questionBank.checkQuestion,
    modalConfirmation: state.modal.modalConfirmation,
    modalQuestion: state.modal.modalQuestion,
  };
};
export default connect(mapStatetoProps, null)(InformationImages);
