import { Grid } from "@mui/material";
import React from "react";

function InformationPreview({ values }) {
  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12}>
        <p
          dangerouslySetInnerHTML={{
            __html: values.information,
          }}
          style={{ color: "#FFF" }}
        ></p>
      </Grid>
    </Grid>
  );
}

export default InformationPreview;
