import { handleQuestion } from "../../../store/actions/questionBank";
import {
  BinaryQuestion,
  BinaryDetails,
  BinaryImages,
  BinaryPreview,
  SingleSelectQuestion,
  SingleSelectDetails,
  SingleSelectImages,
  SingleSelectPreview,
  MultiSelectQuestion,
  MultiSelectDetails,
  MultiSelectImages,
  MultiSelectPreview,
  FreeTextQuestion,
  FreeTextDetails,
  FreeTextImages,
  FreeTextPreview,
  InformationQuestion,
  InformationDetails,
  InformationImages,
  InformationPreview,
} from "../../ComponentIndex";

const TemplateQuestionTypes = ({
  dispatch,
  isDataChanged,
  setIsDataChanged,
}) => {
  const arrayList = [
    [
      0,
      0,
      <BinaryQuestion handleQuestion={handleQuestion} dispatch={dispatch} />,
    ],
    [
      1,
      0,
      <BinaryDetails
        handleQuestion={handleQuestion}
        dispatch={dispatch}
        isDataChanged={isDataChanged}
        setIsDataChanged={setIsDataChanged}
      />,
    ],
    [
      2,
      0,
      <BinaryImages handleQuestion={handleQuestion} dispatch={dispatch} />,
    ],
    [
      3,
      0,
      <BinaryPreview handleQuestion={handleQuestion} dispatch={dispatch} />,
    ],
    [
      0,
      1,
      <SingleSelectQuestion
        handleQuestion={handleQuestion}
        dispatch={dispatch}
      />,
    ],
    [
      1,
      1,
      <SingleSelectDetails
        handleQuestion={handleQuestion}
        dispatch={dispatch}
        isDataChanged={isDataChanged}
        setIsDataChanged={setIsDataChanged}
      />,
    ],
    [
      2,
      1,
      <SingleSelectImages
        handleQuestion={handleQuestion}
        dispatch={dispatch}
      />,
    ],
    [
      3,
      1,
      <SingleSelectPreview
        handleQuestion={handleQuestion}
        dispatch={dispatch}
      />,
    ],
    [
      0,
      2,
      <MultiSelectQuestion
        handleQuestion={handleQuestion}
        dispatch={dispatch}
      />,
    ],
    [
      1,
      2,
      <MultiSelectDetails
        handleQuestion={handleQuestion}
        dispatch={dispatch}
        isDataChanged={isDataChanged}
        setIsDataChanged={setIsDataChanged}
      />,
    ],
    [
      2,
      2,
      <MultiSelectImages handleQuestion={handleQuestion} dispatch={dispatch} />,
    ],
    [
      3,
      2,
      <MultiSelectPreview
        handleQuestion={handleQuestion}
        dispatch={dispatch}
      />,
    ],
    [
      0,
      3,
      <FreeTextQuestion handleQuestion={handleQuestion} dispatch={dispatch} />,
    ],
    [
      1,
      3,
      <FreeTextDetails
        handleQuestion={handleQuestion}
        dispatch={dispatch}
        isDataChanged={isDataChanged}
        setIsDataChanged={setIsDataChanged}
      />,
    ],
    [
      2,
      3,
      <FreeTextImages handleQuestion={handleQuestion} dispatch={dispatch} />,
    ],
    [
      3,
      3,
      <FreeTextPreview handleQuestion={handleQuestion} dispatch={dispatch} />,
    ],
    [
      0,
      4,
      <InformationQuestion
        handleQuestion={handleQuestion}
        dispatch={dispatch}
      />,
    ],
    [
      1,
      4,
      <InformationDetails
        handleQuestion={handleQuestion}
        dispatch={dispatch}
        isDataChanged={isDataChanged}
        setIsDataChanged={setIsDataChanged}
      />,
    ],
    [
      2,
      4,
      <InformationImages handleQuestion={handleQuestion} dispatch={dispatch} />,
    ],
    [
      3,
      4,
      <InformationPreview
        handleQuestion={handleQuestion}
        dispatch={dispatch}
      />,
    ],
  ];
  return arrayList;
};
export default TemplateQuestionTypes;
