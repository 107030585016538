import { useField } from "formik";
import PropTypes from "prop-types";
import { Grid, OutlinedInput, Stack } from "@mui/material";
import TextError from "./TextError";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import React from "react";

function isFocus(OK) {
  if (OK) {
    return {
      color: "#00A45F",
    };
  }
  return {
    color: "#808191",
  };
}
function renderStyle(icondrag) {
  if (icondrag) {
    return {
      ml: 5,
    };
  }
}
function renderLabel(label, icondrag) {
  if (typeof label === "object") {
    if (icondrag) {
      return (
        <>
          <div>
            {icondrag}
            <span>{label[0]}</span>
          </div>
          <span>{label[1]}</span>
        </>
      );
    }
  } else {
    return <span>{label}</span>;
  }
}
const InputWithIcon = (props) => {
  function MyFormHelperText({ label, icondrag, icondragdelete }) {
    const { focused } = useFormControl() || {};
    const helperText = React.useMemo(() => {
      return (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={isFocus(focused, props)}
          fontSize={15}
          fontWeight={500}
          marginRight={icondragdelete > 2 ? 5 : 0}
        >
          {renderLabel(label, icondrag)}
        </Stack>
      );
    }, [focused, label, icondrag, icondragdelete]);

    return <div>{helperText}</div>;
  }

  const { name, label, icondragdelete, icondrag, ...rest } = props;
  const [field] = useField(name);
  return (
    <Grid container direction="column">
      <FormControl>
        <MyFormHelperText
          label={label}
          icondrag={icondrag}
          icondragdelete={icondragdelete}
        />

        <OutlinedInput
          {...field}
          {...rest}
          sx={renderStyle(icondrag)}
        />
        <TextError name={name} icondrag={icondrag} />
      </FormControl>
    </Grid>
  );
};

InputWithIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputWithIcon;
