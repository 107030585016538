import { useEffect } from "react";
import { CampaignList } from "../components/ComponentIndex";

export default function Dashboard() {
  useEffect(() => {
    document.title = 'Campaign Dashboard';
  }, []);

  return <CampaignList title="Campaigns" isArchive={false} />;
}
