import React from "react";
import { Grid, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";

import { getToggleButtonStyle } from "../../utilities/utils";

export default function CampaignTypeToggle({ campaignType, setCampaignType }) {
  const campaignActive = campaignType?.active;
  const genericCampaignActive = !!campaignType?.tradeinIsGeneric;

  const createToggleButtonStyles = (property, value) => {
    const isActive = !!campaignType?.[property] === value;
    return getToggleButtonStyle(isActive, 0, "-2px", "35px");
  };

  const handleSetCampaignActive = (property, newOption) => {
    if (campaignType[property] === newOption || newOption === null) return;
    setCampaignType((prevTypes) => {
      const updatedTypes = [...prevTypes];
      const index = updatedTypes.findIndex((type) => type.id === campaignType.id);
      if (index !== -1) {
        updatedTypes[index][property] = newOption;
      }
      return updatedTypes;
    });
  };

  return (
    <>
      <Grid container key={`${campaignType.name}-type`} sx={{ mb: 2, alignItems: "center" }}>
        <Grid item xs={5}>
          <span>{campaignType.name}</span>
        </Grid>
        <Grid item xs={2}>
          <ButtonGroup
            campaignActive={campaignActive}
            handleSetCampaignActive={(newOption) => handleSetCampaignActive("active", newOption)}
            activeStyle={createToggleButtonStyles("active", true)}
            inactiveStyle={createToggleButtonStyles("active", false)}
          ></ButtonGroup>
        </Grid>
        <Grid item xs={2}>
          <Typography
            sx={{ fontWeight: 600 }}
            className={`status status-${campaignActive ? "ACTIVE" : "EXPIRED"}`}
            variant="body2"
          >
            {campaignActive ? "ACTIVE" : "INACTIVE"}
          </Typography>
        </Grid>
      </Grid>
      {campaignType.name === "Trade-in" && campaignActive && (
        <Grid container sx={{ mb: 2, alignItems: "center" }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={4}>
            <span>Trade-in generic</span>
          </Grid>
          <Grid item xs={2}>
            <ButtonGroup
              campaignActive={genericCampaignActive}
              handleSetCampaignActive={(newOption) => handleSetCampaignActive("tradeinIsGeneric", newOption)}
              activeStyle={createToggleButtonStyles("tradeinIsGeneric", true)}
              inactiveStyle={createToggleButtonStyles("tradeinIsGeneric", false)}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{ fontWeight: 600 }}
              className={`status status-${genericCampaignActive ? "ACTIVE" : "EXPIRED"}`}
              variant="body2"
            >
              {genericCampaignActive ? "ACTIVE" : "INACTIVE"}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

function ButtonGroup({ campaignActive, handleSetCampaignActive, activeStyle, inactiveStyle }) {
  return (
    <ToggleButtonGroup
      sx={{
        borderRadius: "50px",
        border: `solid 3px ${campaignActive ? window.theme.colors.primary : window.theme.colors.error}`,
        height: "30px",
        width: "65px",
      }}
      value={campaignActive}
      exclusive
      color="primary"
      onChange={() => handleSetCampaignActive(!campaignActive)}
      aria-label="Campaign Type Toggle"
    >
      <ToggleButton sx={activeStyle} value={true}>
        &nbsp;
      </ToggleButton>
      <ToggleButton
        sx={{
          ...inactiveStyle,
          border: `${campaignActive ? "initial" : "2px solid rgba(0,0,0,0) !important"}`,
          background: `${campaignActive ? "initial" : window.theme.colors.error} !important`,
        }}
        value={false}
      >
        &nbsp;
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
