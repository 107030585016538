import React from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { BannerImage2 } from "../../../../../assets";
import {
  ComponentUploadImage,
  CustomButton,
  Title,
} from "../../../../ComponentIndex";
import { Form, Formik } from "formik";
import {
  questionUploadImage,
  setQuestionData,
} from "../../../../../store/actions/questionBank";
import { connect } from "react-redux";
import * as Yup from "yup";
import { handleModalConfirmation } from "../../../../../store/actions/modal";
import { publishErrorMessage } from "../../../../../events/events";

const styleAnswer = {
  backgroundColor: "#D9D9D9",
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: 1,
  height: 57,
  borderRadius: 2,
  paddingLeft: 1.5,
  marginBottom: 2,
};

function BinaryImages({
  dispatch,
  question,
  formQuestion,
  modalConfirmation,
  handleQuestion,
  modalQuestion,
}) {
  const [isUploaded, setIsUploaded] = React.useState(Array(3).fill(false));
  // const [isResponse, setIsResponse] = React.useState(true);
  const [modalType, setModalType] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const uploadRefs = React.useRef(
    Array.from({ length: 3 }, () => React.createRef())
  );
  const targetScroll = React.useRef(null);

  const validationSchema = Yup.object({
    dataQuestion: Yup.object().shape({
      banner_image: Yup.mixed()
        .required("*This is a required field")
        .test(
          "fileFormat",
          "*The file format could not be uploaded. Please upload the image with Dynamic image, GIF, PNG, JPG, or JPEG format",
          (value) => {
            if (typeof value === "object" && value) {
              const supportedFormats = ["jpg", "jpeg", "png", "gif"]; //allowed file
              return supportedFormats.includes(value?.name.split(".").pop());
            } else {
              if (value) return true;
              return false;
            }
          }
        )
        .test(
          "fileAspectRatio",
          "*The uploaded file exceeds maximum upload ratio. Please upload image ratio under 327 x 109 pixels",
          async (value) => {
            let img;
            if (typeof value === "object" && value) {
              return new Promise((resolve) => {
                if (value) {
                  img = new Image();
                  var objectUrl = URL.createObjectURL(value);
                  img.onload = function () {
                    // 327 x 109 pixels for banner image
                    if (+this.width > 327 || +this.height > 109) {
                      resolve(false);
                    } else {
                      resolve(true);
                    }
                  };
                  img.src = objectUrl;
                } else {
                  resolve(false);
                }
              }).then((result) => {
                return result;
              });
            } else {
              if (value) return true;
              return false;
            }
          }
        )
        .test(
          "fileSize",
          "*The uploaded file exceeds maximum upload size. Please upload image size under 150KB",
          (value) => {
            if (typeof value === "object" && value) {
              return value.size <= 150000; //max file size is 150KB
            }
            return true;
          }
        ),
      options: Yup.array().of(
        Yup.object().shape({
          image: Yup.mixed()
            .required("*This is a required field")
            .test(
              "fileFormat",
              "*The file format could not be uploaded. Please upload the image with Dynamic image, GIF, PNG, JPG, or JPEG format",
              (value) => {
                if (typeof value === "object" && value) {
                  const supportedFormats = ["jpg", "jpeg", "png", "gif"]; //allowed file
                  return supportedFormats.includes(
                    value?.name.split(".").pop()
                  );
                } else {
                  if (value) return true;
                  return false;
                }
              }
            )
            .test(
              "fileAspectRatio",
              "*The uploaded file exceeds maximum upload ratio. Please upload image ratio under 484 x 484 pixels",
              async (value) => {
                let img;
                if (typeof value === "object" && value) {
                  return new Promise((resolve) => {
                    if (value) {
                      img = new Image();
                      var objectUrl = URL.createObjectURL(value);
                      img.onload = function () {
                        // 484 x 484 pixels for banner image
                        if (+this.width > 484 || +this.height > 484) {
                          resolve(false);
                        } else {
                          resolve(true);
                        }
                      };
                      img.src = objectUrl;
                    } else {
                      resolve(false);
                    }
                  }).then((result) => {
                    return result;
                  });
                } else {
                  if (value) return true;
                  return false;
                }
              }
            )
            .test(
              "fileSize",
              "*The uploaded file exceeds maximum upload size. Please upload image size under 150KB",
              (value) => {
                if (typeof value === "object" && value) {
                  return value.size <= 150000; //max file size is 150KB
                } else {
                  if (value) return true;
                  return false;
                }
              }
            ),
        })
      ),
    }),
  });

  React.useEffect(() => {
    targetScroll.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  React.useEffect(() => {
    setModalType(modalConfirmation.type);
  }, [modalConfirmation]);

  React.useEffect(() => {
    // set Value IsUploaded tobe true or false base on image
    const newIsUpload = [...isUploaded];
    newIsUpload[0] = !!formQuestion.banner_image;
    setIsUploaded(newIsUpload);

    if (formQuestion?.options?.length > 0) {
      formQuestion?.options?.forEach((item, i) => {
        newIsUpload[i + 1] = !!item.image;
        setIsUploaded(newIsUpload);
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formQuestion]);

  // function handleChange(ev) {
  //   setIsResponse(ev.target.checked);
  // }
  function handleQuestionNextBack(nextOrBack) {
    handleQuestion(dispatch, {
      questionType: question.questionType,
      questionStep: nextOrBack,
    });
  }
  function handleModal(type, data) {
    setModalType(type);
    let newData = {}
    if (modalQuestion.type === "edit") {
      newData = {
        id: modalQuestion.data.id,
        body: data,
      };
    } else {
      newData = data
    }
    handleModalConfirmation(dispatch, {
      isOpen: true,
      type,
      data: newData,
    });
  }

  function uploadImage(image, fileType) {
    const response = questionUploadImage({
      fileName: image?.name,
      fileType,
      body: new Blob([image]),
    });

    return response;
  }
  return (
    <>
      <Grid container spacing={1} ref={targetScroll}>
        <Title> Upload image files </Title>
        <Formik
          initialValues={{
            dataQuestion: formQuestion,
            feedback: true,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setIsLoading(true);
            try {
              if (typeof values.dataQuestion.banner_image !== "string") {
                const response1 = await uploadImage(
                  values.dataQuestion.banner_image,
                  "question_banner"
                );
                values.dataQuestion.banner_image = response1.file_name;
              }

              const mappingDataOptions = values.dataQuestion.options.map(async item => {
                if (typeof item.image !== "string") {
                  const response2 = await uploadImage(
                    item.image,
                    "question_option_image"
                  );
                  return response2.file_name
                }
                return item.image
              })

              const getResult = await Promise.all(mappingDataOptions)
              getResult.forEach((item, i) => {
                values.dataQuestion.options[i].image = item
              })

              setIsLoading(false);
              if (modalType === "draft") {
                handleModal("draft", values.dataQuestion);
              } else {
                handleQuestionNextBack(question.questionStep + 1);
              }
              setQuestionData(dispatch, values.dataQuestion);
            } catch (error) {
              publishErrorMessage(error?.response?.data?.title);
              setIsLoading(false);
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid item xs={12}>
                <Stack spacing={4} sx={{ mb: 3 }}>
                  <Typography variant="body1">
                    Note: Always adhere to the guidelines to ensure the survey
                    is visually appealing, and provides a seamless experience
                    for the users.
                  </Typography>
                  <Typography variant="body1">
                    The banner image serves to visually represent or complement
                    the question, enhancing user engagement and understanding.
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h7" mb={1.5}>
                    Banner Image
                  </Typography>
                  <ComponentUploadImage
                    dataImage={values?.dataQuestion?.banner_image}
                    defaultImage={BannerImage2}
                    isDisabled={isUploaded[0]}
                    uploadImageRef={uploadRefs.current[0]}
                    isLoading={isLoading}
                    isError={true}
                    dimension="327 x 109"
                    size="150"
                    name="dataQuestion.banner_image"
                    onHandleClick={(e) => {
                      const newIsUpload = [...isUploaded];
                      newIsUpload[0] = false;
                      setIsUploaded(newIsUpload);
                      uploadRefs.current[0].current.value = null;
                      setFieldValue("dataQuestion.banner_image", "");
                    }}
                    onHandleChange={(e) => {
                      if (e.target.files) {
                        const newIsUpload = [...isUploaded];
                        newIsUpload[0] = true;
                        setIsUploaded(newIsUpload);

                        setFieldValue(
                          "dataQuestion.banner_image",
                          e.target.files[0]
                        );
                      }
                    }}
                  />
                </Stack>
                <Divider sx={{ mt: 2, mb: 2, borderWidth: 1 }} />

                <Typography variant="body1" mt={2} mb={2}>
                  The binary survey requires two distinct images corresponding
                  to each of the two answer options. These images serve to
                  visually represent or complement the answer choices, enhancing
                  user engagement and understanding.
                </Typography>

                <>
                  {/* <Typography
                    variant="subtitle1"
                    mb={1.5}
                    sx={{ opacity: 0.6 }}
                  >
                    Show Response Image
                  </Typography>
                  <label className="switch" onChange={(e) => handleChange(e)}>
                    <Field type="checkbox" name="feedback" />
                    <span className="slider round"></span>
                  </label> */}

                  <>
                    {values.dataQuestion?.options?.map((item, i) => (
                      <div key={i}>
                        <Stack mt={2}>
                          <Typography variant="body1" fontWeight={500} mb={1.5}>
                            Answer Option {i === 0 ? "A" : "B"}
                          </Typography>
                          <Box sx={styleAnswer}>
                            <span>{item.answer}</span>
                          </Box>
                          <ComponentUploadImage
                            dataImage={item.image}
                            defaultImage={BannerImage2}
                            isDisabled={isUploaded[i + 1]}
                            uploadImageRef={uploadRefs.current[i + 1]}
                            isLoading={isLoading}
                            isError={true}
                            name={`dataQuestion.options[${i}].image`}
                            onHandleClick={(e) => {
                              const newIsUpload = [...isUploaded];
                              newIsUpload[i + 1] = false;
                              setIsUploaded(newIsUpload);
                              uploadRefs.current[i + 1].current.value = null;

                              setFieldValue(
                                `dataQuestion.options[${i}].image`,
                                ""
                              );
                            }}
                            onHandleChange={(e) => {
                              if (e.target.files) {
                                const newIsUpload = [...isUploaded];
                                newIsUpload[i + 1] = true;
                                setIsUploaded(newIsUpload);
                                setFieldValue(
                                  `dataQuestion.options[${i}].image`,
                                  e.target.files[0]
                                );
                              }
                            }}
                          />
                          {/* {isResponse && (
                          )} */}
                        </Stack>
                        <Divider sx={{ mt: 2, mb: 2, borderWidth: 1 }} />
                      </div>
                    ))}
                  </>
                </>

                <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
                  <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
                    <CustomButton
                      className={"custom_button white"}
                      style={{ minWidth: 100 }}
                      disabled={isLoading}
                      onHandleClick={() =>
                        handleQuestionNextBack(question.questionStep - 1)
                      }
                    >
                      <span>Back</span>
                    </CustomButton>
                    <CustomButton
                      className={"custom_button white"}
                      style={{ minWidth: 133 }}
                      type="submit"
                      onHandleClick={() => setModalType("draft")}
                      disabled={isLoading}
                    >
                      <span>Save to Drafts</span>
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      style={{ minWidth: 100 }}
                      className={"custom_button black"}
                      onHandleClick={() => setModalType("submit")}
                      disabled={isLoading}
                    >
                      <span>Continue</span>
                    </CustomButton>
                  </Stack>
                </Stack>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    formQuestion: state.questionBank.questionData,
    question: state.questionBank.checkQuestion,
    modalConfirmation: state.modal.modalConfirmation,
    modalQuestion: state.modal.modalQuestion,
  };
};
export default connect(mapStatetoProps, null)(BinaryImages);
