import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { BannerImage1, ImagePreview } from "../../../../../assets";
import { CustomButton, PreviewImage, Title } from "../../../../ComponentIndex";
import { connect } from "react-redux";
import { handleModalConfirmation } from "../../../../../store/actions/modal";

const styleButton = {
  "&:hover": { backgroundColor: "#17412F" },
  mt: 4,
  backgroundColor: "#17412F",
  color: "#FFF",
  minHeight: 46,
  width: 150,
  borderRadius: 2,
  textTransform: "none",
};

const styleCard = {
  backgroundImage: `url(${ImagePreview})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  borderRadius: 10,
  overflow: "auto",
  height: "550px",
};

function BinaryPreview({
  dispatch,
  question,
  formQuestion,
  handleQuestion,
  modalQuestion,
}) {
  function handleModal(type) {
    let data = formQuestion;
    if (modalQuestion.type === "edit") {
      data = {
        id: modalQuestion.data.id,
        body: data,
      };
    }
    handleModalConfirmation(dispatch, {
      isOpen: true,
      type,
      data,
    });
  }
  function handleQuestionNextBack(nextOrBack) {
    handleQuestion(dispatch, {
      questionType: question.questionType,
      questionStep: nextOrBack,
    });
  }

  return (
    <>
      <Title>Question Preview</Title>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Card style={styleCard}>
            <CardContent sx={{ color: "#FFF" }}>
              <Stack gap={1}>
                <div>
                  <PreviewImage
                    dataImage={formQuestion.banner_image}
                    defaultImage={BannerImage1}
                    alt={"BannerImagePreview"}
                    styles={{ width: "100%", height: 100 }}
                  />
                </div>
                <Typography variant="h7" textAlign={"center"} mb={3} mt={2}>
                  {formQuestion.title}
                </Typography>
                <Grid container spacing={2} justifyContent={"center"}>
                  {formQuestion?.options?.map((item, i) => (
                    <Grid item xs={6} key={i}>
                      <Stack alignItems={"center"}>
                        <PreviewImage
                          dataImage={item.image}
                          defaultImage={BannerImage1}
                          alt={`binary${i}`}
                          styles={{ width: "80%" }}
                        />
                        <Button sx={styleButton} variant="contained">
                          <span>{item.answer}</span>
                        </Button>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </CardContent>
          </Card>
          <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
            <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
              <CustomButton
                className={"custom_button white"}
                style={{ width: 100 }}
                onHandleClick={() =>
                  handleQuestionNextBack(question.questionStep - 1)
                }
              >
                <span>Back</span>
              </CustomButton>
              <CustomButton
                onHandleClick={() => handleModal("draft")}
                className={"custom_button white"}
                style={{ width: 133 }}
              >
                <span>Save to Drafts</span>
              </CustomButton>
              <CustomButton
                className={"custom_button black"}
                onHandleClick={() => handleModal("submit")}
                style={{ width: 100 }}
              >
                <span>Submit</span>
              </CustomButton>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    formQuestion: state.questionBank.questionData,
    question: state.questionBank.checkQuestion,
    modalQuestion: state.modal.modalQuestion,
  };
};
export default connect(mapStatetoProps, null)(BinaryPreview);
