import GlobalStyles from "@mui/material/GlobalStyles";

// These affect buttons and icons in the app. The colour palette is defined in config.js and passed in here.
const importGlobalStyles = (
  <GlobalStyles
    styles={{
      ".btn-add::before": {
        content: `"+"`,
        position: "absolute",
        borderRadius: "50%",
        color: `${window.theme.colors.approve}`,
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "18px",
        left: "14px",
        top: "12px",
        textAlign: "center",
        height: "20px",
        width: "20px",
        background: `${window.theme.colors.approve}50`,
      },
      ".status-DRAFT, .status-APPROVED, .status-PENDING": {
        color: `${window.theme.colors.warning}`,
        borderColor: `${window.theme.colors.warning}`,
      },
      ".status-ACTIVE": {
        color: `${window.theme.colors.approve}`,
        borderColor: `${window.theme.colors.approve}`,
      },
      ".status-EXPIRED": {
        color: `${window.theme.colors.error}`,
        borderColor: `${window.theme.colors.error}`,
      },
      ".btn-delete": {
        background: `${window.theme.colors.svg} !important`,
        border: `${window.theme.colors.error} solid 2px !important`,
        maxHeight: "56px",
      },
      ".btn-delete svg path": {
        stroke: `${window.theme.colors.error}`,
      },
      ".btn-delete:hover": {
        background: `${window.theme.colors.error} !important`,
      },
      ".btn-delete:hover svg path": {
        stroke: `${window.theme.colors.svg}`,
      },
      ".btn-edit": {
        background: `${window.theme.colors.svg} !important`,
        border: `${window.theme.colors.warning} solid 2px !important`,
      },
      ".btn-edit svg path": {
        stroke: `${window.theme.colors.warning}`,
        strokeWidth: "1.5px",
        fill: `${window.theme.colors.svg}`,
      },
      ".btn-edit:hover": {
        background: `${window.theme.colors.warning} !important`,
      },
      ".btn-edit:hover svg path": {
        stroke: `${window.theme.colors.svg}`,
        fill: `${window.theme.colors.warning}`,
      },
      ".btn-save": {
        background: `${window.theme.colors.svg} !important`,
        border: `${window.theme.colors.primary} solid 2px !important`,
      },
      ".btn-save svg path": {
        stroke: `${window.theme.colors.primary}`,
        strokeWidth: "1.5px",
        fill: `${window.theme.colors.svg}`,
      },
      ".btn-save svg rect": {
        fill: `${window.theme.colors.primary}`,
      },
      ".btn-save:hover": {
        background: `${window.theme.colors.primary} !important`,
      },
      ".btn-save:hover svg path": {
        stroke: `${window.theme.colors.svg}`,
        fill: `${window.theme.colors.primary}`,
      },
      ".btn-save:hover svg rect": {
        fill: `${window.theme.colors.svg}`,
      },
      body: {
        backgroundImage: `url(${window.theme.backgroundLink})`,
        backgroundColor: `${window.theme.colors.pageBackground}`,
        color: `${window.theme.colors.font}`,
        fontFamily: `-apple-system, ${window.theme.fonts.fontFamilyName}, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;`,
      },
    }}
  />
);

export default importGlobalStyles;
