import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import {
  BannerImage1,
  SingleImage1,
  SingleImage2,
  SingleImage3,
} from "../../../../../assets";
import {
  CustomButton,
  LeftContentQuestion,
  RightContentQuestion,
} from "../../../../ComponentIndex";
import { connect } from "react-redux";
import { handleModalConfirmation } from "../../../../../store/actions/modal";
import { handleCheckNextQuestion } from "../../../../../utilities/utils";

const styleButton = {
  backgroundColor: "#17412F",
  display: "flex",
  fontSize: 12,
  justifyContent: "start",
  borderRadius: 2,
  paddingLeft: 1,
  textTransform: "none",
};

const mockData = [
  {
    label: "Townhouse",
    image: SingleImage1,
  },
  {
    label: "Apartment",
    image: SingleImage2,
  },
  {
    label: "Sub-Urban",
    image: SingleImage3,
  },
];

function SingleSelectQuestion({
  dispatch,
  question,
  formQuestion,
  handleQuestion,
}) {

  const handleNextQuestion = () => {
    handleQuestion(dispatch, {
      questionType: question.questionType,
      questionStep: question.questionStep + 1,
    });
    formQuestion.question_type = 3; // 3 = Single Select
  }

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <LeftContentQuestion title={"Single Select Question"}>
            <Typography variant="body1" mt={1}>
              The Single Select Quiz is designed to capture a user's primary
              choice or preference. One NZ can use this to find out which
              broadband package a user is subscribed to or to understand the
              main reason a user chose One NZ, be it for Speed, Price, or
              Customer Service.
            </Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              Example: "Which aspect of One NZ mobile plans do you value most?"
            </Typography>
          </LeftContentQuestion>
        </Grid>
        <Grid item xs={4}>
          <RightContentQuestion
            title={"Which type of housing below do you currently live in?"}
            image={BannerImage1}
          >
            <Box sx={{ width: "100%" }}>
              <Stack spacing={1}>
                {mockData.map((item, i) => (
                  <Button
                    key={i}
                    variant="contained"
                    sx={styleButton}
                    startIcon={<Avatar src={item.image} variant="square" />}
                  >
                    {item.label}
                  </Button>
                ))}
              </Stack>
            </Box>
          </RightContentQuestion>
        </Grid>
        <Grid item xs={12} mt={2}>
          <CustomButton
            type="submit"
            className={"custom_button black"}
            onHandleClick={() => handleCheckNextQuestion({
              dispatch,
              currentQuestionType: formQuestion.question_type,
              defaultQuestionType: 3,
              formQuestion,
              handleNextQuestion,
              handleModalConfirmation
            })}
          >
            <span>Continue</span>
          </CustomButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {
    formQuestion: state.questionBank.questionData,
    question: state.questionBank.checkQuestion,
  };
};
export default connect(mapStatetoProps, null)(SingleSelectQuestion);
