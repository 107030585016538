export const handleModalRejection = async (dispatch, data) => {
  dispatch({
    type: "SET_MODAL_REJECTION",
    payload: {
      isOpen: data?.isOpen,
      approvalStatus: data?.approvalStatus,
      reason: data?.reason,
      data: data?.data,
      type: data?.type,
    },
  });
};
export const handleModalConfirmation = async (dispatch, data) => {
  dispatch({
    type: "SET_MODAL_CONFIRMATION",
    payload: {
      isOpen: data.isOpen,
      type: data.type,
      data: data.data,
    },
  });
};
export const handleModalQuestion = async (dispatch, data) => {
  dispatch({
    type: "SET_MODAL_QUESTION",
    payload: {
      isOpen: data?.isOpen,
      type: data?.type,
      data: data?.data,
    },
  });
};
export const handleModalSurvey = async (dispatch, data) => {
  dispatch({
    type: "SET_MODAL_SURVEY",
    payload: {
      isOpen: data?.isOpen,
      type: data?.type,
      data: data?.data,
    },
  });
};