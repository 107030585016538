import React from "react";
import { BannerImage1 } from "../../assets";
import { Avatar, Button, Chip, Stack, Typography } from "@mui/material";
import { PreviewImage } from "../ComponentIndex";
import { fetchImage } from "../../utilities/utils";

const styles = {
  marginTop: -2,
  paddingBottom: 3,
};

const styleButton = (questionType, index) => ({
  "&:hover": { backgroundColor: questionType === "MULTIPLE_SELECTION" ? index % 2 === 1 ? "#FFF" : "#17412F" : "#17412F" },
  backgroundColor: questionType === "MULTIPLE_SELECTION" ? index % 2 === 1 ? "#FFF" : "#17412F" : "#17412F",
  color: questionType === "MULTIPLE_SELECTION" ? index % 2 === 1 ? "#000" : "#FFF" : "#FFF",
  fontSize: 12,
  display: "flex",
  justifyContent: "start",
  borderRadius: 2,
  paddingLeft: 1,
  textTransform: "none",
  width: "100%",
});
const styleChip = (questionType, index) => ({
  "&:hover": { backgroundColor: questionType === "MULTIPLE_SELECTION" ? index % 2 === 1 ? "#FFF" : "#17412F" : "#17412F" },
  backgroundColor: questionType === "MULTIPLE_SELECTION" ? index % 2 === 1 ? "#FFF" : "#17412F" : "#17412F",
  color: questionType === "MULTIPLE_SELECTION" ? index % 2 === 1 ? "#000" : "#FFF" : "#FFF",
  display: "flex",
  fontSize: 12,
  justifyContent: "start",
  flexDirection: "row-reverse",
  borderRadius: 4,
  width: "100%",
  height: 40,
});

function SinglePreview({ questionDetail, questionType }) {
  const [newImages, setNewImages] = React.useState([])

  React.useEffect(() => {
    questionDetail?.options?.forEach((item) => {
      convertImage(item.image);
    });
  }, [questionDetail?.options])

  function showImage(path) {
    const findData = newImages.find(item => item[path] )
    if (findData !== undefined && Object.keys(findData).length > 0) {
      return findData[path]
    }
    return ""
  }
  async function convertImage(imageURL) {
    try {
      const result = await fetchImage(imageURL, "list");
      if (result) {
        setNewImages(prev => [...prev, result]);
      } 
    } catch (error) {
      console.error(`Error fetching image`, error);
    }
  }
  return (
    <Stack gap={1} sx={styles}>
      <div>
        <PreviewImage
          dataImage={questionDetail.banner_image}
          defaultImage={BannerImage1}
          alt={"singlemultiBanner"}
          className={"banner_image"}
        />
      </div>
      <Typography variant="h7" textAlign={"center"} mb={2} mt={1}>
        {questionDetail.title}
      </Typography>
      <Stack spacing={3}>
        {questionDetail.options?.map((item, i) => (
          <div key={i}>
            {item.image ? (
              <Button
                variant="contained"
                sx={styleButton(questionType, i)}
                startIcon={
                  item.image && (
                    <Avatar
                      src={
                        (typeof item.image === "object" ?
                          item.image &&
                          URL.createObjectURL(item.image) : showImage(item.image))
                      }
                      variant="square"
                    />
                  )
                }
              >
                {item.answer}
              </Button>
            ) : (
              <Chip
                key={i}
                variant="contained"
                label={item.answer}
                sx={styleChip(questionType, i)}
              />
            )}
          </div>
        ))}
      </Stack>
    </Stack>
  );
}

export default SinglePreview;
