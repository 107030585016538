import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card } from "@mui/material";
import BinaryPreview from "../BinaryPreview";
import SinglePreview from "../SinglePreview";
import FreeTextPreview from "../FreeTextPreview";
import InformationPreview from "../InformationPreview";

function ContentPreview({
  index,
  handleSelect,
  directionButtons,
  questionDetail,
  values,
}) {
  return (
    <>
      <Carousel
        nextIcon={
          values?.questions?.length > 0 && index < values?.questions?.length - 1
            ? directionButtons("Next")
            : ""
        }
        prevIcon={index > 0 ? directionButtons("Previous") : null}
        activeIndex={index}
        onSelect={handleSelect}
        interval={null}
        className={`custom_carousel ${
          index < 1
            ? "hide-previous"
            : values?.questions?.length < 1 || index === values?.questions?.length - 1
            ? "hide-next"
            : ""
        }`}
      >
        {values?.questions?.map((_, i) => {
          return (
            <Carousel.Item key={i}>
              <Card
                style={{
                  height: "100%",
                }}
              >
                <Carousel.Caption>
                  {questionDetail.question_type === "BINARY" && (
                    <BinaryPreview questionDetail={questionDetail} />
                  )}
                  {(questionDetail.question_type === "SINGLE_SELECTION" ||
                    questionDetail.question_type === "MULTIPLE_SELECTION") && (
                    <SinglePreview questionDetail={questionDetail} />
                  )}
                  {questionDetail.question_type === "FREE_TEXT" && (
                    <FreeTextPreview questionDetail={questionDetail} />
                  )}
                  {questionDetail.question_type === "INFORMATION" && (
                    <InformationPreview questionDetail={questionDetail} />
                  )}
                </Carousel.Caption>
              </Card>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
}

export default ContentPreview;
