import React, { useState, useEffect } from "react";
import { Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";

import { LogActivity } from "../../ComponentIndex";
import { publishErrorMessage } from "../../../events/events";

const LogActivitySection = ({ campaign, expanded = false }) => {
  const [isExpanded, setIsExpanded] = useState(expanded),
    [logData, setLogData] = useState(null),
    handleAccordionChange = (event, newExpandedState) => {
    setIsExpanded(newExpandedState);
  };

  useEffect(() => {
  if(isExpanded && logData === null){
    axios
      .get(window.promotion_url + "/campaigns/log/" + campaign.id)
      .then((response) => {
        setLogData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching log data:", error);
        publishErrorMessage("Error fetching log data. Please retry.");
      });
    }
  }, [isExpanded, campaign, logData]);

  return (
    <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="offer-message-content"
        id="offer-message-header"
        sx={{
          background: `${window.theme.colors.header}`,
          borderBottom: `1px solid ${window.theme.colors.notSelectable}`,
        }}
      >
        <Typography variant="h6">Log</Typography>
      </AccordionSummary>
      <AccordionDetails>
          <LogActivity
            logData={logData}
          />
      </AccordionDetails>
    </Accordion>
  );
};

export default LogActivitySection;
