import React, { useCallback, useEffect, useState } from "react";
import { Backdrop, CircularProgress, Snackbar, Alert } from "@mui/material";
import { subscribe } from "../../events/events";

// NotificationBar subscribes to "" events.
// To display a notification/event message elsewhere in the application other classes should simply
// use the "publish" function,
// e.g. publish("showNotification", {severity: "error", message: "<...>"});
export default function NotificationBar() {
  const [notification, setNotification] = useState({ open: false });
  const [waitSpinner, setWaitSpinner] = useState(false);

  const onNotification = (event) => {
    event.detail.severity = event.detail.severity ? event.detail.severity : "info";

    setNotification({
      open: true,
      message: event.detail.message,
      severity: event.detail.severity,
    });
  };

  const handleClose = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setNotification({ ...notification, open: false });
    },
    [notification]
  );

  const changeWaitSpinner = (event) => {
    setWaitSpinner(event.detail.toggle);
  };

  useEffect(() => {
    subscribe("showNotification", onNotification);
    subscribe("hideNotifications", handleClose);
    subscribe("showWaitSpinner", changeWaitSpinner);
  }, [handleClose, setWaitSpinner]);

  return (
    <div>
      <Snackbar open={notification.open} autoHideDuration={4000} onClose={handleClose}>
        <Alert variant="filled" severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: `${window.theme.colors.inverseFont}`, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={waitSpinner}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
