import { Grid, Typography } from "@mui/material";
import { formatDate } from "../../utilities/utils";

export default function LogActivity({ logData }) {
  const style = {
    maxWidth: "100%",
  };

  return (
    <Grid container spacing={2} sx={{ p: "16px" }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <h3 style={{ marginTop: "24px" }}>Action</h3>
        </Grid>
        <Grid item xs={4}>
          <h3 style={{ marginTop: "24px" }}>User</h3>
        </Grid>
        <Grid item xs={4}>
          <h3 style={{ marginTop: "24px" }}>Time</h3>
        </Grid>
      </Grid>
      {logData && logData.map((log, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={4}>
            <Typography component="p" style={style}>
              {log.actionCode}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component="p" style={style}>
              {log.username}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component="p" style={style}>
              {formatDate(log.time, "HH:mm - dd/MM/yyyy")}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
