import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { msalInstance } from './MsalAuth';

const useAccountName = () => {
  const [accountName, setAccountName] = useState(null);
  const { tokenData } = useContext(AuthContext);

  useEffect(() => {
    if (tokenData && !accountName) {
      setAccountName(tokenData.sub);
    } else if (!window.authorizationFlow) {
      const activeAccount = msalInstance.getActiveAccount();
      setAccountName(activeAccount?.name);
    }
  }, [accountName, tokenData]);

  return accountName;
};

export default useAccountName;
