import { Avatar, Chip, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import {
  BannerImage3,
  MultiImage1,
  MultiImage2,
  MultiImage3,
  MultiImage4,
  MultiImage5,
} from "../../../../../assets";
import {
  CustomButton,
  LeftContentQuestion,
  RightContentQuestion,
} from "../../../../ComponentIndex";
import { connect } from "react-redux";
import { handleModalConfirmation } from "../../../../../store/actions/modal";
import { handleCheckNextQuestion } from "../../../../../utilities/utils";

const styleButton = (other) => {
  return {
    display: "flex",
    fontSize: 12,
    justifyContent: "start",
    flexDirection: "row-reverse",
    borderRadius: 4,
    ...other,
  };
};

const mockData = [
  {
    label: "Ambitious",
    image: MultiImage1,
    backgroundColor: "#17412F",
    color: "#FFF",
  },
  {
    label: "Creative",
    image: MultiImage2,
    backgroundColor: "#FFF",
    color: "#000",
  },
  {
    label: "Compassionate",
    image: MultiImage3,
    backgroundColor: "#FFF",
    color: "#000",
  },
  {
    label: "Extrovert",
    image: MultiImage4,
    backgroundColor: "#17412F",
    color: "#FFF",
  },
  {
    label: "Inteligent",
    image: MultiImage5,
    backgroundColor: "#17412F",
    color: "#FFF",
  },
  {
    label: "Introvert",
    image: MultiImage1,
    backgroundColor: "#17412F",
    color: "#FFF",
  },
  {
    label: "Loves Books",
    image: MultiImage1,
    backgroundColor: "#17412F",
    color: "#FFF",
  },
];

function MultiSelectQuestion({
  dispatch,
  question,
  formQuestion,
  handleQuestion,
}) {

  const handleNextQuestion = () => {
    handleQuestion(dispatch, {
      questionType: question.questionType,
      questionStep: question.questionStep + 1,
    });
    formQuestion.question_type = 4; // 4 = Multiple Select
  }

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <LeftContentQuestion title={"Multi Select Question"}>
            <Typography variant="body1" mt={1}>
              The Multi Select question allows One NZ to gain a broader
              understanding by allowing users to select multiple options. This
              can help One NZ determine the features users recognise in the
              premium mobile plan or the variety of One NZ services they
              utilise, such as Mobile, Broadband, or Streaming Services.
            </Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              Example: "Can you identify the channels included in One NZ's
              premium broadband streaming bundle?"
            </Typography>
          </LeftContentQuestion>
        </Grid>
        <Grid item xs={4}>
          <RightContentQuestion
            title={
              "Can you select qualities that applies to you? Select one ormore options that describe you on a rainy day"
            }
            image={BannerImage3}
          >
            <Stack spacing={1.5}>
              {mockData.map((item, i) => (
                <Chip
                  key={i}
                  variant="contained"
                  label={item.label}
                  sx={styleButton({
                    backgroundColor: item.backgroundColor,
                    color: item.color,
                  })}
                  avatar={<Avatar src={item.image} />}
                />
              ))}
            </Stack>
          </RightContentQuestion>
        </Grid>
        <Grid item xs={12} mt={2}>
          <CustomButton
            type="submit"
            className={"custom_button black"}
            onHandleClick={() => handleCheckNextQuestion({
              dispatch,
              currentQuestionType: formQuestion.question_type,
              defaultQuestionType: 4,
              formQuestion,
              handleNextQuestion,
              handleModalConfirmation
            })}
          >
            <span>Continue</span>
          </CustomButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {
    formQuestion: state.questionBank.questionData,
    question: state.questionBank.checkQuestion,
  };
};
export default connect(mapStatetoProps, null)(MultiSelectQuestion);
