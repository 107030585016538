import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { BannerImage3 } from "../../../../../assets";
import {
  CustomButton,
  LeftContentQuestion,
  RightContentQuestion,
} from "../../../../ComponentIndex";
import { connect } from "react-redux";
import { handleModalConfirmation } from "../../../../../store/actions/modal";
import { handleCheckNextQuestion } from "../../../../../utilities/utils";

function InformationQuestion({
  dispatch,
  question,
  formQuestion,
  handleQuestion,
}) {

  const handleNextQuestion = () => {
    handleQuestion(dispatch, {
      questionType: question.questionType,
      questionStep: question.questionStep + 1,
    });
    formQuestion.question_type = 5; // 5 = Information
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <LeftContentQuestion title={"Information"}>
            <Typography variant="body1" mt={1}>
              The information space is ideal for One NZ to be used for many
              purposes, such as promoting upcoming events, spreading awareness
              or simply displaying general information for users. For example,
              One NZ could promote the newest feature or any additional
              gamification in the application.
            </Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              Example: "Did you know that we have a new upcoming feature
              launching this March 2024? Let's find out! This March, One NZ will
              launch our newest feature."
            </Typography>
          </LeftContentQuestion>
        </Grid>
        <Grid item xs={4}>
          <RightContentQuestion
            title={"Welcome to One NZ Reward Survey"}
            image={BannerImage3}
          >
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={12}>
                <List
                  sx={{
                    width: "100%",
                    listStyle: "decimal",
                    pl: 2,
                    color: "#FFF",
                    "& li": {
                      lineHeight: "normal",
                      margin: 0,
                      padding: 0,
                    },
                    "& li p": { color: "#FFF" },
                  }}
                >
                  <ListItem sx={{ display: "list-item", color: "#FFF" }}>
                    <ListItemText secondary="Answer each survey before it expires: We release a few new surveys each month." />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText secondary="Earn wallet balance rewards." />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText secondary="Turn on notifications and come back every $Insert time$, before the weekly survey runs out" />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </RightContentQuestion>
        </Grid>
        <Grid item xs={12} mt={2}>
          <CustomButton
            type="submit"
            className={"custom_button black"}
            onHandleClick={() => handleCheckNextQuestion({
              dispatch,
              currentQuestionType: formQuestion.question_type,
              defaultQuestionType: 5,
              formQuestion,
              handleNextQuestion,
              handleModalConfirmation
            })}
          >
            <span>Continue</span>
          </CustomButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {
    formQuestion: state.questionBank.questionData,
    question: state.questionBank.checkQuestion,
  };
};
export default connect(mapStatetoProps, null)(InformationQuestion);
