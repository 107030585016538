export default function SaveSVG() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 1H5.625V10.625H19.375V1L23.3536 4.97855C23.4473 5.07232 23.5 5.1995 23.5 5.33211V20.25C23.5 22.0375 22.4 23 20.75 23H4.25C2.58778 23 1.5 22.0375 1.5 20.25V3.75C1.5 1.9625 2.58778 1 4.25 1Z"
      />
      <rect opacity="0.8" x="12.5" y="1" width="4.125" height="6.875" rx="0.5" />
    </svg>
  );
}
