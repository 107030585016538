import {
  windowSizeUnderSmTablet,
  windowSizeUnderLgTablet,
  windowSizeUnderLaptop,
  windowSizeUnderMob,
  formatLargeNumberWithCommas,
} from "../utilities/utils";

import { TableCell } from '@mui/material';

export const CONSTANTS = {
  CAMPAIGN: "Campaign",
  CAMPAIGN_TYPE: "Campaign Type",
  COUPON_STATUS: "Coupon Status",
};

export const imageTitles = {
  DYNAMIC: "Dynamic Image",
  LISTVIEW: "Listview Image",
  BRAND: "Brand Image (for offer view on homepage)",
  HERO: "Hero Image (for offer detail page)",
};
export const operatorOptions = ["IS", "AT_LEAST", "LESS_THAN", "NOT", "CONTAINS", "NOT_CONTAINS", "NOT_HAVE"];
export const dropDownOperatorOptions = ["IS", "NOT", "CONTAINS", "NOT_CONTAINS", "NOT_HAVE"];

export const stepConstants = {
  DEFINE_CAMPAIGN: "Define Campaign",
  ELIGIBILITY_CRITERIA: "Eligibility Criteria",
  SELECT_IMAGES: "Select Images",
  OFFER_MESSAGE: "Offer Message",
  PREVIEW_OFFER: "Preview Offer",
  CONFIRM: "Confirm",
};

export const steps = [
  stepConstants.DEFINE_CAMPAIGN,
  stepConstants.ELIGIBILITY_CRITERIA,
  stepConstants.SELECT_IMAGES,
  stepConstants.OFFER_MESSAGE,
  stepConstants.PREVIEW_OFFER,
  stepConstants.CONFIRM,
];

export const exclusionRuleStatuses = ["Earned", "Available", "Redeemed"];

export const statusMapping = {
  Earned: "ASSIGNED",
  Available: "ELIGIBLE",
  Redeemed: "REDEEMED",
};

export const campaignStatusMap = {
  DRAFT: "DRAFT",
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "DRAFT",
  ACTIVE: "ACTIVE",
  DELETED: "DELETED",
  EXPIRED: "EXPIRED",
};

export const campaignFilterStatusMap = {
  "/campaigns": ["", "DRAFT", "PENDING", "APPROVED", "ACTIVE", "EXPIRED"],
  "/campaign-template": ["", "ACTIVE", "DELETED"]
};

export const defaultCampaignValues = (isTemplate) => {
  return {
    name: "",
    description: "",
    startDate: isTemplate ? "" : new Date(new Date().setHours(0, 0, 0, 0)),
    endDate: "",
    couponValue: "",
    maxDistribution: 500000,
    eligibilityCriteria: {
      primaryRuleGroup: { andOrOption: "and", rules: [] },
      ruleGroups: [],
    },
    assignmentCriteria: {
      primaryRuleGroup: { andOrOption: "and", rules: [] },
      ruleGroups: [],
    },
    exclusionCriteria: [],
    status: "DRAFT",
    campaignType: "",
    isGenericTradeIn: false,
    isSavedOffer: false,
    ruleConfig: 1,
    couponOfferMessage: {},
    rewardExpireDays: "",
  };
};

export const offerMessage = {
  couponOfferName: "",
  couponOfferHeading: "",
  couponCTATitle: "",
  couponOfferUrlTitle: "",
  couponOfferExternalUrl: "",
  couponOfferNotification: "",
  couponOfferDetails: "",
  couponOfferValueSubheading: "",
  couponOfferTAndC: "",
  couponOfferShortTAndC: "",
  couponOfferAgentInstructions: "",
  actionTitle: "",
  actionUrl: "",
  saveOfferSavedTitle: "",
  saveOfferUnsavedTitle: "",
};

const statusOverride = (row, isArchive) => (
  <TableCell key="status-{{row.id}}" sx={isArchive ? { color: window.theme.colors.error } : {}}>
    <div data-testid="campaign-status" className={`status status-${row.status.trim()}`}>{row.status}</div>
  </TableCell>
);

export const getCampaignColumnHeaders = (screenWidth) => {
  const laptopOrSmaller = windowSizeUnderLaptop(screenWidth),
    lgTabletOrSmaller = windowSizeUnderLgTablet(screenWidth),
    smTabletOrSmaller = windowSizeUnderSmTablet(screenWidth),
    mobileOrSmaller = windowSizeUnderMob(screenWidth);

  return [
    { id: "name", name: "Name", attribute: "name", width: lgTabletOrSmaller ? "100px" : "160px" },
    {
      id: "status", name: "Status", attribute: "status", width: "90px",
      elementOverride: statusOverride
    },
    { id: "created", name: "Created", attribute: "createdDate", width: "auto", isDate: true, hide: laptopOrSmaller },
    { id: "start", name: "Start", attribute: "startDate", width: "auto", isDate: true, hide: lgTabletOrSmaller },
    { id: "end", name: "End", attribute: "endDate", width: "auto", isDate: true, hide: lgTabletOrSmaller },
    {
      id: "offer-amount",
      isCurrency: true,
      name: (
        <>
          Offer <br /> Amount
        </>
      ),
      attribute: "couponValue",
      width: "auto",
      sx: { textAlign: 'right' },
      hide: mobileOrSmaller,
    },
    {
      id: "campaign-type",
      name: (
        <>
          Campaign <br /> Type
        </>
      ),
      attribute: "campaignType",
      width: "auto",
      hide: smTabletOrSmaller,
    },
    {
      id: "distributed-limit",
      name: (
        <>
          Distributed <br />/ Limit
        </>
      ),
      attribute: "maxDistribution",
      width: "auto",
      hide: mobileOrSmaller,
      elementOverride: (row, isArchive) => (
        <TableCell key="max-distribution-{{row.id}}" data-testid="distributed">
          {formatLargeNumberWithCommas(row.distributedCoupons)} /{" "}
          {formatLargeNumberWithCommas(row.maxDistribution)}
        </TableCell>)
    },
  ];
};

export const getCampaignTemplateColumnHeaders = (screenWidth) => {
  const lgTabletOrSmaller = windowSizeUnderLgTablet(screenWidth);

  return [
    { id: "name", name: "Name", attribute: "templateName", width: lgTabletOrSmaller ? "100px" : "160px" },
    { id: "description", name: "Description", attribute: "description", width: "90px" },
    { id: "status", name: "Status", attribute: "status", width: "auto", elementOverride: statusOverride },
    {
      id: "offer-amount",
      isCurrency: true,
      name: (
        "Offer Amount"
      ),
      attribute: "couponValue",
      width: "auto",
      sx: { textAlign: 'right' },
    },
    { id: "campaign-type", name: "Campaign Type", attribute: "campaignType", width: "auto" },
  ];
};

export const tableTypePathMap = {
  CAMPAIGN: "/campaigns",
  TEMPLATE: "/campaign-template"
};

export const quesitonStatusFilter = [
  { label: "All", value: "" },
  { label: "Approved", value: "APPROVED" },
  { label: "Unchecked", value: "UNCHECKED" },
  { label: "Rejected", value: "REJECTED" },
  { label: "Drafted", value: "DRAFTED" },
];

export const questionTypeFilter = [
  { label: "All", value: "" },
  { label: "Binary", value: "BINARY" },
  { label: "Free Text", value: "FREE_TEXT" },
  { label: "Single Select", value: "SINGLE_SELECTION" },
  { label: "Multi Select", value: "MULTIPLE_SELECTION" },
  { label: "Information", value: "INFORMATION" },
];

export const questionTypeReturnStr = {
  BINARY: "Binary",
  FREE_TEXT: "Free Text",
  SINGLE_SELECTION: "Single Select",
  MULTIPLE_SELECTION: "Multi Select",
  INFORMATION: "Information",
};

export const questionTypeReturnInt = {
  BINARY: 0,
  SINGLE_SELECTION: 1,
  MULTIPLE_SELECTION: 2,
  FREE_TEXT: 3,
  INFORMATION: 4,
};

export const METHOD = {
  GET: 'get',
  PATCH: 'patch',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete'
}

export const ALPHABET = "abcdefghijklmnopqrstuvwxyz"
