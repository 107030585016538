import React, { useState } from "react";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { statusMapping } from "../../../constants/consts";

import { EligibilityViewer, ExclusionRule } from "../../ComponentIndex";
import {isMarketingCampaignType} from "../../../utilities/campaignHelpers";

export const getExclusionRuleValue = (rule) => rule.value || rule.existingCampaign || rule.existingCampaignType;
export const getExclusionRuleRuleKey = (rule) => statusMapping[rule.ruleKey || rule.status] || rule.status;

export const getMappedStatus = (value) => {
  const displayKey = Object.keys(statusMapping).find((key) => statusMapping[key] === value);
  return displayKey || value;
};

const EligibilityCriteriaSection = ({ campaign, ruleData, masterdata = [], expanded = true }) => {

  const [isExpanded, setIsExpanded] = useState(expanded),
    eligibilityCriteria = campaign.eligibilityCriteria,
    assignmentCriteria = campaign.assignmentCriteria,
    exclusionCriteria = campaign.exclusionCriteria
    ? campaign.exclusionCriteria
    : campaign.exclusionRules
    ? campaign.exclusionRules
    : [];

  const handleAccordionChange = (event, newExpandedState) => {
    setIsExpanded(newExpandedState);
  };
  const isNotEmpty = (criteria) => {
    if (!criteria) return false;
    return criteria?.primaryRuleGroup?.rules?.length > 0 || criteria?.ruleGroups?.length > 0;
  };

  const ruleFilter = (ruleConfig) => {
    const selectedRule = ruleData?.find((item) => item.id === ruleConfig);
    return selectedRule?.ruleDescription || campaign?.ruleConfig?.ruleDescription || null;
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="eligibility-exclusion-rules-content"
        id="eligibility-exclusion-rules-header"
        sx={{
          background: `${window.theme.colors.header}`,
          borderBottom: `1px solid ${window.theme.colors.notSelectable}`,
        }}
      >
        <Typography variant="h6">Criteria</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{ p: 2, background: `${window.theme.colors.secondary}` }}>
            <Grid item xs={12} sx={{ m: "10px 0" }}>
              <Typography variant="h7" className="bold">
                Include following customers for offer:
              </Typography>
            </Grid>
            {masterdata.length > 0 && isNotEmpty(eligibilityCriteria) && (
              <EligibilityViewer data={eligibilityCriteria} ruleName={"eligibility"} />
            )}
            {masterdata.length > 0 && !isNotEmpty(eligibilityCriteria) && <Grid>No Eligibility Rules.</Grid>}
          </Grid>

          <Grid item xs={12} sx={{ p: 2, background: `${window.theme.colors.componentBackground}` }}>
            <Grid item xs={12} sx={{ m: "10px 0" }}>
              <Typography variant="h7" className="bold">
                Exclude following customers from offer:
              </Typography>
            </Grid>
            <Grid>
              {exclusionCriteria?.map((rule, index) => (
                <React.Fragment key={`exclusionCriteria-${index}`}>
                  <Grid item xs={12} sx={{ m: "5px 0" }}>
                    <ExclusionRule
                      value={getExclusionRuleValue(rule)}
                      ruleKey={getExclusionRuleRuleKey(rule)}
                      operator={rule.date ? "IN ANY" : "IN"}
                      date={rule.date}
                    />
                  </Grid>
                  {index < exclusionCriteria.length - 1 && (
                    <Grid item xs={12} sx={{ ml: 1, fontWeight: 600 }}>
                      or
                    </Grid>
                  )}
                </React.Fragment>
              ))}
              {!(exclusionCriteria.length > 0) && <div>No Exclusion Rules.</div>}
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ p: 2, background: `${window.theme.colors.secondary}` }}>
            <Grid item xs={12} sx={{ m: "10px 0" }}>
              <Typography variant="h7" className="bold">
                When do users need to meet eligibility criteria
              </Typography>
            </Grid>

            {masterdata.length > 0 && !!campaign.ruleConfig && (
              <Grid sx={{ textTransform: "capitalize" }}>{ruleFilter(campaign.ruleConfig)}</Grid>
            )}
            {!masterdata.length > 0 && !campaign.ruleConfig && <Grid>None selected.</Grid>}
          </Grid>

          <Grid item xs={12} sx={{ p: 2, background: `${window.theme.colors.componentBackground}` }} hidden={isMarketingCampaignType(campaign)}>
            <Grid item xs={12} sx={{ m: "10px 0" }}>
              <Typography variant="h7" className="bold">
                Customers must meet these criteria to earn the value of the coupon:
              </Typography>
            </Grid>
            {masterdata.length > 0 && isNotEmpty(assignmentCriteria) && (
              <EligibilityViewer ruleName={"assignment"} data={assignmentCriteria} />
            )}
            {masterdata.length > 0 && !isNotEmpty(assignmentCriteria) && <Grid>No Assignment Rules.</Grid>}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default EligibilityCriteriaSection;
