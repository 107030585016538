import {useContext, useEffect, useState} from "react";
import { NumericFormat } from "react-number-format";
import {
  Grid,
  FormControl,
  OutlinedInput,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  isNameInvalid,
  isEndDateBeforeStartDate,
  addAsterisk,
  doesNameContainIllegalCharacters
} from "../../utilities/utils";
import {
  isValid,
  isValidValue,
  isValidStartDate,
  isOverZero,
  isOverThirty,
  isBetweenOneAndFiveHundred,
  isInvalidEndDate,
  isDraftEndDateInPast,
  isMarketingCampaignType,
  verifyCampaignType,
} from "../../utilities/campaignHelpers";
import { ErrorContainer } from "../ComponentIndex";
import EditModeContext from "../../contexts/EditModeContext";

export default function DefineCampaign(props) {
  const formValues = props.formValues,
    campaignTypes = props.campaignTypes,
    setFormValues = props.setFormValues,
    { editMode } = useContext(EditModeContext),
    resetErrorState = props.resetErrorState,
    surveyItems = props.surveyItems,
    [hasNameChanged, setNameChanged] = useState(false),
    [hasDescriptionChanged, setDescriptionChanged] = useState(resetErrorState),
    [hasCampaignTypeChanged, setCampaignTypeChanged] = useState(resetErrorState),
    [hasExpireDaysChanged, setExpireDaysChanged] = useState(resetErrorState),
    [hasOfferValueChanged, setOfferValueChanged] = useState(resetErrorState),
    [hasMaxOfferCountChanged, setMaxOfferCountChanged] = useState(resetErrorState),
    isTemplate = props.isTemplate,
    [hasEndDateChanged, setEndDateChanged] = useState(resetErrorState),
    [hasSurveyValueChanged, setHasSurveyValueChanged] = useState(resetErrorState),
    [isSavedOffer, setIsSavedOffer] = useState(false),
    [isSavedOfferSelected, setIsSavedOfferSelected] = useState(formValues.isSavedOffer);

  useEffect(() => {
    const campaignType = props.campaignTypes.find(
        type => type.name === formValues.campaignType);
    const isOfferSaved = campaignType?.isSavedOffer || false;
    setIsSavedOffer(isOfferSaved);
    setIsSavedOfferSelected(isOfferSaved);
    setFormValues((prevValues) => ({
      ...prevValues,
      isSavedOffer: isOfferSaved,
    }));
  }, [formValues.campaignType, props.campaignTypes, setFormValues]);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name === "couponValue" || name === "maxDistribution") {
      if (typeof value === "string") {
        value = value.replace(/[$,]/g, "");
        value = parseInt(value);
      }
    }

    setFormValues({
      ...formValues,
      [name]: value,
      isGenericTradeIn: name === "campaignType" ? false : formValues.isGenericTradeIn,
    });

    switch (name) {
      case "name":
        if (!hasNameChanged) {
          setNameChanged(true);
        }
        break;
      case "description":
        if (!hasDescriptionChanged) {
          setDescriptionChanged(true);
        }
        break;
      case "campaignType":
        if (!hasCampaignTypeChanged) {
          setCampaignTypeChanged(true);
        }
        break;
      case "rewardExpireDays":
        if (!hasExpireDaysChanged) {
          setExpireDaysChanged(true);
        }
        break;
      case "maxDistribution":
        if (!hasMaxOfferCountChanged) {
          setMaxOfferCountChanged(true);
        }
        break;
      case "couponValue":
        if (!hasOfferValueChanged) {
          setOfferValueChanged(true);
        }
        break;
      case "url":
        if (!hasSurveyValueChanged) {
          setHasSurveyValueChanged(true);
        }
        break;
      default:
        break;
    }
  };

  const handleExpireAfterChange = (e) => {
    const value = e.target.value;
    if (Number.isNaN(value)) {
      return;
    }
    handleInputChange(e);
  };

  const getNameHelperText = () => {
    return !hasNameChanged
      ? ""
      : doesNameContainIllegalCharacters(formValues, editMode)
      ? "Campaign name cannot contain /\\%#;?[]"
      : isNameInvalid(formValues, props.campaigns, editMode)
      ? "This campaign name is already taken."
      : !isValid(formValues.name) && hasNameChanged
      ? "Please enter a name for your campaign."
      : isOverThirty(formValues.name)
      ? `Total characters must be 30 or less. Currently at ${formValues.name.length} characters.`
      : "";
  };

  const isValidCampaignDescription = isTemplate || isBetweenOneAndFiveHundred(formValues.description);

  const getDescriptionHelperText = () => {
    return hasDescriptionChanged && !isValidCampaignDescription && formValues.description.length < 500
      ? "Please enter a campaign description."
      : hasDescriptionChanged && !isValidCampaignDescription && formValues.description.length > 500
      ? `Please use a maximum of 500 characters in the campaign description. Currently at ${formValues.description.length} characters.`
      : "";
  };

  const isCampaignNameError =
    hasNameChanged &&
    (isNameInvalid(formValues, props.campaigns, editMode) ||
        doesNameContainIllegalCharacters(formValues, editMode) ||
      ((!isValid(formValues.name) || isOverThirty(formValues.name)) && hasNameChanged));

  const isStartDateError = !isTemplate &&
    (isEndDateBeforeStartDate(formValues.startDate, formValues.endDate) || !isValidStartDate(formValues.startDate));

  const startDateErrorText = !isTemplate && !isValidStartDate(formValues.startDate)
    ? "Please select a valid start date. Date cannot be in the past."
    : "";

  const isEndDateError =
    !isTemplate && (isDraftEndDateInPast(formValues.endDate) ||
    (hasEndDateChanged && isEndDateBeforeStartDate(formValues.startDate, formValues.endDate)) ||
    (hasEndDateChanged && isInvalidEndDate(formValues.endDate)));

  const endDateErrorText =
    !isTemplate && hasEndDateChanged && isInvalidEndDate(formValues.endDate)
      ? "Please select a valid offer available end date."
      : isDraftEndDateInPast(formValues.endDate)
      ? "Offer available end date cannot be in the past."
      : "";

  const offerCountError = hasMaxOfferCountChanged && !isTemplate && !isValidValue(formValues.maxDistribution);
  const offerValueError = hasOfferValueChanged && !isTemplate && !isValidValue(formValues.couponValue);

  const campaignTypeError = hasCampaignTypeChanged && !isValid(formValues.campaignType) && verifyCampaignType(formValues, campaignTypes);

  const expiryDateError = hasExpireDaysChanged && !isTemplate && !isOverZero(formValues.rewardExpireDays);

  const isTradeInGenericSelected = formValues.isGenericTradeIn;
  
  const surveyValueError = hasSurveyValueChanged && !formValues.url;

  const handleGenericTradeInToggle = (e) => {
    const { name, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: checked,
      couponValue: checked === true ? 0 : formValues.couponValue,
    });
  };

  const handleSavedOfferToggle = (e) => {
    const { name, checked } = e.target;
    setIsSavedOfferSelected(checked)
    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  const renderDatePickerSection = () => {
    return (
      <>
        <Grid item xs={8} sm={6}>
          <FormControl fullWidth>
            <DatePicker
              data-testid={`start-date-input`}
              id="start-input"
              aria-describedby="start-helper-text"
              name="startDate"
              label={addAsterisk("Offer available start date", !isTemplate)}
              inputFormat="dd/MM/yyyy"
              minDate={new Date(new Date().setHours(0, 0, 0, 0))}
              disablePast={true}
              value={formValues.startDate}
              onChange={(newValue) => {
                setFormValues({ ...formValues, startDate: newValue });
              }}
              renderInput={(params) => <TextField {...params} error={isStartDateError} helperText={startDateErrorText} />}
            />
            {isEndDateBeforeStartDate(formValues.startDate, formValues.endDate) && (
              <ErrorContainer errorMessage="Please ensure that the offer available end date is after the start date." />
            )}
          </FormControl>
        </Grid>
        <Grid item xs={8} sm={6}>
          <FormControl fullWidth>
            <DatePicker
              data-testid={`end-date-input`}
              id="end-input"
              aria-describedby="end-helper-text"
              name="endDate"
              label={addAsterisk("Offer available end date", !isTemplate)}
              inputFormat="dd/MM/yyyy"
              value={formValues.endDate}
              disablePast={true}
              onChange={(newValue) => {
                setFormValues({ ...formValues, endDate: newValue });
                setEndDateChanged(true);
              }}
              renderInput={(params) => <TextField {...params} error={isEndDateError} helperText={endDateErrorText} />}
            />
          </FormControl>
        </Grid>
      </>
  )};

  return (
    <Grid container spacing={2} sx={{ p: "16px 0" }}>
      <Grid item xs={12}>
        <Typography variant="h3" color="primary">
          { isTemplate ? 'Campaign Template' : 'Campaign' }
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={8} md={4}>
          <FormControl fullWidth>
            <TextField
              data-testid={`name-input`}
              id="name-input"
              aria-describedby="name-helper-text"
              label="Name"
              name="name"
              variant="standard"
              required // name is required for templates
              error={isCampaignNameError}
              helperText={getNameHelperText()}
              value={formValues.name}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={8} md={4} sm={6}>
        <FormControl fullWidth>
          <InputLabel
            sx={campaignTypeError ? { color: `${window.theme.colors.error} !important` } : null}
            id="campaignTypeLabelId"
          >
            Campaign type *
          </InputLabel>
          <Select
            data-testid={`campaign-type-input`}
            required // campaign type is required for templates
            name="campaignType"
            labelId="campaignTypeLabelId"
            id="campaignType"
            label="Campaign Type *"
            value={formValues.campaignType}
            onChange={handleInputChange}
            error={campaignTypeError}
          >
            {campaignTypes
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((ct) => (
                <MenuItem key={ct.id} value={ct.name} disabled={ct.name === "Goodwill"}>
                  {ct.name}
                </MenuItem>
              ))}
          </Select>
          {(campaignTypeError || verifyCampaignType(formValues, campaignTypes)) && <ErrorContainer errorMessage="Please choose a campaign type." />}
        </FormControl>
      </Grid>
      {formValues.campaignType === "Survey" && (
        <Grid item xs={4} md={4} sm={6}>
        <FormControl fullWidth>
          <InputLabel
            sx={surveyValueError ? { color: `${window.theme.colors.error} !important` } : null}
            id="surveyLabelId"
          >
            Survey Name
          </InputLabel>
          <Select
            data-testid={`survey-name-input`}
            required
            name="url"
            labelId="surveyLabelId"
            id="survey-name-input"
            label="Survey Name"
            value={formValues.url}
            onChange={handleInputChange}
            error={surveyValueError}
          >
            {!surveyItems?.empty && surveyItems?.content?.length > 0 ? surveyItems.content
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              )) : (
                <MenuItem>
                  No Data
                </MenuItem>
              )}
          </Select>
          {surveyValueError && <ErrorContainer errorMessage="Please choose a survey url" />}
        </FormControl>
      </Grid>
      )}

      {isMarketingCampaignType(formValues) && (
          <Grid item xs={4} sm={6}>
            <FormControl fullWidth>
              <TextField id="external-url-input"
                         data-testid={`external-url-input`}
                         aria-describedby="external-url-text"
                         label="External URL"
                         name="url"
                         required
                         value={formValues.url}
                         onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
      )}
      {formValues.campaignType === "Trade-in" &&
        campaignTypes.filter((c) => c.name === "Trade-in")[0].tradeinIsGeneric === true && (
          <Grid item xs={12} md={4} sm={6}>
            <FormControl fullWidth>
              <FormGroup>
                <FormControlLabel
                  sx={{ marginTop: "8px" }}
                  label="Trade-in Generic Campaign"
                  control={
                    <Checkbox
                      checked={isTradeInGenericSelected}
                      name="isGenericTradeIn"
                      value={isTradeInGenericSelected}
                      data-testid={`is-generic-trade-in-input`}
                      aria-describedby="is-generic-trade-in-helper-text"
                      variant="standard"
                      onChange={handleGenericTradeInToggle}
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )}
      {isSavedOffer && (
        <Grid item xs={12} md={4} sm={6}>
          <FormControl fullWidth>
            <FormGroup>
              <FormControlLabel
                  sx={{ marginTop: "8px" }}
                  label="Saved Offer"
                  control={
                    <Checkbox
                        checked={isSavedOfferSelected}
                        name="isSavedOffer"
                        value={isSavedOfferSelected}
                        data-testid={`is-saved-offer-input`}
                        aria-describedby="is-saved-offer-helper-text"
                        variant="standard"
                        onChange={handleSavedOfferToggle}
                    />
                  }
              />
            </FormGroup>
          </FormControl>
        </Grid>
          )}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            data-testid={`description-input`}
            id="description-input"
            aria-describedby="description-helper-text"
            multiline
            rows={3}
            label="Description"
            name="description"
            required={!isTemplate}
            error={hasDescriptionChanged && !isValidCampaignDescription}
            helperText={getDescriptionHelperText()}
            value={formValues.description}
            onChange={handleInputChange}
          />
        </FormControl>
      </Grid>
      { isTemplate ? null : renderDatePickerSection() }
      <Grid item xs={8} sm={6} md={4}>
        <FormControl fullWidth>
          <InputLabel
            sx={
              !isTradeInGenericSelected && offerValueError ? { color: `${window.theme.colors.error} !important` } : null
            }
            htmlFor="value-input"
          >
            {addAsterisk("Offer Value", !isTemplate)}
          </InputLabel>
          <NumericFormat
            disabled={isTradeInGenericSelected || isMarketingCampaignType(formValues)}
            sx={isTradeInGenericSelected || isMarketingCampaignType(formValues) ? { background: window.theme.colors.notSelectable } : null}
            data-testid={`value-input`}
            customInput={OutlinedInput}
            id="value-input"
            aria-describedby="value-helper-text"
            decimalScale={0}
            inputProps={{maxLength:16}}
            thousandSeparator={true}
            thousandsGroupStyle="thousand"
            name="couponValue"
            label={addAsterisk("Offer Value", !isTemplate)}
            prefix={"$"}
            required={!isTemplate}
            error={!isTradeInGenericSelected && !isMarketingCampaignType(formValues) && offerValueError}
            value={isTradeInGenericSelected || isMarketingCampaignType(formValues) ? 0 : formValues.couponValue}
            onChange={handleInputChange}
          />
        </FormControl>
        {!isTradeInGenericSelected && offerValueError && (
          <ErrorContainer errorMessage="Please enter a positive amount." />
        )}
      </Grid>
      <Grid item xs={8} sm={6} md={4}>
        <FormControl fullWidth>
          <InputLabel
            sx={offerCountError ? { color: `${window.theme.colors.error} !important` } : null}
            htmlFor="max-input"
          >
            {addAsterisk("Maximum offer count", !isTemplate)}
          </InputLabel>
          <NumericFormat
            data-testid={`maximum-count-input`}
            id="max-input"
            aria-describedby="max-helper-text"
            name="maxDistribution"
            label={addAsterisk("Maximum Offer Count", !isTemplate)}
            allowNegative={false}
            thousandSeparator={true}
            thousandsGroupStyle="thousand"
            inputProps={{maxLength:16}}
            type="text"
            required={!isTemplate}
            error={offerCountError}
            value={formValues.maxDistribution}
            onChange={handleInputChange}
            customInput={OutlinedInput}
          />
          {offerCountError && <ErrorContainer errorMessage="Please enter a positive amount." />}
        </FormControl>
      </Grid>

      <Grid item xs={8} sm={6} md={4}>
        <FormControl fullWidth>
          <InputLabel
            sx={expiryDateError ? { color: `${window.theme.colors.error} !important` } : null}
            htmlFor="value-input"
          >
            {addAsterisk("Balance expires after days", !isTemplate)}
          </InputLabel>
          <NumericFormat
            disabled={isMarketingCampaignType(formValues)}
            sx={isMarketingCampaignType(formValues) ? { background: window.theme.colors.notSelectable } : null}
            data-testid={`expiry-days-input`}
            id="rewardExpireDays-input"
            customInput={OutlinedInput}
            aria-describedby="rewardExpireDays-helper-text"
            name="rewardExpireDays"
            label="Balance expires after date *"
            error={!isMarketingCampaignType(formValues) && offerValueError}
            value={isMarketingCampaignType(formValues) ? null : formValues.rewardExpireDays}
            onChange={handleExpireAfterChange}
          />
          {expiryDateError && <ErrorContainer errorMessage="Please enter a number of days before expiration." />}
        </FormControl>
      </Grid>
    </Grid>
  );
}
