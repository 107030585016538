import {
  Link as HrefLink,
  useNavigate,
  useParams
} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import useDeleteCampaign from "../hooks/useDeleteCampaign";
import useFetchMasterData from "../hooks/useFetchMasterData";
import {campaignStatusMap, tableTypePathMap} from "../constants/consts";
import EditModeContext from "../contexts/EditModeContext";
import axios from "axios";
import {publish} from "../events/events";
import {convertDraftDate} from "../utilities/utils";
import EditSVG from "../assets/EditSVG";
import DeleteSVG from "../assets/DeleteSVG";

import {
  CampaignDetailsSection,
  ComponentGuard,
  CouponDistributionSection,
  DownloadUsers,
  EligibilityCriteriaSection,
  ErrorContainer,
  ImagesDisplaySection,
  LogActivitySection,
  OfferMessageSection,
  PreviewOfferSection,
} from "../components/ComponentIndex";

import useImagePreview from "../hooks/useImagePreview";
import useAccountName from "../lib/useAccountName";
import {isMarketingCampaignType} from "../utilities/campaignHelpers";

export default function Campaign({ isTemplate = false }) {
  const { id } = useParams(),
    navigate = useNavigate(),
    masterdata = useFetchMasterData()

  const [reason, setReason] = useState(""),
    [loading, setLoading] = useState(false),
    [error, setError] = useState(""),
    [campaign, setCampaign] = useState(null),
    { setEditMode } = useContext(EditModeContext);

  const images = useImagePreview(campaign);

  useEffect(() => {
    setLoading(true);
    publish("showWaitSpinner", { toggle: true });

    axios
      .get(window.promotion_url + (isTemplate ? "/campaign-template" : "/campaigns/") + id)
      .then((response) => {
        setCampaign(response.data);
        document.title = response.data.name;
      })
      .catch((error) => {
        setError("Error while fetching campaigns: " + error.message);
      })
      .finally(() => {
        setLoading(false);
        publish("showWaitSpinner", { toggle: false });
      });
  }, [id, isTemplate]);

  const {
    deleteDialogOpen,
    deleteDialogTitle,
    deleteDialogDetail,
    showDeleteDialog,
    deleteCampaign,
    handleDeleteReason,
    deleteReason,
    needsReason,
    handleClose,
  } = useDeleteCampaign(isTemplate ? tableTypePathMap.TEMPLATE : tableTypePathMap.CAMPAIGN);

  const checkForErrors = () => {
    setError("");
    let newError = "";

    const endDate = convertDraftDate(campaign.endDate),
      startDate = convertDraftDate(campaign.startDate),
      now = new Date(new Date().setHours(0, 0, 0, 0));

    if (endDate < now) {
      newError = "Campaign end date has passed. Please update end date and resubmit for approval. ";
    }
    if (startDate < now) {
      newError +=
        "Campaign Offer available start date cannot be in the past. Please update start date and resubmit for approval.";
    }

    if (newError !== "") {
      setError(newError);
      return false;
    }
    return true;
  };

  const accountName = useAccountName();

  const submitStatus = (newStatus) => {
    if (!checkForErrors()) {
      return;
    }

    setLoading(true);

    const updatedCampaign = {
      status: newStatus,
      reason: reason,
      initiator: accountName,
    };
    axios
      .post(window.promotion_url + "/eligible-customers/campaigns/" + campaign.id + "/coupons", updatedCampaign, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      })
      .then(() => {
        campaign.status = newStatus;
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        setError("Error updating status. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showDownload = () => {
    // returns false if campaign is deleted or expired
    return !isTemplate && campaign.status !== campaignStatusMap.DELETED && campaign.status !== campaignStatusMap.EXPIRED
        && campaign.status !== campaignStatusMap.ACTIVE;
  };

  const renderBottom = () => {
    let bottom = null;
    switch (campaign.status) {
      case "DRAFT":
        bottom = (
          <Grid item justifyContent="flex-end">
            <Button
              data-testid={`submit-for-approval-button`}
              variant="contained"
              size="small"
              onClick={() => submitStatus(campaignStatusMap.PENDING)}
              disabled={loading}
            >
              Submit for Approval
            </Button>
          </Grid>
        );
        break;
      case "PENDING":
        bottom = (
          <ComponentGuard allowedRoles={isTemplate ? window.appRoles.approveTemplates : window.appRoles.approveCampaign}>
            <Grid item justifyContent="flex-end">
              <Button
                data-testid={`approve-button`}
                variant="contained"
                size="small"
                onClick={() => submitStatus(campaignStatusMap.APPROVED)}
                sx={{ mr: 1 }}
                disabled={loading}
              >
                Approve
              </Button>
              <Button
                data-testid={`reject-button`}
                variant="contained"
                size="small"
                onClick={() => submitStatus(campaignStatusMap.REJECTED)}
                disabled={loading}
              >
                Reject
              </Button>
            </Grid>
            <Grid container justifyContent="flex-end">
              <TextField
                data-testid={`approve-reject-reason-field`}
                style={{ marginTop: "20px" }}
                onChange={(event) => {
                  setReason(event.target.value);
                }}
                id="reason-for"
                label="Reason for approval/reject"
                value={reason}
              />
            </Grid>
          </ComponentGuard>
        );
        break;
      default:
        break;
    }
    return bottom;
  };

  const isDeleteDisabled = () => {
    return campaign.status !== campaignStatusMap.DELETED && campaign.name !== window.defaultCampaign;
  };

  if (!campaign) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Paper
      sx={{
        p: 3,
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2} sx={{ p: "16px 0" }}>
        <Grid item xs={8}>
          <Typography variant="h3" sx={{ ml: 1, mt: 2 }}>{`${campaign.status} - ${campaign.name || campaign.templateName}`}</Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "right", mt: 2, pr: 1 }}>
          <ComponentGuard allowedRoles={window.appRoles.createCampaign}>
            {campaign.status === "DRAFT" && (
              <HrefLink
                data-testid={`edit-button`}
                to={`/campaigns/edit/${campaign.id}`}
                onClick={(event) => {
                  event.stopPropagation();
                  setEditMode(true);
                }}
              >
                <Button className="btn-edit" sx={{ minWidth: "45px", mr: 1.5 }}>
                  <EditSVG />
                </Button>
              </HrefLink>
            )}
          </ComponentGuard>
          <ComponentGuard allowedRoles={window.appRoles.deleteCampaign}>
            {isDeleteDisabled(campaign) && (
              <Button
                data-testid={`delete-button`}
                className="btn-delete"
                sx={{ minWidth: "45px" }}
                onClick={(event) => {
                  event.stopPropagation();
                  showDeleteDialog(campaign);
                }}
              >
                <DeleteSVG />
              </Button>
            )}
          </ComponentGuard>
        </Grid>
        <Grid item xs={12}>
          <CampaignDetailsSection campaign={campaign} expanded={true} />
        </Grid>
        <Grid item xs={12}>
          <EligibilityCriteriaSection campaign={campaign} masterdata={masterdata} expanded={true} />
        </Grid>
        <Grid item xs={12}>
          <CouponDistributionSection campaign={campaign} masterdata={masterdata} expanded={true} />
        </Grid>
        <Grid item xs={12}>
          <ImagesDisplaySection campaign={campaign} expanded={true} />
        </Grid>
        <Grid item xs={12} hidden={isMarketingCampaignType(campaign)}>
          <OfferMessageSection couponOfferMessages={campaign.couponOfferMessages ? campaign.couponOfferMessages : [campaign]}
                               formValues={campaign} expanded={true} />
        </Grid>
        <Grid item xs={12} hidden={isMarketingCampaignType(campaign) || window.preview_offer_disabled}>
          <PreviewOfferSection campaign={campaign} images={images}/>
        </Grid>
        <Grid item xs={12}>
          <LogActivitySection campaign={campaign}/>
        </Grid>
        {showDownload() && (
          <DownloadUsers
            formValues={campaign}
            setFormValues={() => {}}
            showCalculate={false}
            preventSave={true}
          />
        )}
        <Grid item xs={12}>
          <Dialog
            open={deleteDialogOpen}
            onClose={() => publish("hideNotifications")}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{deleteDialogTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{deleteDialogDetail}</DialogContentText>
              {needsReason && (
                <TextField
                  data-testid={`handle-delete-reason-input`}
                  autoFocus
                  required
                  margin="dense"
                  id="reason"
                  label="Reason"
                  type="text"
                  fullWidth
                  autoComplete="off"
                  sx={{ marginTop: "10px" }}
                  onChange={(event) => handleDeleteReason(event.target.value)}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button
                data-testid={`confirm-delete-button`}
                onClick={deleteCampaign}
                color="primary"
                disabled={needsReason && !deleteReason.trim()}
              >
                Yes
              </Button>
              <Button data-testid={`cancel-delete-button`} onClick={handleClose} color="error" autoFocus>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button data-testid={`back-button`} variant="contained" size="small" onClickCapture={() => navigate("/campaigns")}>
                Back
              </Button>
            </Grid>
            {renderBottom()}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!!error && (
            <ErrorContainer
              containerStyle={{
                margin: "0",
              }}
              errorMessage={error}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
