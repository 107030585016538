import React, { useState } from "react";
import {
  ErrorMessage,
  FieldArray,
  FormikProvider,
  useFormik,
  useFormikContext,
} from "formik";
import {
  Autocomplete,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ComponentUploadImage,
  CustomButton,
  FormikControl,
} from "../ComponentIndex";
import { DeleteOutline, DragIndicator } from "@mui/icons-material";
import {
  resetSurveyData,
  setSurveyData,
  surveyUploadImage,
} from "../../store/actions/survey";
import { BannerImage2 } from "../../assets";
import * as Yup from "yup";
import { handleModalConfirmation } from "../../store/actions/modal";
import { getListQuestionBank } from "../../store/actions/questionBank";
import { connect } from "react-redux";
import { publishErrorMessage } from "../../events/events";
const defaultOption = { question_id: "", title: "", question_type: "" };

const AutoFillValue = ({ surveyDetail, setValueArrayID }) => {
  const { setFieldValue } = useFormikContext();
  let defaultData = [
    {
      question_id: "",
      title: "",
      question_type: "",
    },
  ];

  let questionData = !surveyDetail.data.questions
    ? defaultData
    : surveyDetail.data.questions?.map((item) => {
        return {
          question_id: item.question_id,
          question_type: item.question_type,
          title: item.question_title,
        };
      });

  React.useEffect(() => {
    const getIds = !surveyDetail.data.questions
      ? [""]
      : surveyDetail.data.questions?.map((item) => {
          return item.question_id;
        });
    setValueArrayID(getIds);
    setFieldValue("dataSurvey", {
      name: surveyDetail.data.name,
      image: surveyDetail.data.image,
      questions: questionData,
      is_submitted: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDetail, setFieldValue]);
  return null;
};
const AutoFillCreate = ({ formSurvey, setValueArrayID, dataArray }) => {
  const { setFieldValue } = useFormikContext();
  const getIds = formSurvey.questions?.length
    ? formSurvey.questions?.map((item) => item.question_id)
    : [""];
  const questionData = formSurvey.questions?.map((item) => {
    return {
      question_id: item.question_id,
      question_type: item.question_type,
      title: item.title,
    };
  });
  React.useEffect(() => {
    setValueArrayID(getIds);
    setFieldValue("dataSurvey", {
      name: formSurvey.name,
      image: formSurvey.image,
      questions: questionData,
      is_submitted: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

const SurveyDetail = ({
  dispatch,
  formSurvey,
  setStepSurvey,
  surveyDetail,
  modalConfirmation,
  modalSurvey,
  isDataChanged,
  setIsDataChanged,
}) => {
  const [isUploaded, setIsUploaded] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [valueArrayID, setValueArrayID] = React.useState([""]);
  const [timer, setTimer] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState([[false]]);
  const [isLoadingGenerateImage, setIsLoadingGenerateImage] =
    React.useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [isOpen, setIsOpen] = React.useState([[false]]);
  const [imageFileName, setImageFileName] = React.useState("");
  const uploadRefs = React.useRef(
    Array.from({ length: 1 }, () => React.createRef())
  );

  const validationSchema = Yup.object({
    dataSurvey: Yup.object().shape({
      name: Yup.string().required("This is a required field"),
      image: Yup.mixed()
        .required("*This is a required field")
        .test(
          "fileFormat",
          "*The file format could not be uploaded. Please upload the image with Dynamic image, GIF, PNG, JPG, or JPEG format",
          (value) => {
            if (typeof value === "object" && value) {
              const supportedFormats = ["jpg", "jpeg", "png", "gif"]; //allowed file
              return supportedFormats.includes(value?.name.split(".").pop());
            } else {
              if (value) return true;
              return false;
            }
          }
        )
        .test(
          "fileAspectRatio",
          "*The uploaded file exceeds maximum upload ratio. Please upload image ratio under 327 x 109 pixels",
          async (value) => {
            let img;
            if (typeof value === "object" && value) {
              return new Promise((resolve) => {
                if (value) {
                  img = new Image();
                  var objectUrl = URL.createObjectURL(value);
                  img.onload = function () {
                    // 327 x 109 pixels for banner image
                    if (+this.width > 327 || +this.height > 109) {
                      resolve(false);
                    } else {
                      resolve(true);
                    }
                  };
                  img.src = objectUrl;
                } else {
                  resolve(false);
                }
              }).then((result) => {
                return result;
              });
            } else {
              if (value) return true;
              return false;
            }
          }
        )
        .test(
          "fileSize",
          "*The uploaded file exceeds maximum upload size. Please upload image size under 150KB",
          (value) => {
            if (typeof value === "object" && value) {
              return value.size <= 150000; //max file size is 150KB
            }
            return true;
          }
        ),
      questions: Yup.array().of(
        Yup.object().shape({
          question_id: Yup.string().required("*This is a required field"), // these constraints take precedence
        })
      ),
    }),
  });

  React.useEffect(() => {
    // set Value IsUploaded tobe true or false base on image
    setIsUploaded(!!surveyDetail?.data?.image);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDetail]);

  React.useEffect(() => {
    getQuestionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (modalSurvey.type === "create") {
      resetSurveyData(dispatch);
    }
  }, [dispatch, modalSurvey.type]);

  React.useEffect(() => {
    setModalType(modalConfirmation.type);
  }, [modalConfirmation]);

  function handleModal(type, data) {
    setModalType(type);
    if (modalSurvey.type === "edit") {
      data = {
        id: modalSurvey.data.id,
        body: data,
      };
    }
    handleModalConfirmation(dispatch, {
      isOpen: true,
      type,
      data,
    });
  }

  async function getQuestionList(payload, i = 0) {
    const newLoading = [...isLoading];
    newLoading[i] = true;
    setIsLoading(newLoading);
    try {
      const response = await getListQuestionBank(dispatch, {
        type: "ACTIVE",
        page: "",
        size: "",
        title: payload,
      });
      const newData = !response?.empty
        ? response.content.map((item) => ({
            id: item.id,
            title: item.title,
            question_type: item.question_type,
          }))
        : [];
      setDataArray(newData);
    } catch (error) {
      publishErrorMessage(error?.response?.data?.title);
    } finally {
      const newLoading = [...isLoading];
      newLoading[i] = false;
      setIsLoading(newLoading);
    }
  }
  function handleStartDrag(event, i) {
    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("options", i);
  }
  function handleOnDrop(event, values, index) {
    const valueOptions = event.dataTransfer.getData("options");
    handleMoveArray(values, +valueOptions, index);
  }
  function handleMoveArray(arr, from, to) {
    // drag and drop value array ID and array questions
    const tempValueArrayID = valueArrayID[from];
    const temp = arr[from];
    arr[from] = arr[to];
    arr[to] = temp;
    valueArrayID[from] = valueArrayID[to];
    valueArrayID[to] = tempValueArrayID;

    setSurveyData(dispatch, {
      ...formSurvey,
      questions: [...arr],
    });
  }
  function handleDragOverEnter(e) {
    e.preventDefault();
  }
  function handleDelete(arrayHelpers, index, value) {
    const newData = [...valueArrayID];
    const filterData = newData.filter((item) => item !== value.question_id);
    setValueArrayID(filterData);

    arrayHelpers.remove(index);
  }
  function handleAddMoreQuestion(arrayHelpers) {
    arrayHelpers.push(defaultOption);
    setValueArrayID((prev) => [...prev, ""]);
    setIsLoading((prev) => [...prev, false]);
  }
  function uploadImage(image, fileName) {
    const response = surveyUploadImage({
      param: fileName,
      body: new Blob([image]),
    });

    return response;
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      dataSurvey: formSurvey,
    },
    onSubmit: async (values) => {
      setIsLoadingGenerateImage(true);

      // Generate Image
      try {
        if (typeof values.dataSurvey.image !== "string") {
          const response = await uploadImage(
            values.dataSurvey.image,
            imageFileName
          );
          values.dataSurvey.image = response.file_name;
        }
        setIsLoadingGenerateImage(false);

        const mappingQuestion = values.dataSurvey.questions.map(
          (item, index) => ({
            question_id: item.question_id,
            question_type: item.question_type,
            title: item.title,
            position: index + 1,
          })
        );

        const newData = {
          ...values.dataSurvey,
          questions: mappingQuestion,
        };
        if (modalType === "draft") {
          handleModal("draft", newData);
        } else {
          setStepSurvey((prev) => prev + 1);
        }
        setIsDataChanged(true);
        setSurveyData(dispatch, newData);
      } catch (error) {
        publishErrorMessage(error?.response?.data?.title);
        setIsLoading(false);
      }
    },
  });

  function handleSearch(event, value, index) {
    if (event?.type === "click" || !event?.type) return;

    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        getQuestionList(value, index);
      }, 500)
    );
  }

  const handleChangeItem = (index, value, setFieldValue) => {
    setFieldValue(`dataSurvey.questions[${index}].question_id`, `${value.id}`);
    setFieldValue(`dataSurvey.questions[${index}].title`, value.title);
    setFieldValue(
      `dataSurvey.questions[${index}].question_type`,
      value.question_type
    );

    const newData = [...valueArrayID];
    newData[index] = `${value.id}`;
    setValueArrayID(newData);
  };
  function handleIsOpen(isOPen, index) {
    if (isOPen) {
      const newData = [...isOpen];
      newData[index] = isOPen;
      setIsOpen(newData);
    }
  }
  function handOnclose(isCLose, index) {
    if (isCLose) {
      const newData = [...isOpen];
      newData[index] = false;
      setIsOpen(newData);
    }
  }
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={600} mb={2} fontSize={18}>
            Survey Details
          </Typography>
          {surveyDetail.isLoading &&
            !surveyDetail.data &&
            modalSurvey.type === "edit" && <CircularProgress />}
          {((!surveyDetail.isLoading &&
            surveyDetail.data &&
            Object.keys(surveyDetail.data).length) ||
            modalSurvey.type === "create") && (
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <FormikControl
                  control="input"
                  label={[
                    "Survey Name",
                    `(${formik.values?.dataSurvey.name?.length || 0}/70)`,
                  ]}
                  id="survey_name"
                  name="dataSurvey.name"
                  placeholder="Type survey name"
                  className="basic-input"
                  inputProps={{ maxLength: 70 }}
                />
                <Stack sx={{ padding: "20px 0", width: "80%" }}>
                  <Typography variant="h7">
                    The Preview Tile Image serves as the initial visual
                    introduction. It's designed to capture user attention and
                    entice them to participate. This image should encapsulate
                    the essence of the topic or theme.
                  </Typography>
                </Stack>

                <Stack>
                  <Typography variant="h7" mb={1.5}>
                    Survey Preview Tile
                  </Typography>
                  <ComponentUploadImage
                    dataImage={formik.values.dataSurvey.image}
                    defaultImage={BannerImage2}
                    isDisabled={isUploaded}
                    uploadImageRef={uploadRefs.current[0]}
                    isLoading={isLoadingGenerateImage}
                    isError={true}
                    dimension="327 x 109"
                    size="150"
                    name="dataSurvey.image"
                    onHandleClick={() => {
                      setIsUploaded(false);
                      uploadRefs.current[0].current.value = null;
                      formik.setFieldValue("dataSurvey.image", "");
                    }}
                    onHandleChange={(e) => {
                      if (e.target.files) {
                        setIsUploaded(true);
                        setImageFileName(e.target.files[0].name);
                        formik.setFieldValue(
                          "dataSurvey.image",
                          e.target.files[0]
                        );
                      }
                    }}
                  />
                </Stack>
                <Divider sx={{ mt: 2, mb: 2, borderWidth: 1 }} />
                <FieldArray
                  name="dataSurvey.questions"
                  render={(arrayHelpers) => (
                    <>
                      {formik.values.dataSurvey.questions.map((item, index) => (
                        <div key={index}>
                          <li
                            style={{ listStyle: "none" }}
                            onDragStart={(ev) => handleStartDrag(ev, index)}
                            onDragOver={(e) => handleDragOverEnter(e)}
                            onDragEnter={(e) => handleDragOverEnter(e)}
                            onDrop={(ev) =>
                              handleOnDrop(
                                ev,
                                formik.values.dataSurvey?.questions,
                                index
                              )
                            }
                          >
                            <>
                              <Stack position={"relative"}>
                                {formik.values.dataSurvey.questions?.length >
                                  1 && (
                                  <IconButton
                                    onClick={() =>
                                      handleDelete(arrayHelpers, index, item)
                                    }
                                    sx={{
                                      position: "absolute",
                                      right: 0,
                                      zIndex: 100,
                                      cursor: "pointer",
                                    }}
                                  >
                                    <DeleteOutline />
                                  </IconButton>
                                )}
                                <label
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton
                                    draggable="true"
                                    sx={{ paddingLeft: 0 }}
                                  >
                                    <DragIndicator />
                                  </IconButton>
                                  <span>Question {index + 1}</span>
                                </label>
                                <Autocomplete
                                  fullWidth
                                  isOptionEqualToValue={(option, value) => {
                                    return (
                                      option.title === value || value === ""
                                    );
                                  }}
                                  onOpen={() => handleIsOpen(true, index)}
                                  onClose={() => handOnclose(true, index)}
                                  disableClearable={true}
                                  clearIcon={false}
                                  getOptionDisabled={(option) => {
                                    return valueArrayID.includes(
                                      `${option.id}`
                                    );
                                  }}
                                  getOptionLabel={(op) => {
                                    return isOpen[index]
                                      ? op.title || item.title || ""
                                      : item.title;
                                  }}
                                  selectOnFocus={true}
                                  onInputChange={(event, value) => {
                                    handleSearch(event, value, index);
                                  }}
                                  value={item.title || null}
                                  onChange={(event, value) =>
                                    handleChangeItem(
                                      index,
                                      value,
                                      formik.setFieldValue
                                    )
                                  }
                                  options={dataArray}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      name={`dataSurvey.questions[${index}].question_id`}
                                      placeholder="Select question"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {isLoading[index] ? (
                                              <CircularProgress size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </>
                                        ),
                                      }}
                                    />
                                  )}
                                />

                                <ErrorMessage
                                  name={`dataSurvey.questions[${index}].question_id`}
                                  render={(message) => {
                                    return (
                                      <span style={{ color: "red" }}>
                                        {message}
                                      </span>
                                    );
                                  }}
                                />
                              </Stack>
                            </>
                          </li>
                        </div>
                      ))}
                      <CustomButton
                        variant="link"
                        disabled={formik.values.dataSurvey.questions.length > 9}
                        style={{
                          textDecoration: "underline",
                          textTransform: "none",
                          color:
                            formik.values.dataSurvey.questions.length > 9
                              ? "gray"
                              : "#00A45F",
                          fontWeight: 600,
                        }}
                        onHandleClick={() =>
                          handleAddMoreQuestion(arrayHelpers)
                        }
                      >
                        Add another question
                      </CustomButton>
                    </>
                  )}
                />
                <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
                  <CustomButton
                    className={"custom_button white"}
                    style={{ width: 133 }}
                    type="submit"
                    onHandleClick={() => setModalType("draft")}
                    disabled={isLoadingGenerateImage}
                  >
                    <span>Save to Drafts</span>
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    className={"custom_button black"}
                    disabled={isLoadingGenerateImage}
                  >
                    <span>Continue</span>
                  </CustomButton>
                </Stack>
                {modalSurvey.type === "create" && (
                  <AutoFillCreate
                    dataArray={dataArray}
                    formSurvey={formSurvey}
                    setValueArrayID={setValueArrayID}
                  />
                )}
                {modalSurvey.type === "edit" &&
                  !isDataChanged &&
                  !surveyDetail.isLoading &&
                  surveyDetail.data &&
                  Object.keys(surveyDetail.data).length && (
                    <AutoFillValue
                      surveyDetail={surveyDetail}
                      setValueArrayID={setValueArrayID}
                    />
                  )}
              </form>
            </FormikProvider>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    modalSurvey: state.modal.modalSurvey,
    surveyDetail: state.survey.surveyDetail,
  };
};
export default connect(mapStatetoProps, null)(SurveyDetail);
