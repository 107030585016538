import axios from "axios";
import { METHOD } from "../constants/consts";

export const apiGet = (URL, params) =>
  axios({
    url: URL,
    method: METHOD.GET,
    params,
  });
export const apiDelete = (URL, data) =>
  axios({
    url: URL,
    method: METHOD.DELETE,
    data,
  });
export const apiPost = (URL, data) =>
  axios({
    url: URL,
    method: METHOD.POST,
    data,
  });
export const apiPutWithHeaders = (URL, data) =>
  axios({
    url: URL,
    method: METHOD.PUT,
    data,
    headers: {
      "Content-Type": "application/octet-stream",
    },
  });
export const apiPatch = (URL, data) =>
  axios({
    url: URL,
    method: METHOD.PATCH,
    data,
  });
export const apiPostWithHeaders = (URL, data) =>
  axios({
    url: URL,
    method: METHOD.POST,
    data,
    headers: {
      "Content-Type": "application/octet-stream",
    },
  });
export const apiPut = (URL, data) =>
  axios({
    url: URL,
    method: METHOD.PUT,
    data,
  });