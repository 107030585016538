import React from "react";
import { Grid } from "@mui/material";

export default function ErrorContainer({ containerStyle, styleOverrides, errorMessage }) {
  return (
    <Grid container style={!!containerStyle ? containerStyle : null}>
      <span
        className="error-text"
        style={{
          color: `${window.theme.colors.error}`,
          fontSize: "0.75rem",
          margin: "3px 14px",
          ...styleOverrides,
        }}
      >
        {errorMessage}
      </span>
    </Grid>
  );
}
