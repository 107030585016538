import { Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function Title({ children }) {
  return (
    <Typography
      variant="h6"
      mb={3}
      fontSize={18}
      fontWeight={500}
      color={"#11142D"}
    >
      {children}
    </Typography>
  );
}
Title.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Title;
