import React from "react";
import { Formik, Form, FieldArray, Field, useFormikContext } from "formik";
import { CustomButton, FormikControl, Title } from "../../../../ComponentIndex";
import {
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { DeleteOutline, DragIndicator } from "@mui/icons-material";
import {
  setQuestionData,
} from "../../../../../store/actions/questionBank";
import * as Yup from "yup";
import { connect } from "react-redux";
import { handleModalConfirmation } from "../../../../../store/actions/modal";

function getCustomLabel(type, length, maxLength) {
  return [`Answer ${type}`, `(${length || 0}/${maxLength})`];
}

const AutoFillValue = ({ questionDetail }) => {
  const { setFieldValue } = useFormikContext();

  React.useEffect(() => {
    let defaultData = [
      {
        answer: "",
        answer_feedback: "",
        image: "",
      },
      {
        answer: "",
        answer_feedback: "",
        image: "",
      },
    ];

    let optionsData = !questionDetail.data.options
      ? defaultData
      : questionDetail.data.options?.map((item) => {
          return {
            answer: item.answer,
            answer_feedback: item.answer_feedback,
            image: item.image,
          };
        });
    setFieldValue("dataQuestion", {
      title: questionDetail.data.title,
      banner_image: questionDetail.data.banner_image,
      question_type: questionDetail.data.question_type,
      information: questionDetail.data.information,
      show_feedback: questionDetail.data.show_feedback,
      free_text_feedback: questionDetail.data.free_text_feedback,
      options: optionsData,
      is_submitted: false,
    });
  }, [questionDetail, setFieldValue]);
  return null;
};

function SingleSelectDetails({
  dispatch,
  question,
  formQuestion,
  modalConfirmation,
  handleQuestion,
  modalQuestion,
  questionDetail,
  isDataChanged,
  setIsDataChanged,
}) {
  const [modalType, setModalType] = React.useState("");
  const defaultOption = { answer: "", answer_feedback: "", image: "" };
  let arrayLengthOptions = null;
  let feedback = true;

  const validationSchema = Yup.object({
    dataQuestion: Yup.object().shape({
      title: Yup.string().required("This is a required field"),
      show_feedback: Yup.boolean(),
      options: Yup.array()
        .test({
          message: "This is a required field",
          test: (val) => {
            if (!feedback) {
              arrayLengthOptions = val.filter((item) => item.answer).length;
              return arrayLengthOptions >= val?.length;
            } else {
              arrayLengthOptions = val.filter(
                (item) => item.answer && item.answer_feedback
              ).length;
              return arrayLengthOptions >= val?.length;
            }
          },
        })
        .when(["show_feedback"], {
          is: (show_feedback) => {
            feedback = show_feedback;
            return show_feedback;
          },
          then: Yup.array().of(
            Yup.object().shape({
              answer: Yup.string().test({
                message: "This is a required field",
                test: (val) => {
                  if (arrayLengthOptions >= val?.length) return true;
                  return val;
                },
              }),
              answer_feedback: Yup.string().test({
                message: "This is a required field",
                test: (val) => {
                  if (arrayLengthOptions >= val?.length) return true;
                  return val;
                },
              }),
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              answer: Yup.string().test({
                message: "This is a required field",
                test: (val) => {
                  if (arrayLengthOptions >= val?.length) return true;
                  return val;
                },
              }),
              answer_feedback: Yup.string(),
            })
          ),
        }),
    }),
  });

  React.useEffect(() => {
    setModalType(modalConfirmation.type);
  }, [modalConfirmation]);

  function addMoreOption(arrayHelpers, props) {
    if (props.values.dataQuestion.options.length > 9) return;
    arrayHelpers.push(defaultOption);
  }
  function handleQuestionNextBack(nextOrBack) {
    handleQuestion(dispatch, {
      questionType: question.questionType,
      questionStep: nextOrBack,
    });
  }
  function handleModal(type, data) {
    setModalType(type);
    if (modalQuestion.type === "edit") {
      data = {
        id: modalQuestion.data.id,
        body: data,
      };
    }
    handleModalConfirmation(dispatch, {
      isOpen: true,
      type,
      data,
    });
  }
  function handleStartDrag(event, i) {
    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("options", i);
  }
  function handleOnDrop(event, values, index) {
    const valueOptions = event.dataTransfer.getData("options");
    handleMoveArray(values.options, values, +valueOptions, index);
  }
  function handleMoveArray(arr, dataQuestion, from, to) {
    const temp = arr[from];
    arr[from] = arr[to];
    arr[to] = temp;
    setQuestionData(dispatch, {
      ...dataQuestion,
      options: [...arr],
    });
  }
  function handleDragOverEnter(e) {
    e.preventDefault();
  }
  function handleChange(ev, dataQuestion) {
    setQuestionData(dispatch, {
      ...dataQuestion,
      show_feedback: ev.target.checked,
    });
    dataQuestion.options.forEach((item) => {
      item.answer_feedback = "";
    });
  }

  return (
    <>
      <Title>Question Details</Title>
      {questionDetail.isLoading &&
        !questionDetail.data &&
        modalQuestion.type === "edit" && <CircularProgress />}
      {((!questionDetail.isLoading &&
        questionDetail.data &&
        Object.keys(questionDetail.data).length) ||
        modalQuestion.type === "create") && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                dataQuestion: formQuestion,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                if (modalType === "draft") {
                  handleModal("draft", values.dataQuestion);
                } else {
                  handleQuestionNextBack(question.questionStep + 1);
                }
                setIsDataChanged(true);
                setQuestionData(dispatch, values.dataQuestion);
              }}
            >
              {(props) => (
                <Form>
                  <Grid container direction="column">
                    <FormikControl
                      control="input"
                      label={[
                        "Question",
                        `(${props.values?.dataQuestion.title?.length || 0}/45)`,
                      ]}
                      id="question"
                      name="dataQuestion.title"
                      placeholder="Type question"
                      className="basic-input"
                      inputProps={{ maxLength: 45 }}
                    />
                  </Grid>
                  <>
                    <Typography
                      variant="subtitle1"
                      mb={1.5}
                      mt={2}
                      sx={{ opacity: 0.6 }}
                    >
                      Show Feedback
                    </Typography>
                    <label
                      className="switch"
                      onChange={(e) =>
                        handleChange(e, props.values.dataQuestion)
                      }
                    >
                      <Field
                        type="checkbox"
                        name="dataQuestion.show_feedback"
                      />
                      <span className="slider round"></span>
                    </label>

                    <Stack sx={{ mt: 2 }}>
                      <Typography variant="subtitle1" color={"#676363"}>
                        Note:
                      </Typography>
                      <List
                        sx={{
                          width: "100%",
                          pl: 2,
                          listStyle: "inherit",
                          color: "#808191",
                          "& li": {
                            lineHeight: "normal",
                            margin: 0,
                            padding: 0,
                          },
                        }}
                      >
                        <ListItem sx={{ display: "list-item" }}>
                          <ListItemText secondary="Always adhere to the guidelines to ensure the survey is visually appealing, and provides a seamless experience for the users." />
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                          <ListItemText secondary="Make sure to have a consistent writing style and structure to make your ideas sound clear, interesting, and unbiased." />
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                          <ListItemText secondary="Minimum answer options are 2 fields and maximum answers options are 10 fields." />
                        </ListItem>
                      </List>
                    </Stack>
                  </>

                  <FieldArray
                    name="dataQuestion.options"
                    render={(arrayHelpers) => (
                      <>
                        {props.values.dataQuestion?.options?.map(
                          (item, index) => (
                            <div key={index}>
                              <li
                                style={{ listStyle: "none" }}
                                onDragStart={(ev) => handleStartDrag(ev, index)}
                                onDragOver={(e) => handleDragOverEnter(e)}
                                onDragEnter={(e) => handleDragOverEnter(e)}
                                onDrop={(ev) =>
                                  handleOnDrop(
                                    ev,
                                    props.values.dataQuestion,
                                    index
                                  )
                                }
                              >
                                <>
                                  <Stack position={"relative"}>
                                    {props.values.dataQuestion.options?.length >
                                      2 && (
                                      <IconButton
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        sx={{
                                          position: "absolute",
                                          right: 0,
                                          zIndex: 100,
                                          cursor: "pointer",
                                        }}
                                      >
                                        <DeleteOutline />
                                      </IconButton>
                                    )}
                                    <FormikControl
                                      control={"inputWithIcon"}
                                      label={getCustomLabel(
                                        "Option",
                                        item.answer.length,
                                        22
                                      )}
                                      id={`answer${index}`}
                                      name={`dataQuestion.options[${index}].answer`}
                                      placeholder={"Type answer option"}
                                      className="basic-input"
                                      icondragdelete={
                                        props.values.dataQuestion.options
                                          ?.length
                                      }
                                      inputProps={{ maxLength: 22 }}
                                      icondrag={
                                        <IconButton draggable="true">
                                          <DragIndicator />
                                        </IconButton>
                                      }
                                    />
                                  </Stack>
                                  {props.values.dataQuestion.show_feedback && (
                                    <FormikControl
                                      control={"input"}
                                      label={getCustomLabel(
                                        "Feedback Option",
                                        item.answer_feedback.length,
                                        340
                                      )}
                                      id={`answer_feedback${index}`}
                                      name={`dataQuestion.options[${index}].answer_feedback`}
                                      placeholder={
                                        "Type answer feedback option"
                                      }
                                      className="basic-input"
                                      styleParent={{ ml: 5 }}
                                      inputProps={{ maxLength: 340 }}
                                    />
                                  )}
                                </>
                              </li>
                            </div>
                          )
                        )}
                        <CustomButton
                          variant="link"
                          disabled={
                            props.values.dataQuestion.options?.length > 9
                          }
                          style={{
                            textDecoration: "underline",
                            textTransform: "none",
                            color:
                              props.values.dataQuestion.options?.length > 9
                                ? "gray"
                                : "#00A45F",
                            fontWeight: 600,
                          }}
                          onHandleClick={() =>
                            addMoreOption(arrayHelpers, props)
                          }
                        >
                          Add More Option
                        </CustomButton>
                      </>
                    )}
                  />
                  <Stack direction={"row"} spacing={2} sx={{ ml: 0.5, mt: 3 }}>
                    <CustomButton
                      className={"custom_button white"}
                      style={{ width: 100 }}
                      disabled={modalQuestion.type === "edit"}
                      onHandleClick={() =>
                        modalQuestion.type === "edit" ? {} : handleQuestionNextBack(question.questionStep - 1)
                      }
                    >
                      <span>Back</span>
                    </CustomButton>
                    <CustomButton
                      className={"custom_button white"}
                      style={{ width: 133 }}
                      type="submit"
                      onHandleClick={() => setModalType("draft")}
                    >
                      <span>Save to Drafts</span>
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      className={"custom_button black"}
                    >
                      <span>Continue</span>
                    </CustomButton>
                  </Stack>
                  {modalQuestion.type === "edit" &&
                    !isDataChanged &&
                    !questionDetail.isLoading &&
                    questionDetail.data &&
                    Object.keys(questionDetail.data)?.length && (
                      <AutoFillValue questionDetail={questionDetail} />
                    )}
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      )}
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    questionDetail: state.questionBank.questionDetail,
    formQuestion: state.questionBank.questionData,
    question: state.questionBank.checkQuestion,
    modalConfirmation: state.modal.modalConfirmation,
    modalQuestion: state.modal.modalQuestion,
  };
};
export default connect(mapStatetoProps, null)(SingleSelectDetails);
