import { publishErrorMessage } from "../../events/events";
import {
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPutWithHeaders,
} from "../../utilities/api";
import { API_CMS } from "../../utilities/api-url";

export const getListQuestionBank = async (dispatch, data) => {
  dispatch({
    type: "SET_QUESTION_BANK_LIST",
    payload: {
      isLoading: true,
      data: false,
      error: null,
    },
  });
  try {
    const response = await apiGet(API_CMS.QUESTION_BANK_LIST, data);
    dispatch({
      type: "SET_QUESTION_BANK_LIST",
      payload: {
        isLoading: false,
        data: response.data,
        error: null,
      },
    });
    return response.data;
  } catch (error) {
    publishErrorMessage(error?.response?.data?.title);
    dispatch({
      type: "SET_QUESTION_BANK_LIST",
      payload: {
        isLoading: false,
        data: false,
        error: error || "Error get list question",
      },
    });
  }
};
export const deleteQuestionBank = async (data) => {
  try {
    const response = await apiDelete(API_CMS.QUESTION_BANK_DELETE(data));
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const detailQuestionBank = async (dispatch, data) => {
  dispatch({
    type: "SET_QUESTION_BANK_DETAIL",
    payload: {
      isLoading: true,
      data: null,
      error: null,
    },
  });
  try {
    const response = await apiGet(API_CMS.QUESTION_BANK_DETAIL(data));
    dispatch({
      type: "SET_QUESTION_BANK_DETAIL",
      payload: {
        isLoading: false,
        data: response.data,
        error: null,
      },
    });
  } catch (error) {
    publishErrorMessage(error?.response?.data?.title);
    dispatch({
      type: "SET_QUESTION_BANK_DETAIL",
      payload: {
        isLoading: false,
        data: false,
        error: error || "Error get detail question",
      },
    });
  }
};
export const submitApprovalRejectQuestionBank = async (data) => {
  try {
    const response = await apiPost(
      API_CMS.QUESTION_BANK_SUBMIT_APPROVAL(data.id),
      data.body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const handleQuestion = (dispatch, data) => {
  dispatch({
    type: "SET_QUESTION",
    payload: {
      questionType: data.questionType,
      questionStep: data.questionStep,
    },
  });
};
export const resetQuestionData = (dispatch) => {
  dispatch({
    type: "SET_QUESTION_DATA",
    payload: {
      title: "",
      question_type: 1,
      information: null,
      show_feedback: true,
      free_text_feedback: null,
      banner_image: "",
      options: [
        {
          answer: "",
          answer_feedback: "",
          image: "",
        },
        {
          answer: "",
          answer_feedback: "",
          image: "",
        },
      ],
      is_submitted: false,
    },
  });
};
export const setQuestionType = (dispatch, data) => {
  dispatch({
    type: "SET_QUESTION_TYPE",
    payload: {
      question_type: data.question_type
    },
  });
};
export const setQuestionData = (dispatch, data) => {
  dispatch({
    type: "SET_QUESTION_DATA",
    payload: {
      title: data.title,
      question_type: data.question_type,
      information: data.information,
      banner_image: data.banner_image,
      show_feedback: data.show_feedback,
      free_text_feedback: data.free_text_feedback,
      options: data.options,
      is_submitted: data.is_submitted,
    },
  });
};
export const createQuestionBank = async (data) => {
  try {
    const response = await apiPost(API_CMS.QUESTION_BANK_CREATE, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const questionUploadImage = async (data) => {
  const files = `?file_name=${data.fileName}&file_type=${data.fileType}`
  try {
    const response = await apiPutWithHeaders(
      API_CMS.QUESTION_UPLOAD_IMAGE(files),
      data.body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const editQuestionBank = async (data) => {
  try {
    const response = await apiPatch(API_CMS.QUESTION_BANK_EDIT(data.id), data.body);
    return response.data;
  } catch (error) {
    throw error;
  }
};