import React, { useState, useEffect } from "react";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { imageTitles } from "../../../constants/consts";
import axios from "axios";

const ImagesDisplaySection = ({ campaign, localImages, expanded = true }) => {
  const [images, setImages] = useState({}),
    [isExpanded, setIsExpanded] = useState(expanded);

  const handleAccordionChange = (event, newExpandedState) => {
    setIsExpanded(newExpandedState);
  };

  const fetchImage = async (name, imageUrl) => {
    try {
      const response = await axios.get(window.promotion_url + "/campaigns/images/" + imageUrl, {
        responseType: "blob",
      });
      if (response.status !== 200) {
        throw new Error(`Error fetching image ${name}: ${response.statusText}`);
      }
      const blob = response.data;
      return { [name]: URL.createObjectURL(blob) };
    } catch (error) {
      console.error(`Error fetching image ${name}:`, error);
    }
  };

  useEffect(() => {
    if (localImages) {
      const imagesData = Object.entries(localImages).reduce((acc, [name, file]) => {
        return { ...acc, [name]: URL.createObjectURL(file) };
      }, {});
      setImages(imagesData);
    } else {
      const imageObject = [
        { name: imageTitles.HERO, value: campaign.heroImageName },
        { name: imageTitles.BRAND, value: campaign.brandImageName },
        { name: imageTitles.DYNAMIC, value: campaign.dynamicImageName },
        { name: imageTitles.LISTVIEW, value: campaign.listViewImageName },
      ];

      const fetchImages = async () => {
        let imagesData = {};

        for (const image of imageObject) {
          try {
            if (image.value === null) {
              console.log("Not found:", image.name);
              continue;
            }
            const imageData = await fetchImage(image.name, image.value);
            imagesData = { ...imagesData, ...imageData };
          } catch (error) {
            console.error("Error fetching images:", error);
          }
        }
        setImages(imagesData);
      };

      fetchImages();
    }
  }, [campaign, localImages]);

  const getImageName = (imageName) => {
    if (imageName === imageTitles.BRAND) {
      return campaign.brandImageName;
    } else if (imageName === imageTitles.HERO) {
      return campaign.heroImageName;
    }
    return "";
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="campaign-details-content"
        id="campaign-details-header"
        sx={{
          background: `${window.theme.colors.header}`,
          borderBottom: `1px solid ${window.theme.colors.notSelectable}`,
        }}
      >
        <Typography variant="h6">Images</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} style={{ padding: "16px" }}>
          {!!images &&
            Object.entries(images).map(([imageName, imageUrl]) => (
              <Grid item xs={6} key={imageName}>
                <Typography component="h5" sx={{ mb: 2, fontWeight: "bold" }}>
                  {!!imageTitles[imageName] ? imageTitles[imageName] : imageName}
                </Typography>
                {!!imageUrl && <img src={imageUrl} alt={imageName} style={{ maxWidth: "80%", mb: 0 }} />}
                <div>
                  {!!localImages && !!localImages[imageName] ? (
                    <Typography component="p">
                      {localImages[imageName].name} - {(localImages[imageName].size / 1024 / 1024).toPrecision(2)}{" "}
                      megabytes
                    </Typography>
                  ) : (
                    <Typography component="p">{getImageName(imageName)}</Typography>
                  )}
                </div>
              </Grid>
            ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ImagesDisplaySection;
