import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    tenantId: window.tenantId, // Your tenant ID goes here
    clientId: window.clientId, // Your client (application) ID goes here
    authority: "https://login.microsoftonline.com/" + window.tenantId,
    redirectUri: window.authRedirectUri, // Must be the same in Azure AD portal
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
          default:
            console.warn(message);
            return;
        }
      },
    },
  },
};
export const protectedResources = {
  campaignApi: {
    path: "/v2/campaigns",
    scopes: window.campaignApiScopes,
  },
};

export default msalConfig;
