export const API_CMS = {
  QUESTION_BANK_LIST: `${window.survey_url}/dashboard/question-banks`,
  QUESTION_BANK_DELETE: id => `${window.survey_url}/dashboard/question-banks/delete/${id}`,
  QUESTION_BANK_DETAIL: id => `${window.survey_url}/dashboard/question-banks/details/${id}`,
  QUESTION_BANK_SUBMIT_APPROVAL: id => `${window.survey_url}/dashboard/question-banks/submit-approval/${id}`,
  QUESTION_BANK_CREATE: `${window.survey_url}/dashboard/question-banks/create`,
  QUESTION_UPLOAD_IMAGE: param => `${window.survey_url}/dashboard/question-banks/upload-image${param}`,
  QUESTION_BANK_EDIT: id =>`${window.survey_url}/dashboard/question-banks/update/${id}`,

  SURVEY_LIST: `${window.survey_url}/dashboard/surveys`,
  SURVEY_DELETE: id => `${window.survey_url}/dashboard/surveys/delete/${id}`,
  SURVEY_DETAIL: id => `${window.survey_url}/dashboard/surveys/details/${id}`,
  SURVEY_SUBMIT_APPROVAL: id => `${window.survey_url}/dashboard/surveys/submit-approval/${id}`,
  SURVEY_CREATE: `${window.survey_url}/dashboard/surveys/create`,
  SURVEY_UPLOAD_IMAGE: param => `${window.survey_url}/dashboard/surveys/upload-image${param}`,
  SURVEY_EDIT: id => `${window.survey_url}/dashboard/surveys/edit/${id}`,
}
