import { useField } from "formik";
import PropTypes from "prop-types";
import { Grid, OutlinedInput, Stack } from "@mui/material";
import TextError from "./TextError";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import React from "react";

function isFocus(OK) {
  if (OK) {
    return {
      color: "#00A45F",
    };
  }
  return {
    color: "#808191",
  };
}
const Input = (props) => {
  function MyFormHelperText({ label }) {
    const { focused } = useFormControl() || {};
    const helperText = React.useMemo(() => {
      return (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={isFocus(focused, props)}
          fontSize={15}
          fontWeight={500}
          mb={1}
        >
          {typeof label === "object" && (
            <>
              <span>{label[0]}</span>
              <span>{label[1]}</span>
            </>
          )}
          {typeof label === "string" && <>{label}</>}
        </Stack>
      );
    }, [focused, label]);

    return <>{helperText}</>;
  }

  const { name, label, styleParent, disabled, ...rest } = props;
  const [field] = useField(name);
  return (
    <Grid container direction="column">
      <FormControl sx={styleParent}>
        <MyFormHelperText label={label} />

        <OutlinedInput
          {...field}
          {...rest}
          disabled={disabled}
          sx={{ backgroundColor: disabled ? "#D9D9D9" : "" }}
        />
        <TextError name={name} />
      </FormControl>
    </Grid>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Input;
