import { useState, useEffect } from "react";

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      // Debounce to throttle the logic in this function
      clearTimeout(handleResize.timeoutId);

      handleResize.timeoutId = setTimeout(() => {
        setScreenSize(window.innerWidth);
      }, 100);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
      // Clear timeou
      clearTimeout(handleResize.timeoutId);
    };
  }, []);

  return screenSize;
};

export default useScreenSize;
