import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FormHelperText, Typography, Grid, Button } from "@mui/material";
import axios from "axios";
import DeleteSVG from "../../assets/DeleteSVG";
import DownloadSVG from "../../assets/DownloadSVG";

const ImageForm = (props) => {
  const fileInputRef = useRef(null),
    file = props.selectedImage,
    setFile = props.setImage,
    removeImage = props.removeImageUrl,
    [error, setError] = useState(false),
    [imageSelected, setImageSelected] = useState(false),
    imageUrl = props.imageUrl,
    [errorMessage, setErrorMessage] = useState(""),
    [isDisabled, setIsDisabled] = useState(false);

  const fetchImage = useCallback(async () => {
    try {
      const response = await axios.get(window.promotion_url + "/campaigns/images/" + imageUrl, {
        responseType: "blob",
      });
      if (response.status !== 200) {
        throw new Error(`Error fetching image: ${response.statusText}`);
      }
      const blob = response.data;
      if (file == null) {
        setFile(new File([blob], imageUrl.split("/").pop(), { type: blob.type }));
      }
      setImageSelected(true);
    } catch (error) {
      console.error(`Error fetching image:`, error);
    }
  }, [imageUrl, setFile, file]);

  useEffect(() => {
    if (imageUrl) {
      fetchImage();
    }
    if (!!file) {
      setImageSelected(true);
      setIsDisabled(true);
    }
  }, [imageUrl, imageSelected, fetchImage, file]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      const getErrorMessage = () => {
        let errorMessage = "Error uploading file.";
        if (!!fileRejections.length && !!fileRejections[0].errors && !!fileRejections[0].errors[0].message) {
          switch (fileRejections[0].errors[0].code) {
            case "file-invalid-type":
              errorMessage = "Error uploading file. Only JPEG, PNG and GIF file formats are allowed.";
              break;
            case "file-too-large":
              errorMessage = "Error uploading file. The file size exceeds 5MB limit.";
              break;
            case "too-many-files":
              errorMessage = "Error uploading file. Only one file is allowed.";
              break;
            default:
              errorMessage = `Error uploading file. ${fileRejections[0].errors[0].message}`;
              break;
          }
        }
        return errorMessage;
      };

      if (acceptedFiles.length > 0) {
        try {
          setFile(acceptedFiles[0]);
          props.setImage(acceptedFiles[0]);
          setImageSelected(true);
          setError(false);
          setErrorMessage(null);
          setIsDisabled(true);
        } catch (e) {
          const errorMessage = getErrorMessage();
          setError(true);
          setImageSelected(null);
          setErrorMessage(errorMessage);
          console.log("Error uploading file: ", e);
        }
      } else if (fileRejections.length !== 0) {
        const errorMessage = getErrorMessage();
        setError(true);
        setImageSelected(null);
        setErrorMessage(errorMessage);
      }
    },
    [props, setFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxFiles: 1,
    maxSize: 5 * 1024 * 1024,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg", ".jpeg"],
      "image/gif": [".gif"],
    },
    disabled: isDisabled,
  });

  const handleRemove = () => {
    setFile(null);
    setImageSelected(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    removeImage();
    setImageSelected(false);
    setError(false);
    setErrorMessage(null);
    setIsDisabled(false);
  };

  return (
    <Grid container sx={{ mt: 2 }}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6">{props.label}</Typography>
        <span>Maximum File Size: 5 megabytes. </span>
        <br />
        <span>Image formats accepted: jpeg, jpg, png & gif.</span>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div
          data-testid={`drag-drop-upload-image`}
          id={props.id}
          className={isDisabled ? "imageDragDropDisabled" : "imageDragDrop"}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <DownloadSVG />
          <p>
            {isDisabled
              ? "Only one image is allowed. To add a new image, please remove the one below first."
              : isDragActive
              ? "Drop the file here..."
              : "Drag and drop image here, or click to select files"}
          </p>
        </div>
        {!!error && (
          <FormHelperText sx={{ ml: 2 }} error>
            {errorMessage}
          </FormHelperText>
        )}

        {!!imageSelected && !!file && (
          <div className="imageDragDropPreview">
            <p>{`${file.name} - ${(file.size / 1024 / 1024).toPrecision(2)} megabytes`}</p>
            <img src={URL.createObjectURL(file)} alt="Upload preview" />
            <br />
            <Button
              data-testid={`delete-image-button`}
              className="btn-delete"
              variant="contained"
              onClick={handleRemove}
              style={{ color: "white", fontSize: "12px", padding: "12px", marginTop: "16px" }}
            >
              <DeleteSVG />
            </Button>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default ImageForm;
