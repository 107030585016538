import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { BannerImage1 } from "../../assets";
import { PreviewImage } from "../ComponentIndex";

const styles = {
  marginTop: -2,
  paddingBottom: 3,
};
const styleBox = {
  backgroundColor: "#17412F",
  borderRadius: 3,
  padding: 2,
  overflowY: "auto",
};

function FreeTextPreview({ questionDetail }) {
  return (
    <Stack gap={1} sx={styles}>
      <div>
        <PreviewImage
          dataImage={questionDetail.banner_image}
          defaultImage={BannerImage1}
          alt={"FreeTextBanner"}
          className={"banner_image"}
        />
      </div>
      <Typography variant="h7" textAlign={"center"} mb={2} mt={1}>
        {questionDetail?.title}
      </Typography>
      <Grid container spacing={2} justifyContent={"start"} display={"contents"}>
        <Box sx={styleBox}>
          <Typography
            color={"#fff"}
            sx={{ wordBreak: "break-all" }}
            height={150}
            variant="h6"
            fontSize={16}
          >
            Tell us what you think
          </Typography>
        </Box>
      </Grid>
    </Stack>
  );
}

export default FreeTextPreview;
