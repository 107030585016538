import React from "react";
import { Button } from "@mui/material";

export default function RuleButton({ dataIdString, click, children }) {
  return (
    <Button
      data-testid={dataIdString}
      sx={{
        ml: "2px",
        mt: 0,
        pl: 3,
        textTransform: "initial",
        borderRadius: 2,
        border: `${window.theme.colors.approve} solid 1px`,
        p: "10px 20px 10px 40px",
        background: `${window.theme.colors.componentBackground}`,
      }}
      className="btn-add"
      variant="outlined"
      onClick={click}
    >
      {children}
    </Button>
  );
}
