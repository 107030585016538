import { Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";

const styleCard = {
  backgroundColor: "#00A45F",
  borderRadius: 10,
  minHeight: 350,
  maxHeight: 350,
  overflow: "auto",
  scrollbarWidth: "none",
};
function LeftContent({ title, children }) {
  return (
    <Card style={styleCard}>
      <CardContent sx={{ color: "#FFF" }}>
        <Typography
          gutterBottom
          variant="body1"
          component="div"
          fontWeight={"bold"}
        >
          {title}
        </Typography>
        <Stack gap={18}>{children}</Stack>
      </CardContent>
    </Card>
  );
}

export default LeftContent;
