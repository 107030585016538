import {loginRedirect, logoutRedirect, msalInstance} from "../../lib/MsalAuth";
import Button from "@mui/material/Button";
import {useContext, useState} from "react";
import {AuthContext} from "react-oauth2-code-pkce";

export default function LogInOutButton(props) {
  const [buttonText, setButtonText] = useState("login");

  const color = props.color || "inherit";
  const variant = props.variant || "text";

  const {token, logOut } = useContext(AuthContext);

  // App is only loaded after redirect promise is handled,
  // so active account should always be properly initialised
  const handleLogInOut = async (props) => {
    if (window.authorizationFlow) {
      logOut();
    }
    if (msalInstance.getActiveAccount()) {
      logoutRedirect();
    } else {
      await msalInstance.handleRedirectPromise();

      loginRedirect();
    }
  };
  const disabledLoginRender = props.disableLogin;

  if ((msalInstance.getActiveAccount() || token) && buttonText !== "logout") {
    setButtonText("logout");
  }

  if (window.disableSso) {
    return null;
  }

  return (
    <>
      {(!disabledLoginRender || (msalInstance.getActiveAccount() || token)) && (
        <Button
          data-testid={`login-out-button`}
          color={color}
          variant={variant}
          onClick={handleLogInOut}
          sx={{
            fontSize: "19px",
            p: "6px 16px",
          }}
        >
          {buttonText}
        </Button>
      )}
    </>
  );
}
