import React, { useState } from "react";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {isActionItemCampaign} from "../../../utilities/campaignHelpers";

const OfferMessageSection = ({ couponOfferMessages, expanded = true, formValues }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  const couponOfferMessage = couponOfferMessages.length > 0 ? couponOfferMessages[0] : null;
  const style = {
    maxWidth: "100%",
  };

  const handleAccordionChange = (event, newExpandedState) => {
    setIsExpanded(newExpandedState);
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="offer-message-content"
        id="offer-message-header"
        sx={{
          background: `${window.theme.colors.header}`,
          borderBottom: `1px solid ${window.theme.colors.notSelectable}`,
        }}
      >
        <Typography variant="h6">Offer Message</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {couponOfferMessage && (
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: "16px" }}>
              <span style={{ fontWeight: "bold" }}>Offer Overview</span>
            </Grid>
            <Grid item xs={6}>
              <Typography component="p" style={style}>
                Offer Name: {couponOfferMessage.couponOfferName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component="p" style={style}>
                Offer Heading: {couponOfferMessage.couponOfferHeading}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component="p" style={style}>
                Offer CTA Title: {couponOfferMessage.couponCTATitle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" style={style}>
                Offer Details: <br />
                {couponOfferMessage.couponOfferDetails}
              </Typography>
            </Grid>
            {!!couponOfferMessage?.couponOfferValueSubheading?.length && (
              <Grid item xs={12}>
                <Typography component="p" style={style}>
                  Value Sub-heading: <br />
                  {couponOfferMessage.couponOfferValueSubheading}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sx={{ marginTop: "16px" }}>
              <span style={{ fontWeight: "bold" }}>Additional Details for Detailed Offer View</span>
            </Grid>
            <Grid item xs={6}>
              <Typography component="p" style={style}>
                Offer Url Title: {couponOfferMessage.couponOfferUrlTitle}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component="p" style={style}>
                Offer External Url: {couponOfferMessage.couponOfferExternalUrl}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" style={style}>
                Short Terms and Conditions: <br />
                {couponOfferMessage.couponOfferShortTAndC}
              </Typography>
            </Grid>
            {isActionItemCampaign(formValues) && (
                <Grid item xs={12} sx={{marginTop: "16px"}}>
                  <span style={{fontWeight: "bold"}}>Action Items</span>
                </Grid>
            )}
            {isActionItemCampaign(formValues) && (
                <Grid item xs={6} isActionItemCampaign>
                  <Typography component="p" style={style}>
                    Action Title: {couponOfferMessage.actionTitle}
                  </Typography>
                </Grid>
            )}
            {isActionItemCampaign(formValues) && (
                <Grid item xs={6}>
                  <Typography component="p" style={style}>
                    Action Url: {couponOfferMessage.actionUrl}
                  </Typography>
                </Grid>
            )}
            {formValues.isSavedOffer && (
                <Grid item xs={6}>
                  <Typography component="p" style={style}>
                    Save Offer CTA - Unsaved State: {couponOfferMessage.saveOfferUnsavedTitle}
                  </Typography>
                </Grid>
            )}
            {formValues.isSavedOffer && (
                <Grid item xs={6}>
                  <Typography component="p" style={style}>
                    Save Offer CTA - Saved State: {couponOfferMessage.saveOfferSavedTitle}
                  </Typography>
                </Grid>
            )}
            <Grid item xs={12} sx={{ marginTop: "16px" }}>
              <span style={{ fontWeight: "bold" }}>Customer Communications and Help</span>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" style={style}>
                Offer Agent Instructions: <br />
                {couponOfferMessage.couponOfferAgentInstructions}
              </Typography>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default OfferMessageSection;
