/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  SurveyList,
  HeaderSurveyList,
  CustomModalConfirmation,
  ViewDetailSurvey,
  ModalRejection,
  CustomModal,
} from "../components/ComponentIndex";
import { Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import NewSurvey from "./NewSurvey";
import {
  deleteSurvey,
  getListSurvey,
  submitApprovalRejectSurvey,
} from "../store/actions/survey";
import { publish, publishErrorMessage } from "../events/events";
import {
  handleModalConfirmation,
  handleModalQuestion,
  handleModalRejection,
  handleModalSurvey,
} from "../store/actions/modal";
import { editSurvey, createSurvey } from "../store/actions/survey";
import { getIDTokenClaims, splitData } from "../utilities/utils";

function handleSuccessMessage(type, action) {
  if (action === "create") {
    if (type === "draft") {
      return "Survey successfully Saved as Draft";
    } else if (type === "submit") {
      return "Survey successfully Added";
    }
  } else if (action === "edit") {
    return "Survey successfully Edited";
  } else {
    if (type === "delete") {
      return "Survey bank successfully deleted";
    } else if (type === "approve") {
      return "Survey successfully Approved";
    } else if (type === "reject") {
      return "Survey successfully Rejected";
    }
  }
}

function Survey({ dispatch, surveyList, modalConfirmation, modalSurvey }) {
  const [timer, setTimer] = React.useState(null);
  const [isLoading, setIsloading] = React.useState(false);
  const [isFiltered, setIsFiltered] = React.useState(false);
  const [isRendered, setIsrendered] = React.useState(false);

  const [totalData, setTotalData] = React.useState({
    active: 0,
    draft: 0,
    archive: 0,
  });
  const [limitasi, setLimitasi] = React.useState({
    active: 5,
    draft: 5,
    archive: 5,
  });
  const [valueFilter, setValueFilter] = React.useState({
    title: null,
    status: null,
  });
  const [dataSurvey, setDataSurvey] = React.useState({
    active: null,
    draft: null,
    archive: null,
  });

  React.useEffect(() => {
    // Handle Back from Browser
    window.onpopstate = () => {
      handleModalConfirmation(dispatch, {
        isOpen: false,
        type: "",
        data: null,
      });
      handleModalRejection(dispatch, {
        isOpen: false,
        approvalStatus: false,
        reason: null,
        data: null,
        type: "",
      });
      handleModalQuestion(dispatch, {
        isOpen: false,
        type: "",
        data: null,
      });
      handleModalSurvey(dispatch, {
        isOpen: false,
        type: "",
        data: null,
      });
    }
  })

  React.useEffect(() => {
    getAllSurveyList({
      title: valueFilter.title || "",
      status: valueFilter.status?.value || null,
    });

  }, [dispatch, valueFilter.title, valueFilter.status?.value]);

  // search survey title
  function searchSurveyName(value) {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        setValueFilter((prev) => ({ ...prev, title: value }));
        setIsFiltered(true);
      }, 1000)
    );
  }

  async function getAllSurveyList({ title, status }) {
    setIsrendered(true);
    const payload = {
      role_names: getIDTokenClaims(),
      size: 5,
      title,
      status,
    };
    const [activeSurvey, draftSurvey, archiveSurvey] = await Promise.allSettled(
      [
        getListSurvey(dispatch, { type: "ACTIVE", sort: "updated_date,DESC", ...payload }),
        getListSurvey(dispatch, { type: "DRAFT", sort: "updated_date,DESC", ...payload }),
        getListSurvey(dispatch, { type: "ARCHIVED", sort: "deleted_date,DESC", ...payload }),
      ]
    );
    setDataSurvey((prev) => ({
      ...prev,
      active: activeSurvey.status === "fulfilled" ? activeSurvey.value : [],
      draft: draftSurvey.status === "fulfilled" ? draftSurvey.value : [],
      archive: archiveSurvey.status === "fulfilled" ? archiveSurvey.value : [],
    }));
  }

  const onSubmitDraftDelete = async (data) => {
    setIsloading(true);
    if (data.type === "submit") {
      data.data.is_submitted = true;
    } else if (data.type === "draft") {
      data.data.is_submitted = false;
    }

    if (modalSurvey.type === "edit") {
      let splitBannerImage = splitData(data?.data?.body?.image, "/")
      if (splitBannerImage?.length > 0) {
        splitBannerImage = splitBannerImage?.pop();
      }
      let newDataQuesiton = data?.data?.body?.questions?.map((item) => {
        if (item.title) {
          delete item.title;
          delete item.question_type;
        }
        return item;
      });
      
      const payload = {
        id: data.data.id,
        body: {
          ...data.data.body,
          is_submitted: data.data.is_submitted,
          image: splitBannerImage,
          questions: newDataQuesiton,
        },
      };
      callAPIURL(editSurvey, payload, data.type, "edit");
    } else if (modalSurvey.type === "create") {
      let splitBannerImage = splitData(data.data?.image, "/")
      if (splitBannerImage?.length > 0) {
        splitBannerImage = splitBannerImage?.pop();
      }
      let newDataQuesiton = data.data.questions.map((item) => {
        if (item.title) {
          delete item.title;
          delete item.question_type;
        }
        return item;
      });
      const newData = {
        ...data.data,
        image: splitBannerImage,
        questions: newDataQuesiton,
      };
      callAPIURL(createSurvey, newData, data.type, "create");
    } else if (data.type === "delete") {
      callAPIURL(deleteSurvey, data.data.id, data.type);
    } else if (data.type === "approve") {
      submitApproveOrRejection(data);
    }
  };

  const callAPIURL = (apiURL, data, type, action) => {
    apiURL(data)
      .then(() => {
        publish("showNotification", {
          message: handleSuccessMessage(type, action),
          severity: "success",
        });
      })
      .catch((error) => {
        publishErrorMessage(error?.response?.data?.title);
      })
      .finally(() => {
        window.location.reload();
      });
  };

  function handleModalIsApprove(approvalStatus) {
    const body = {
      approval_status: true,
      reason: null,
    };
    // if the checker doing to reject
    if (!approvalStatus) {
      handleModalRejection(dispatch, {
        isOpen: true,
        approvalStatus: false,
        reason: null,
        data: modalSurvey.data,
        type: "reject",
      });
    } else {
      const payload = {
        id: modalSurvey.data.id,
        body,
      };
      handleModalConfirmation(dispatch, {
        isOpen: true,
        type: "approve",
        data: payload,
      });
    }
  }

  function submitApproveOrRejection(payload) {
    setIsloading(true);
    submitApprovalRejectSurvey({ id: payload.data.id, body: payload.data.body })
      .then(() => {
        publish("showNotification", {
          message: handleSuccessMessage(payload.type),
          severity: "success",
        });
      })
      .catch((error) => {
        publishErrorMessage(error?.response?.data?.title);
      })
      .finally(() => {
        window.location.reload();
      });
  }

  function handleRejection(params) {
    const payload = {
      type: params.type,
      data: {
        id: params.data.id,
        body: {
          approval_status: params.approvalStatus,
          reason: params.reason,
        },
      },
    };
    submitApproveOrRejection(payload);
  }

  return (
    <>
      <Grid container gap={2}>
        <Grid item xs={12}>
          <HeaderSurveyList
            dispatch={dispatch}
            searchSurveyName={searchSurveyName}
            valueFilter={valueFilter}
            setValueFilter={setValueFilter}
            setIsFiltered={setIsFiltered}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <SurveyList
                dispatch={dispatch}
                status="ACTIVE"
                title="Active Surveys"
                surveyList={dataSurvey.active}
                isLoading={surveyList.isLoading}
                isError={surveyList.error}
                isFiltered={isFiltered}
                valueFilter={valueFilter}
                setLimitasi={setLimitasi}
                limitasi={limitasi}
                setDataSurvey={setDataSurvey}
                dataLength={limitasi.active}
                setTotalData={setTotalData}
                totalData={totalData.active}
                isRendered={isRendered}
                setIsrendered={setIsrendered}
              />
            </Grid>

            <Grid item xs={12}>
              <SurveyList
                dispatch={dispatch}
                status="DRAFT"
                title="Draft Surveys"
                surveyList={dataSurvey.draft}
                isLoading={surveyList.isLoading}
                isError={surveyList.error}
                isFiltered={isFiltered}
                valueFilter={valueFilter}
                setLimitasi={setLimitasi}
                limitasi={limitasi}
                setDataSurvey={setDataSurvey}
                dataLength={limitasi.draft}
                setTotalData={setTotalData}
                totalData={totalData.draft}
                isRendered={isRendered}
                setIsrendered={setIsrendered}
              />
            </Grid>
            <Grid item xs={12}>
              <SurveyList
                dispatch={dispatch}
                status="ARCHIVE"
                title="Archive Surveys"
                surveyList={dataSurvey.archive}
                isLoading={surveyList.isLoading}
                isError={surveyList.error}
                isFiltered={isFiltered}
                valueFilter={valueFilter}
                setLimitasi={setLimitasi}
                limitasi={limitasi}
                setDataSurvey={setDataSurvey}
                dataLength={limitasi.archive}
                setTotalData={setTotalData}
                totalData={totalData.archive}
                isRendered={isRendered}
                setIsrendered={setIsrendered}
              />
            </Grid>
          </Grid>
        </Grid>

        <CustomModalConfirmation
          dispatch={dispatch}
          modalConfirmation={modalConfirmation}
          onHandleSubmit={onSubmitDraftDelete}
          isLoading={isLoading}
          setIsloading={setIsloading}
        >
          {modalConfirmation.type === "submit" && (
            <>
              <Typography
                id="modal-modal-title"
                variant="body1"
                fontWeight={600}
                fontSize={32}
                lineHeight={"normal"}
              >
                Are you sure all data input are correct?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Submitted data will be reviewed by checker.
              </Typography>
            </>
          )}
          {modalConfirmation.type === "draft" && (
            <>
              <Typography
                id="modal-modal-title"
                variant="body1"
                fontWeight={600}
                fontSize={32}
                lineHeight={"normal"}
              >
                Save to draft and continue later?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                All entered data will be stay as your latest input.
              </Typography>
            </>
          )}
          {modalConfirmation.type === "delete" && (
            <>
              <Typography
                id="modal-modal-title"
                variant="body1"
                fontWeight={600}
                fontSize={32}
                lineHeight={"normal"}
              >
                Are you sure you want to delete this survey?
              </Typography>
            </>
          )}
          {modalConfirmation.type === "approve" && (
            <Typography
              id="modal-modal-title"
              variant="body1"
              fontWeight={600}
              fontSize={32}
              lineHeight={"normal"}
            >
              Are you sure to approve this survey?
            </Typography>
          )}
        </CustomModalConfirmation>

        <ModalRejection
          isLoading={isLoading}
          onHandleSubmit={handleRejection}
        />
        {modalSurvey.type !== "" && (
          <CustomModal isOpen={modalSurvey.isOpen}>
            <>
              {(modalSurvey.type === "create" ||
                modalSurvey.type === "edit") && (
                <NewSurvey modalSurvey={modalSurvey} />
              )}
              {modalSurvey.type === "preview" && (
                <ViewDetailSurvey
                  onHandleModalIsApprove={handleModalIsApprove}
                />
              )}
            </>
          </CustomModal>
        )}
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    modalConfirmation: state.modal.modalConfirmation,
    surveyList: state.survey.surveyList,
    modalSurvey: state.modal.modalSurvey,
  };
};
export default connect(mapStatetoProps, null)(Survey);
