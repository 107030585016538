import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import {
  BannerImage1,
  BinaryImage1,
  BinaryImage2,
} from "../../../../../assets";
import {
  CustomButton,
  LeftContentQuestion,
  PreviewImage,
  RightContentQuestion,
} from "../../../../ComponentIndex";
import { connect } from "react-redux";
import { handleModalConfirmation } from "../../../../../store/actions/modal";
import { handleCheckNextQuestion } from "../../../../../utilities/utils";

const styleDiv = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};
const styleButton = (bg = "#FFF", color = "#323232") => {
  return {
    "&:hover": { backgroundColor: bg },
    mt: 2,
    backgroundColor: bg,
    color: color,
    fontSize: 10,
    height: 46,
    borderRadius: 2,
    textTransform: "none",
  };
};
function BinaryQuestion({ dispatch, question, formQuestion, handleQuestion }) {

  const handleNextQuestion = () => {
    handleQuestion(dispatch, {
      questionType: question.questionType,
      questionStep: question.questionStep + 1,
    });
    formQuestion.question_type = 1; // 1 = Binary
  }
  
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <LeftContentQuestion title={"Binary Question"}>
            <Typography variant="body1" mt={1}>
              The Binary question offers a direct approach, ideal for gauging
              user knowledge or preferences between two clear choices. For
              instance, One NZ could ask users if they're aware that the basic
              mobile plan includes unlimited texting or if they have a
              preference between prepaid or postpaid mobile plans.
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              Example: "Do you know which One NZ broadband package provides a
              free router upgrade: Basic or Premium?"
            </Typography>
          </LeftContentQuestion>
        </Grid>
        <Grid item xs={4}>
          <RightContentQuestion
            title={"How would you prefer to pay for your new smartphone?"}
            image={BannerImage1}
          >
            <Grid container justifyContent={"center"} mt={2}>
              <Grid item xs={6}>
                <div style={styleDiv}>
                  <PreviewImage
                    dataImage={""}
                    defaultImage={BinaryImage1}
                    alt={"BinaryImage1"}
                    styles={{ width: "80%" }}
                  />
                  <Button
                    sx={styleButton("#17412F", "#FFF")}
                    variant="contained"
                  >
                    Pay Upfront
                  </Button>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={styleDiv}>
                  <PreviewImage
                    dataImage={""}
                    defaultImage={BinaryImage2}
                    alt={"BinaryImage2"}
                    styles={{ width: "80%" }}
                  />
                  <Button sx={styleButton()} variant="contained">
                    Spread Over 24/36
                  </Button>
                </div>
              </Grid>
            </Grid>
          </RightContentQuestion>
        </Grid>
        <Grid item xs={12} mt={2}>
          <CustomButton
            type="submit"
            className={"custom_button black"}
            onHandleClick={() => handleCheckNextQuestion({
              dispatch,
              currentQuestionType: formQuestion.question_type,
              defaultQuestionType: 1,
              formQuestion,
              handleNextQuestion,
              handleModalConfirmation
            })}
          >
            <span>Continue</span>
          </CustomButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {
    formQuestion: state.questionBank.questionData,
    question: state.questionBank.checkQuestion,
  };
};
export default connect(mapStatetoProps, null)(BinaryQuestion);
