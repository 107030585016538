import {useContext, useEffect} from 'react';
import axios from 'axios';
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {protectedResources} from "../config/AuthConfig";
import {msalInstance} from "./MsalAuth";

const AxiosInterceptor = () => {

  const {token, logIn}: IAuthContext = useContext(AuthContext);

  useEffect(() => {

    const requestInterceptor = axios.interceptors.request.use(
        async function (config) {
          if (window.authorizationFlow) {
            if (token) {
              config.headers.Authorization = `Bearer ${token}`;
            } else {
              logIn();
              return Promise.reject(new axios.Cancel('redirecting for authorization'));
            }
          } else {
            const tokenResponse = await msalInstance.acquireTokenSilent({
              account: msalInstance.getActiveAccount(),
              scopes: protectedResources.campaignApi.scopes
            });
            config.headers.Authorization = `Bearer ${tokenResponse?.accessToken}`;
          }
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
    );

    const responseInterceptor = axios.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          if (error.response && error.response.status === 401) {
            logIn();
          }
          return Promise.reject(error);
        }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.request.eject(responseInterceptor);
    };
  }, [token, logIn]);

  return null;
};

export default AxiosInterceptor;
