import React from "react";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";

export default function ExclusionRule({ ruleKey = "", value = "", operator = "", date = "" }) {
  const formattedDate = date ? format(new Date(date), "dd/MM/yyyy") : "";

  return (
    <Typography variant="h7">
      {ruleKey} {operator} {value} {formattedDate && ` since ${formattedDate}`}
    </Typography>
  );
}
