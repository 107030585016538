import { useState, useEffect } from "react";
import axios from "axios";

export default function useFetchMasterData() {
  const [masterData, setMasterData] = useState([]);

  useEffect(() => {
    axios
      .get(window.promotion_url + "/campaigns/masterdata")
      .then((response) => {
        let data = [];

        response.data.forEach((item) => {
          let existingItem = data.find((d) => d.label === item.label);

          if (existingItem) {
            existingItem.values.push(item.value);
          } else {
            data.push({
              label: item.label,
              key: item.key,
              inputType: item.inputType,
              values: [item.value],
            });
          }
        });

        setMasterData(data);
      })
      .catch((error) => {
        console.error("Error fetching Masterdata:", error);
      });
  }, []);

  return masterData;
}
