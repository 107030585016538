export default function DownloadSVG() {
  return (
    <svg width="50" height="50" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.20356 14.7C9.20237 14.7 9.20117 14.7 9.19998 14.7C9.19878 14.7 9.19758 14.7 9.19639 14.7H8.37497C7.31697 14.7 6.352 14.3017 5.62183 13.6468C3.80349 12.4714 2.59998 10.4263 2.59998 8.1C2.59998 4.45492 5.5549 1.5 9.19998 1.5C12.2753 1.5 14.8594 3.60337 15.592 6.45H18.275C20.5532 6.45 22.4 8.29683 22.4 10.575C22.4 12.8532 20.5532 14.7 18.275 14.7H9.20356Z"
        fill="#000F55"
      />
      <path
        d="M8.74559 19.9185C8.47049 20.2434 8.70144 20.7416 9.12719 20.7416H11.3872V23C11.3872 23.2761 11.611 23.5 11.8872 23.5H13.1128C13.3889 23.5 13.6128 23.2761 13.6128 23V20.7416H15.8728C16.2985 20.7416 16.5295 20.2434 16.2544 19.9185L12.8816 15.9349C12.6819 15.699 12.3181 15.699 12.1184 15.9349L8.74559 19.9185Z"
        fill="#000F55"
      />
    </svg>
  );
}
