import { Avatar, Button, Chip, Grid, Stack } from "@mui/material";
import React from "react";
import { fetchImage } from "../../../utilities/utils";
const styleButton = (questionType, index) => ({
  "&:hover": { backgroundColor: questionType === "MULTIPLE_SELECTION" ? index % 2 === 1 ? "#FFF" : "#17412F" : "#17412F" },
  backgroundColor: questionType === "MULTIPLE_SELECTION" ? index % 2 === 1 ? "#FFF" : "#17412F" : "#17412F",
  color: questionType === "MULTIPLE_SELECTION" ? index % 2 === 1 ? "#000" : "#FFF" : "#FFF",
  fontSize: 12,
  display: "flex",
  justifyContent: "start",
  borderRadius: 2,
  paddingLeft: 1,
  textTransform: "none",
  width: "100%",
});
const styleChip = (questionType, index) => ({
  "&:hover": { backgroundColor: questionType === "MULTIPLE_SELECTION" ? index % 2 === 1 ? "#FFF" : "#17412F" : "#17412F" },
  backgroundColor: questionType === "MULTIPLE_SELECTION" ? index % 2 === 1 ? "#FFF" : "#17412F" : "#17412F",
  color: questionType === "MULTIPLE_SELECTION" ? index % 2 === 1 ? "#000" : "#FFF" : "#FFF",
  display: "flex",
  fontSize: 12,
  justifyContent: "start",
  flexDirection: "row-reverse",
  borderRadius: 4,
  width: "100%",
  height: 40,
});

function SingleMultiPreview({ values }) {
  const [newImages, setNewImages] = React.useState([])

  React.useEffect(() => {
    values?.options?.forEach((item) => {
      convertImage(item.image);
    });
  }, [values?.options])

  function showImage(path) {
    const findData = newImages.find(item => item[path] )
    if (findData !== undefined && Object.keys(findData).length > 0) {
      return findData[path]
    }
    return ""
  }
  async function convertImage(imageURL) {
    try {
      const result = await fetchImage(imageURL, "list");
      if (result) {
        setNewImages(prev => [...prev, result]);
      } 
    } catch (error) {
      console.error(`Error fetching image`, error);
    }
  }
  return (
    <Grid
      container
      spacing={2}
      justifyContent={"center"}
      sx={{ display: "contents" }}
    >
      <Stack spacing={3} mt={3}>
        {values.options?.map((item, i) => (
          <div key={i}>
            {item.image ? (
              <Button
                variant="contained"
                sx={styleButton(values.question_type, i)}
                startIcon={
                  item.image && (
                    <Avatar
                      src={
                        (typeof item.image === "object" ?
                          item.image &&
                          URL.createObjectURL(item.image) : showImage(item.image))
                      }
                      variant="square"
                    />
                  )
                }
              >
                {item.answer}
              </Button>
            ) : (
              <Chip
                key={i}
                variant="contained"
                label={item.answer}
                sx={styleChip(values.question_type, i)}
              />
            )}
          </div>
        ))}
      </Stack>
    </Grid>
  );
}

export default SingleMultiPreview;
