import React from "react";
import { connect } from "react-redux";
import CustomModal from ".";
import {
  Box,
  FormControl,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { handleModalRejection } from "../../../store/actions/modal";
import CustomButton from "../Forms/CustomButton";
import ErrorContainer from "../ErrorContainer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  border: "none",
  borderRadius: 6,
};

function ModalRejection({
  dispatch,
  dataRejection,
  isLoading,
  onHandleSubmit,
}) {
  const [value, setValue] = React.useState("");
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const handleClose = () => {
    setValue("")
    setIsSubmitted(false)
    handleModalRejection(dispatch, {
      isOpen: false,
      approvalStatus: false,
      reason: null,
    });
  }
    
  const handleSubmit = () => {
    setIsSubmitted(true)
    if (value) {
      onHandleSubmit(dataRejection);
    }
  };

  return (
    <CustomModal isOpen={dataRejection.isOpen} handleClose={handleClose}>
      <Box sx={style}>
        <Typography
          variant="h6"
          color={"#323232"}
          fontSize={18}
          fontWeight={600}
          mb={2.5}
        >
          Reject Survey
        </Typography>
        <FormControl fullWidth>
          <label style={{ marginBottom: 10, fontSize: 14 }}>
            Reason for Rejection
          </label>
          <OutlinedInput
            error={!value && isSubmitted}
            placeholder="Type reason for rejection"
            onChange={(e) => {
              setValue(e.target.value);
              handleModalRejection(dispatch, {
                ...dataRejection,
                reason: e.target.value,
              });
            }}
          />
          {!value && isSubmitted && (
            <ErrorContainer
              containerStyle={{ marginTop: "8px", marginLeft: "-10px" }}
              errorMessage="This is a required field."
            />
          )}
        </FormControl>
        <Stack pt={3} direction={"row"} alignItems={"center"} gap={2}>
          <CustomButton
            onHandleClick={handleClose}
            className={"custom_button white"}
            style={{ width: 100 }}
          >
            <span>Cancel</span>
          </CustomButton>
          <CustomButton
            type="submit"
            className={"custom_button black"}
            style={{ width: 100 }}
            disabled={isLoading}
            onHandleClick={handleSubmit}
          >
            <span>Submit</span>
          </CustomButton>
        </Stack>
      </Box>
    </CustomModal>
  );
}

const mapStatetoProps = (state) => {
  return {
    dataRejection: state.modal.modalRejection,
  };
};
export default connect(mapStatetoProps, null)(ModalRejection);
