import React from "react";
import { Grid, Button, ToggleButtonGroup, ToggleButton, Paper, Typography } from "@mui/material";

import { Rule, RuleButton } from "../../ComponentIndex";
import DeleteSVG from "../../../assets/DeleteSVG";
import { getToggleButtonStyle } from "../../../utilities/utils";

const defaultValues = {
  key: "",
  label: "",
  logicalOperator: "IS",
  value: "",
  logicalJoin: "AND",
};

export default function RuleGroup({
  showErrorOnPage = false,
  ruleGroup = { rules: [], andOrOption: "and" },
  ruleKeys = [],
  isSubgroup = false,
  onChange = (e, ruleGroup) => {},
  removeGroup = () => {},
  rulesExist = false,
  groupName = 0,
  ruleName = "",
  numberOfOtherRules = 0,
}) {
  const onRuleChange = (e, index, newRule) => {
    ruleGroup.rules[index] = newRule;
    onChange(e, ruleGroup);
  };

  const onOptionChange = (e, newOption) => {
    if (newOption) {
      ruleGroup.andOrOption = newOption;
      onChange(e, ruleGroup);
    }
  };

  const addRule = (e) => {
    let rule = {
      ruleKey: "",
      value: "",
      label: defaultValues.label,
      operator: defaultValues.logicalOperator,
    };

    ruleGroup.rules = [...ruleGroup.rules, rule];
    onChange(e, ruleGroup);
  };

  const doesRuleGroupExist = ruleGroup.rules.length > 0,
    numberOfRulesInGroup = ruleGroup.rules.length,
    numberOfPrimaryRulesOrGroupRules = ruleGroup.rules.length + numberOfOtherRules;
  const getLabel = (key) => {
    const foundItem = ruleKeys.find((item) => item.key === key);
    return foundItem ? foundItem.label : key;
  };

  const isAndSelected = ruleGroup.andOrOption === "and";
  const andStyle = getToggleButtonStyle(isAndSelected, 0, "-2px", "60px");
  const orStyle = getToggleButtonStyle(!isAndSelected, "-2px", 0, "60px");

  const primaryToggleStyle = isSubgroup ? {} : { position: "absolute", top: "-60px" };

  const groupStyle = isSubgroup
    ? { p: 2, borderRadius: 2 }
    : { p: 0, borderRadius: 0, border: "0px solid transparent", boxShadow: "none" };

  return (
    <>
      {isSubgroup ? (
        <React.Fragment>
          <Grid item xs={8} sm={10}>
            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "1rem", mt: 1.5 }}>
              Group {groupName + 1}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={2} sx={{ textAlign: "end" }}>
            <Button
              sx={{ p: 1.5 }}
              data-testid={`remove-group-button-${groupName.toString().replace(/\s+/g, "-")}`}
              onClick={removeGroup}
              className="btn-delete"
              color="error"
              variant="contained"
            >
              <DeleteSVG />
            </Button>
          </Grid>
        </React.Fragment>
      ) : null}

      {numberOfPrimaryRulesOrGroupRules > 1 && !isSubgroup ? (
        <Grid item xs={12}>
          <ToggleButtonGroup
            data-testid={`toggle-and-or-button-${groupName.toString().replace(/\s+/g, "-")}`}
            sx={{
              borderRadius: "50px",
              border: `solid 2px ${window.theme.colors.primary}`,
              mb: 2,
              ...primaryToggleStyle,
            }}
            value={ruleGroup.andOrOption}
            color="primary"
            exclusive
            onChange={onOptionChange}
            aria-label="And/Or rule toggle"
          >
            <ToggleButton sx={andStyle} value="and">
              AND
            </ToggleButton>
            <ToggleButton sx={orStyle} value="or">
              OR
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      ) : null}

      {rulesExist || doesRuleGroupExist ? (
        <Grid item xs={12}>
          <Paper sx={groupStyle}>
            {numberOfRulesInGroup > 1 && isSubgroup ? (
              <Grid item xs={12}>
                <ToggleButtonGroup
                  data-testid={`toggle-and-or-button-${groupName.toString().replace(/\s+/g, "-")}`}
                  sx={{ borderRadius: "50px", border: `solid 3px ${window.theme.colors.primary}`, mb: 2 }}
                  value={ruleGroup.andOrOption}
                  color="primary"
                  exclusive
                  onChange={onOptionChange}
                  aria-label="And/Or rule toggle"
                >
                  <ToggleButton sx={andStyle} value="and">
                    AND
                  </ToggleButton>
                  <ToggleButton sx={orStyle} value="or">
                    OR
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            ) : null}

            {ruleGroup.rules.map((rule, index) => (
              <React.Fragment key={index}>
                {!(index === 0) && (
                  <Typography
                    variant="h6"
                    sx={{ m: "4px 8px", textTransform: "uppercase", fontSize: "1rem", fontWeight: 600 }}
                  >
                    {ruleGroup.andOrOption}
                  </Typography>
                )}
                <Grid container>
                  <Rule
                    name={ruleName}
                    isSubgroup={isSubgroup}
                    data-testid={`rule-${index}`}
                    showErrorOnPage={showErrorOnPage}
                    keys={ruleKeys}
                    id={rule.ruleKey}
                    logicalOperator={rule.operator}
                    inputType={rule.inputType}
                    value={rule.value}
                    valueOptions={rule.valueOptions}
                    label={rule.label ? rule.label : getLabel(rule.ruleKey)}
                    onKeyChange={(e, key) => {
                      const { key: newKey = "", label = "", inputType = "", values = [] } = key || {};
                      rule.ruleKey = newKey;
                      rule.label = label;
                      rule.inputType = inputType;
                      rule.valueOptions = values;
                      rule.value = "";
                      onRuleChange(e, index, rule);
                    }}
                    onOperatorChange={(e, operator) => {
                      rule.operator = operator;
                      onRuleChange(e, index, rule);
                    }}
                    onValueChange={(e, value) => {
                      rule.value = value;
                      onRuleChange(e, index, rule);
                    }}
                  />
                  <Grid
                    item
                    xs={4}
                    md={2}
                    alignItems="stretch"
                    sx={{ display: "flex", justifyContent: "flex-end", p: 0.5 }}
                  >
                    <Button
                      data-testid={`delete-rule-${index}-button`}
                      className="btn-delete"
                      color="error"
                      variant="contained"
                      onClick={() => {
                        ruleGroup.rules = ruleGroup.rules.filter((item, itemIndex) => itemIndex !== index);
                        onChange({ target: { value: ruleGroup } }, ruleGroup);
                      }}
                    >
                      <DeleteSVG />
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
          </Paper>
        </Grid>
      ) : null}
      <Grid item>
        <RuleButton
          dataIdString={`add-${isSubgroup ? "group-individual" : "individual"}-${ruleName.toLowerCase()}-button`}
          click={addRule}
        >
          Add individual rule
        </RuleButton>
      </Grid>
    </>
  );
}
